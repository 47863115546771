
import LoginForm from "@/components/AuthForms/LoginForm";
import RegistrationForm from "@/components/AuthForms/RegistrationForm";
import ForgotPasswordForm from "@/components/AuthForms/ForgotPasswordForm";
import OauthGithub from "@/components/AuthForms/OauthGithub";
import ConfirmChange from "@/components/AuthForms/ConfirmChange";

import TheDashboard from "@/components/TheDashboard";

import ThePublisherDashboard from "@/components/ThePublisherDashboard";

import TheSite from "@/components/TheSite";
import SiteVersions from "@/components/TheSite/SiteVersions";
import SiteForms from "@/components/TheSite/SiteForms";
import SiteFormSettings from "@/components/TheSite/SiteForm/SiteFormSettings";
import SiteFormsList from "@/components/TheSite/SiteForm/SiteFormsList";
import SiteUsage from "@/components/TheSite/SiteUsage";
import SiteSettings from "@/components/TheSite/SiteSettings";
import SiteAccess from "@/components/TheSite/SiteAccess";
import SiteApps from "@/components/TheSite/SiteApps";
import SitePlugin from "@/components/TheSite/SitePlugin";
import SitePluginEdit from "@/components/TheSite/SitePluginEdit";

import SiteAppChiseCms from "@/components/TheSite/SiteApps/SiteAppsPages/SiteAppChiseCms";
import SiteAppParseServer from "@/components/TheSite/SiteApps/SiteAppsPages/SiteAppParseServer";
import SiteAppParseCloud from "@/components/TheSite/SiteApps/SiteAppsPages/SiteAppParseCloud";

import TheProfile from "@/components/TheProfile";
import ProfileSettings from "@/components/TheProfile/ProfileSettings";
import ProfileBilling from "@/components/TheProfile/ProfileBilling";
import ProfileInvoices from "@/components/TheProfile/ProfileInvoices";

import {APP_PARSE_SERVER, APP_CHISEL_CMS} from "@/models/siteApp";
import TheProject from "@/components/TheProject";
import ProjectMain from "@/components/TheProject/ProjectMain";
import ProjectAccess from "@/components/TheProject/ProjectAccess";
import ProjectSites from "@/components/TheProject/ProjectSites";

import TheOrganisation from "@/components/TheOrganisation";
import OrganisationMain from "@/components/TheOrganisation/OrganisationMain";
import OrganisationBilling from '@/components/TheOrganisation/Billing/OrganisationBilling';
import OrganisationAccess from "@/components/TheOrganisation/OrganisationAccess";
import OrganisationAudit from "@/components/TheOrganisation/OrganisationAudit";
import OrganisationSecurity from "@/components/TheOrganisation/OrganisationSecurity";
import OrganisationIdentityAccess from "@/components/TheOrganisation/OrganisationIdentityAccess";
import OrganisationInfra from "@/components/TheOrganisation/OrganisationInfra";

import ThePlugin from '@/components/ThePlugin';
import PluginList from '@/components/ThePublisher/Plugin/PluginList';
import PluginDetail from '@/components/ThePublisher/Plugin/PluginDetail';
import DeveloperList from '@/components/ThePublisher/Developer/DeveloperList';
import DeveloperDetail from '@/components/ThePublisher/Developer/DeveloperDetail';
import PolicyList from '@/components/ThePublisher/Policy/PolicyList';
import PolicyDetail from '@/components/ThePublisher/Policy/PolicyDetail';

import TheReport from "@/components/ThePublisher/Report/TheReport";
import PluginInstallsReport from '@/components/ThePublisher/Report/PluginInstallsReport';
import TopDevelopersReport from '@/components/ThePublisher/Report/TopDevelopersReport';

import TheIntegration from "@/components/TheIntegration/TheIntegration";
import MuralIntegration from "@/components/TheIntegration/MuralIntegration";
import MiroIntegration from "@/components/TheIntegration/MiroIntegration";
import FigmaIntegration from "@/components/TheIntegration/FigmaIntegration";
import VulcanIntegration from "@/components/TheIntegration/VulcanIntegration";

import ReviewList from '@/components/ThePublisher/Review/ReviewList';
import SDKList from '@/components/ThePublisher/SDKList';

const routes = [
  {
    name: "login",
    path: "/login",
    component: LoginForm,
    meta: { isAuth: true }
  },
  // {
  //   name: "oauthGithub",
  //   path: '/login/github',
  //   component: OauthGithub,
  //   meta: {isAuth: true}
  // },
  // {
  //   name: 'oauthBitbucket',
  //   path: '/login/bitbucket',
  //   component: OauthGithub,
  //   meta: {isAuth: true}
  // },
  {
    name: "githubAuth",
    path: "/oauth/github",
    component: OauthGithub,
    meta: {requiresAuth: false}
  },
  {
    name: "acceptInvitation",
    path: "/invitations/:id/accept_invitation",
    component: RegistrationForm,
    meta: {isAuth: true}
  },
  {
    name: "dropboxAuth",
    path: '/oauth/dropbox',
    component: OauthGithub,
    meta: {requiresAuth: true}
  },
  {
    name: "bitbucketAuth",
    path: '/oauth/bitbucket',
    component: OauthGithub,
    meta: {requiresAuth: false}
  },
  {
    name: "registration",
    path: "/registration",
    component: RegistrationForm,
    props: true,
    meta: { isAuth: true }
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: ForgotPasswordForm,
    meta: { isAuth: true }
  },
  {
    name: "dashboard",
    path: "/",
    component: TheDashboard,
    meta: { requiresAuth: true }
  },
  {
    name: "confirm_change",
    path: "/sites/:site_id/confirm_change/:confirm_change_id",
    component: ConfirmChange,
    meta: { requiresAuth: true }
  },
  {
    name: "profile",
    path: "/profile",
    component: TheProfile,
    redirect: {name: 'profile_settings'},
    meta: { requiresAuth: true },
    children: [
      {
        name: "profile_settings",
        path: 'settings',
        component: ProfileSettings,
      },
      {
        name: "profile_billing",
        path: 'billing',
        component: ProfileBilling,
      },
      {
        name: "profile_invoices",
        path: 'invoices',
        component: ProfileInvoices,
      }
    ]
  },
  {
    name: "project",
    path: "/project/:id",
    redirect: {name: 'project_sites'},
    component: TheProject,
    meta: { requiresAuth: true },
    children: [
      {
        name: "project_main",
        path: 'main',
        component: ProjectMain,
      },
      {
        name: "project_access",
        path: 'access',
        component: ProjectAccess,
      },
      {
        name: "project_sites",
        path: 'sites',
        component: ProjectSites
      }
    ]
  },
  {
    name: "organisation",
    path: "/organisation/:id",
    redirect: {name: 'organisation_main'},
    component: TheOrganisation,
    meta: { requiresAuth: true },
    children: [{
        name: "organisation_main",
        path: 'main',
        component: OrganisationMain
      },
      {
        name: "organisation_billing",
        path: 'organisation_billing',
        component: OrganisationBilling
      },
      {
        name: 'organisation_access',
        path: 'access',
        component: OrganisationAccess
      },
      {
        name: 'organisation_audit',
        path: 'audit',
        component: OrganisationAudit
      },
      {
        name: 'organisation_security',
        path: 'security',
        component: OrganisationSecurity
      },
      {
        name: 'organisation_identity_access',
        path: 'identity_access',
        component: OrganisationIdentityAccess
      },
      {
        name: 'organisation_infra',
        path: 'infra',
        component: OrganisationInfra
      }
    ]
  },
  {
    name: "site",
    path: "/site/:id",
    redirect: {name: 'site_versions'},
    component: TheSite,
    meta: { requiresAuth: true },
    children: [
      {
        name: "site_versions",
        path: 'versions',
        component: SiteVersions,
      },
      {
        name: "site_forms",
        path: 'forms',
        redirect: {name: 'site_forms_list'},
        component: SiteForms,
        children: [
          {
            name: "site_form_settings",
            path: 'settings/:formId',
            component: SiteFormSettings

          },
          {
            name: "site_forms_list",
            path: 'list',
            component: SiteFormsList
          }
        ]
      },
      {
        name: "site_usage",
        path: 'usage',
        component: SiteUsage,
      },
      {
        name: "site_settings",
        path: 'settings',
        component: SiteSettings,
      },
      {
        name: "site_access",
        path: 'access',
        component: SiteAccess,
      },
      {
        name: "site_apps",
        path: 'apps',
        component: SiteApps,
        children: [
          {
            name: "site_apps_" + APP_PARSE_SERVER,
            path: "parse_server/:siteAppId",
            component: SiteAppParseServer,
          },
          {
            name: "site_apps_" + APP_CHISEL_CMS,
            path: "chisel/:siteAppId",
            component: SiteAppChiseCms,
          },
          {
            name: "parse_cloud",
            path: "parse_server/:siteAppId/parse_cloud",
            component: SiteAppParseCloud
          }
        ]
      },
      {
        name: "site_plugin",
        path: 'plugin/:slug',
        component: SitePlugin
      },
      {
        name: "site_plugin_edit",
        path: 'plugin/:slug/edit',
        component: SitePluginEdit
      },
    ]
  },
  {
    name: "plugin",
    path: "/publisher/plugins",
    redirect: { name: 'plugin_list' },
    component: ThePlugin,
    meta: { requiresAuth: true, publisherRoute: true },
    children: [
      {
        name: "plugin_list",
        path: "",
        component: PluginList,
        meta: { requiresAuth: true, publisherRoute: true, title: "Plugin" },
      },
      {
        name: "plugin_detail",
        path: ":pluginId",
        component: PluginDetail,
        meta: { requiresAuth: true, publisherRoute: true, title: "Plugin" }
      }
    ]
  },
  {
    name: "developers",
    path: "/publisher/developers",
    redirect: { name: 'developer_list' },
    component: ThePlugin,
    meta: { requiresAuth: true, publisherRoute: true },
    children: [
      {
        name: "developer_list",
        path: "",
        component: DeveloperList,
        meta: { requiresAuth: true, publisherRoute: true, title: "Developers" },
      },
      {
        name: "developer_detail",
        path: ":developerId",
        component: DeveloperDetail,
        meta: { requiresAuth: true, publisherRoute: true, title: "Developers" }
      }
    ]
  },
  {
    name: "reports",
    path: "/publisher/reports",
    component: ThePlugin,
    meta: { requiresAuth: true, publisherRoute: true },
    children: [
      {
        name: "report_list",
        path: "",
        component: TheReport,
        meta: { requiresAuth: true, publisherRoute: true, title: "Report" },
      },
      {
        name: "report_plugin_installs",
        path: "plugin-installs",
        component: PluginInstallsReport,
        meta: { requiresAuth: true, publisherRoute: true, title: "Plugin Installs Report" },
      },
      {
        name: "report_top_developers",
        path: "top-developers",
        component: TopDevelopersReport,
        meta: { requiresAuth: true, publisherRoute: true, title: "Top Developers Report" },
      }
    ]
  },
  {
    name: "publisher_policies",
    path: "/publisher/policies",
    redirect: { name: 'publisher_policy_list' },
    component: ThePlugin,
    meta: { requiresAuth: true, publisherRoute: true },
    children: [
      {
        name: "publisher_policy_list",
        path: "",
        component: PolicyList,
        meta: { requiresAuth: true, publisherRoute: true, title: "Policies" },
      },
      {
        name: "publisher_policy_detail",
        path: ":policyId",
        component: PolicyDetail,
        meta: { requiresAuth: true, publisherRoute: true, title: "Policies" }
      }
    ]
  },
  {
    name: "publisher_dashboard",
    path: "/publisher/dashboard",
    component: ThePublisherDashboard,
    meta: { requiresAuth: true, publisherRoute: true, title: "Publisher Dashboard" }
  },
  {
    name: "publisher_reviews",
    path: "/publisher/reviews",
    component: ThePlugin,
    meta: { requiresAuth: true, publisherRoute: true },
    children: [
      {
        name: "review_list",
        path: "",
        component: ReviewList,
        meta: { requiresAuth: true, publisherRoute: true, title: "Reviews" },
      }
    ]
  },
  {
    name: "publisher_sdk",
    path: "/publisher/sdk",
    component: SDKList,
    meta: { requiresAuth: true, publisherRoute: true, title: "SDK" },
  },
  {
    name: "integration",
    path: "/integration",
    component: TheIntegration,
    meta: { requiresAuth: true },
    children: [
      {
        name: "integration_mural",
        path: "mural",
        component: MuralIntegration,
        meta: { title: "Mural Integration" },
      },
      {
        name: "integration_vulcan",
        path: "vulcan",
        component: VulcanIntegration,
        meta: { title: "Vulcan Integration" },
      },
      {
        name: "integration_miro",
        path: "miro",
        component: MiroIntegration,
        meta: { title: "Miro Integration" },
      },
      {
        name: "integration_figma",
        path: "figma",
        component: FigmaIntegration,
        meta: { title: "Figma Integration" },
      }
    ]
  },
  {
    name: '404',
    path: '*'
  }
];

export default routes;
