<template>
  <div>
    <draggable class="dragArea root" tag="ul" :list="list" :value="value"
           v-bind="dragOptions" @input="emitter" handle=".handle"
    >

      <li v-for="el in realValue" :key="el.domID" v-bind:class="classObject(el)">      
        <span class="mr-2 p-2 handle">
          <the-icon icon="drag-handler" />
        </span>
        <div class="">
          <div class="object_type mb-1">{{el.type}}</div>
          <div class="object_title">{{el.model || 'Untitled'}}</div>
        </div>
        <v-spacer class="handle"></v-spacer>
        <v-tooltip bottom v-if="isMobile === false">
          <template v-slot:activator="{ on }">
            <v-btn class="action-copy" icon tile text @click="itemSettings(el)" v-on="on"
                  :ripple="false" max-width="38">
              <the-icon icon="square-edit" />
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom v-if="isMobile === false">
          <template v-slot:activator="{ on }">
            <v-btn class="action-delete" icon tile text @click="removeItem(el)" v-on="on"
                  :ripple="false" max-width="30">
              <the-icon icon="trash" />
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </li>
    </draggable>

  </div>
</template>
<script>
  import draggable from "vuedraggable";
  import {mapActions} from "vuex";
  import {cloneDeep} from "lodash";

  export default {
    name: "FormBuilderElements",
    data() {
      return {
      }
    },
    props: {
      value: {
        required: false,
        type: Array,
        default: null
      },
      list: {
        required: false,
        type: Array,
        default: null
      },
    },
    components: {
      draggable
    },
    computed: {
      // drag / drop options
      dragOptions() {
        return {
          animation: 150,
          group: "nested",
          swapThreshold: 0.65,
          disabled: false,
          ghostClass: "ghost",
          fallbackOnBody: true
        };
      },
      // helper for getting this.model
      realValue() {
        return this.value ? this.value : this.list;
      },
      isMobile() { // detect the size of the screen, useful for showing navigation drawer bottom on Mobile
        return this.$vuetify.breakpoint.smAndDown;
      }
    },
    methods: {
      ...mapActions({
        setEditingElement: 'formBuilder/setEditingElement',
        deleteElement: 'formBuilder/deleteElement',

        setAddDialogVisible: 'formBuilder/setAddDialogVisible',
        setAddDialogCaller: 'formBuilder/setAddDialogCaller'
      }),
      // This will emit the event to parent on drag/drop so that updateElements vuex mutation to be committed eventually.
      emitter(value) {
        this.$emit("input", value);
      },
      classObject(element) {
        return {
          'field builder-element': true,
          ['field-' + element.type]: true,
          [element.type]: true
        }
      },

      removeItem(element) {
        this.deleteElement({ domID: element.domID });
      },
      // setting icon click handler
      itemSettings(el) {
        let payload = cloneDeep(el);
        payload.parent = this.domID;
        this.setEditingElement(payload);
      },
      // Called from mobile device dropdown menu of every instance
      // based on element selection, it calls different method with this[field.action] call
      callAction(field, el) {
        if (field && field.action) this[field.action](el);
      },
    }
  };
</script>
<style scoped lang="scss">
  .dragArea:first-child {
    min-height: 100px;
    list-style: none;
    padding: 5px;
    /*padding-right: 0px;*/

  }

  .dragArea.root {
    border-top: none;
    padding: 0px;
    padding-right: 10px;
  }

  .dragArea li {
    /*padding: 10px;*/
    /*margin-bottom: 10px;*/
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                       not supported by any browser */
  }

  .dragArea .field {
    /*margin-bottom: 10px;*/
  }

  .dragArea li.builder-element {
    /*padding: 5px;*/
    padding: 12px;
    display: flex;
    background: $N6;
    margin-bottom: 8px;
  }

  .dragArea li.builder-element.ghost {
    border: 3px dashed #999;
  }

  /* Prevent the text contents of draggable elements from being selectable. */
  [draggable] {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    /* Required to make elements draggable in old WebKit */
    -khtml-user-drag: element;
    -webkit-user-drag: element;
  }

  .handle {
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
    vertical-align: middle;
    display: flex;
    align-items: center;
  }

  .object_type {
    margin-right: 7px;
    overflow: visible;
    font-size: 12px;
  }
  .object_title {
    font-size: 14px;
    color: white;
  }
</style>