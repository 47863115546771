<template>
  <v-menu
    :close-on-content-click="true"
    class="mx-4"
    offset-y
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        elevation="0"
        v-on="on"
        large
        @click="filterExpanded=!filterExpanded"
        v-bind="attrs"
        class="StatusFilter__filter-button"
      >
        <the-icon
          icon="filter"
          class="StatusFilter__icon-active"
        />
        <span :class="filterClassName">{{ status ? status : 'Status Filter' }}</span>
        <the-icon
          icon="close"
          class="StatusFilter__close-icon"
          v-if="status"
          @click.native.stop.prevent="onResetStatus()"
        />
        <the-icon
          icon="expand-down"
          :class="filterExpanded ? 'expanded StatusFilter__icon-inactive' : 'StatusFilter__icon-active'"
        />
      </v-btn>

    </template>

    <v-card max-height="250">
      <v-list>
        <v-list-item v-for="(statusOption, i) in statusOptions" :key="i" @click="onSelectStatusOption(statusOption)" 
          :class="`status-filter-option ${statusOption.class} ${ status === statusOption.value ? 'active' : '' }`">
          {{ statusOption.value }}
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>


<script>
export default {
  name: "StatusFilter",
  props: {
    status: String,
    disabled: Boolean
  },
  data() {
    const statusOptions = [
      { class: 'sale-ready', value: 'Ready for Sale' },
      { class: 'in-review', value: 'In Review' },
      { class: 'rejected', value: 'Rejected' },
      { class: 'other-status', value: 'Prepare for Upload' },
      { class: 'other-status', value: 'Waiting for Upload' },
      { class: 'other-status', value: 'Upload Received' },
      { class: 'other-status', value: 'Waiting for Review' },
      { class: 'other-status', value: 'Pending Contract' },
      { class: 'other-status', value: 'Waiting for Compliance' },
      { class: 'other-status', value: 'Pending Developer Release' },
      { class: 'other-status', value: 'Pending Release' },
      { class: 'other-status', value: 'Processing for Store' },
      { class: 'other-status', value: 'Removed from Sale' },
      { class: 'other-status', value: 'Missing Content' },
      { class: 'other-status', value: 'Developer Rejected' },
      { class: 'other-status', value: 'Developer Removed from Sale' },
      { class: 'other-status', value: 'Security Audit Issue' },
      { class: 'other-status', value: 'Lab Ready' }
    ];
    return {
      filterExpanded: false,
      statusOptions
    }
  },
  computed: {
    filterClassName() {
      const status = this.status ? this.status.toLowerCase() : '';
      if (status == 'ready for sale') return 'sale-ready'
      if (status == 'in review') return 'in-review'
      if (status == 'rejected') return 'rejected'
      return 'other-status';
    },
  },
  methods: {
    onSelectStatusOption(statusOption) {
      this.filterExpanded = false;
      this.$emit('updateFilter', { status: statusOption.value });
    },
    onResetStatus() {
      this.$emit('updateFilter', { status: '' });
    }
  }
}
</script>

<style lang="scss" scoped>
  $style: StatusFilter;
  .#{$style} {
    &__filter-button {
      background: transparent !important;
      border: 1px solid $N3;
      span {
        margin-left: 10px;
        margin-right: 15px;
        min-width: 100px;
        display: block;
      }
    }
    &__icon-active {
      color: $N2;
      fill: $N2;
    }
    &__icon-inactive {
      color: $N3;
      fill: $N3;
      &.expanded {
        transform: rotate(-180deg);
      }
    }
    &__close-icon {
      width: 16px;
      height: 16px;
      color: $N3;
      fill: $N3;
    }
  }


  .sale-ready {
    color: $G1 !important;
  }
  .in-review {
    color: $Y1 !important;
  }
  .rejected {
    color: $R2 !important;
  }
  .other-status {
    color: $N1 !important;
  }
</style>