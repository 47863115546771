<template>
  <publisher-dashboard-section-wrapper title="Developers Map">
    <div id="svgMap" ref="map"></div>
  </publisher-dashboard-section-wrapper>
</template>

<script>
import svgMap from "svgmap";
import PublisherDashboardSectionWrapper from "./PublisherDashboardSectionWrapper.vue";
import "svgmap/dist/svgMap.min.css";

export default {
  name: "PublisherDashboardDeveloperMap",
  components: {
    PublisherDashboardSectionWrapper
  },
  props: {
    statistics: Object
  },
  mounted() {
    new svgMap({
      targetElementID: "svgMap",
      mouseWheelZoomEnabled: false,
      hideFlag: true,
      data: {
        data: {
          developers: {
            format: "{0}",
            thousandSeparator: ",",
            thresholdMax: 50,
            thresholdMin: 1,
          },
        },
        applyData: "developers",
        values: this.statistics
      },
    });
  }
};
</script>

<style lang="scss">
#svgMap {
  .svgMap-map-wrapper {
    background: $bg-color;
  }
}

.svgMap-tooltip {
  background: rgba(31, 41, 55, 0.9);
  box-shadow: 0px 4px 8px rgba(24, 33, 45, 0.4);
  border-radius: 11px;
  color: #ffffff !important;
  font-family: $body-font-family;
}
.svgMap-tooltip .svgMap-tooltip-content-wrapper {
  color: #ffffff !important;
}
.svgMap-tooltip .svgMap-tooltip-content .svgMap-tooltip-no-data {
  color: #ffffff !important;
}
.svgMap-tooltip .svgMap-tooltip-content {
  color: #ffffff !important;
}
.svgMap-tooltip .svgMap-tooltip-content table td span {
  color: #ffffff;
}

.svgMap-tooltip-pointer {
  display: none;
}
/*
.svgMap-tooltip .svgMap-tooltip-title {
  padding: 0;
}
.svgMap-tooltip .svgMap-tooltip-content-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.svgMap-tooltip .svgMap-tooltip-content-container .svgMap-tooltip-content {
  font-weight: 500;
}
/* .svgMap-tooltip .svgMap-tooltip-flipped {
} */
</style>
