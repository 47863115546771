<template>
  <v-layout wrap class="DashboardRecentSites">
    <v-flex
      xs6
      sm3
      class="DashboardRecentSites__item"
      v-for="site in sites"
      :key="site.id"
    >
      <div class="DashboardRecentSites__card" @click.stop="moveToSite(site)">
        <div class="DashboardRecentSites__img-wrapper">
          <img :src="getImage(site)" alt="" />
        </div>
        <div class="DashboardRecentSites__content">
          <h6 class="DashboardRecentSites__title"> 
            <span class="DashboardRecentSites__dot"/> {{ site.site_name }}
            <span class="ml-2"> <the-icon icon="arrow-up-and-right" /> </span>
          </h6>
          <div class="DashboardRecentSites__bw">
            <span class="DashboardRecentSites__bw-name"> 
              <span class="DashboardRecentSites__bw_bandwidth"> {{ parseBandwidth(site.bandwidth.month) }} </span>
              <span class="DashboardRecentSites__bw_bandwidth_txt ml-2">  Bandwidth used </span>
            </span>
            <span class="DashboardRecentSites__bw-value">
             
            </span
            >
          </div>
        </div>
      </div>
    </v-flex>
  </v-layout>
</template>
<script>
import { parseBandwidth } from '@/utils/common'; 
export default {
  name: "DashboardRecentSites",
  props: {
    sites: Array,
  },
  data() {
    return {
    }
  },
  computed: {},
  methods: {
    getImage(site) {
      if (site?.thumb_image_url) return site.thumb_image_url;
      return "https://asgard-production.s3.amazonaws.com/forge+assets/forge_default.png";
    },
    parseBandwidth(bandwidth) {
      return parseBandwidth(bandwidth);
    },
    moveToSite(site) {
      this.$router.push({ path: '/site/' + site.id})
    }
   },
};
</script>
<style lang="scss">
$style: DashboardRecentSites;
.#{$style} {
  margin: -12px;
  &__dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $G1;
    margin-right: 15px;
  }

  &__item {
    padding: 12px;

    &:hover {
      background: $N7;
      border-radius: 8px;
      color: $N2;
      .#{$style}__title {
        color: $N2;
      }
      .#{$style}__bw_bandwidth {
        color: $N2;
      }
    }
  }
  &__card {
    display: flex;
    color: orange;
    cursor: pointer;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  &__img-wrapper {
    position: relative;
    padding-top: 53%;
    margin-bottom: 24px;
    overflow: hidden;
    border-radius: 8px;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
    }
  }
  &__title {
    color: $white;
    font-size: $H16;
    font-weight: 400;
    margin-bottom: 8px;
  }
  &__bw {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__bw-name {
    color: $white;
    font-weight: normal;
    font-size: $H12;
    .#{$style}__bw_bandwidth_txt {
      color: $N2;
      font-weight: normal;
      font-size: $H12;
    }
  }
  &__bw-value {
    color: $N2;
    font-weight: normal;
    font-size: $H12;
  }
}
</style>
