<template>
  <v-menu
    :close-on-content-click="false"
    :disabled="disabled"
    class="mx-4"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        elevation="0"
        v-bind="attrs"
        v-on="on"
        @click="columnFilterExpanded=!columnFilterExpanded"
        large
        class="ColumnsFilter__filter-button"
      >
        <the-icon
          icon="column"
          class="ColumnsFilter__icon-active"
        /> 
        <span>Columns</span>
        <the-icon
          icon="expand-down"
          :class="columnFilterExpanded ? 'expanded ColumnsFilter__icon-inactive' : 'ColumnsFilter__icon-active'"
        />
      </v-btn>
    </template>

    <v-card max-height="250">
      <v-list>
        <v-list-item v-for="option in columnOptions" 
          :class="{'flex-column min-height-0': option.children, 'dense-item': !option.children }">
          <v-checkbox
            v-model="localColumns"
            v-if="!option.children"
            :value="option.value"
            :label="option.text"
          />
          <v-checkbox
            v-if="option.children"
            :label="option.text"
            :indeterminate="isIndeterminated(option)"
            :value="isFullySelected(option)"
            class="w-full parent-checkbox align-self-start"
            @change="parentOptionChanged($event, option)"
          />
          <div v-if="option.children && option.children.length > 0">
            <div v-for="child in option.children" class="dense-item pl-4">
              <v-checkbox
                v-model="localColumns"
                :value="child.value"
                :label="child.text"
              />
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>


<script>
export default {
  name: "ColumnsFilter",
  props: {
    columns: Array,
    columnOptions: Array,
    disabled: Boolean
  },
  data() {
    return {
      localColumns: [...this.columns],
      columnFilterExpanded: false
    }
  },
  methods: {
    // to select / deselect all the children when parent option value change
    // in columns filter
    parentOptionChanged(value, optionObject) {
      const childrenValues = optionObject.children.map(child => child.value);
      const columns = this.columns.filter(column => childrenValues.indexOf(column) === -1);
      if (value) {
        this.$emit('update:columns', [...columns, ...childrenValues]);
        this.localColumns = [...columns, ...childrenValues];
      } else {
        this.$emit('update:columns', columns);
        this.localColumns = [...columns];
      }
    },
    // to check indertimated status for option with children in columns filter
    isIndeterminated(optionObject) {
      const filteredChildren = optionObject.children.filter((child) =>this.columns.indexOf(child.value) !== -1);
      return filteredChildren.length > 0 && filteredChildren.length < optionObject.children.length;
    },
    isFullySelected(optionObject) {
      const filteredChildren = optionObject.children.filter((child) =>this.columns.indexOf(child.value) !== -1);
      if (filteredChildren.length === 0) return false;
      if (filteredChildren.length === optionObject.children.length) return true;
    }
  },
  watch: {
    columns: {
      handler () {
        if (JSON.stringify(this.columns) != JSON.stringify(this.localColumns))
          this.localColumns = deepClone(this.columns || [])
      },
      deep: true
    },
    localColumns: {
      handler () {
        if (JSON.stringify(this.columns) != JSON.stringify(this.localColumns))
          this.$emit('update:columns', this.localColumns)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  $style: ColumnsFilter;
  .#{$style} {
    &__filter-button {
      background: transparent !important;
      border: 1px solid $N3;
      span {
        margin-left: 10px;
        margin-right: 15px;
      }
    }
    &__icon-active {
      color: $N2;
      fill: $N2;
    }
    &__icon-inactive {
      color: $N3;
      fill: $N3;
      &.expanded {
        transform: rotate(-180deg);
      }
    }
  }

  .dense-item {
    max-height: 40px;
  }
  .min-height-0 {
    min-height: 0px;
  }
  .parent-checkbox {
    max-height: 40px;
    margin-top: 6px;
  }
</style>