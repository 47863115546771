<template>
  <div class="OptionsList">
    <div class="OptionsList__new_opt_notification" :align="appsTab ? 'center' : ''" :class="{ app_tab: appsTab }"
      v-if="newOptAdded">
      <h4> To apply new environments please redeploy your app </h4>
    </div>
    <v-layout v-for="(option, index) in newOptions" :key="index" align-center class="OptionsList__row">
      <v-flex xs6>
        <v-text-field v-model="option.key" :placeholder="keyLabel" filled dark dense hide-details
          class="OptionsList__input" />
      </v-flex>
      <v-flex xs6>
        <v-text-field
          v-model="option.value"
          :append-icon="passwordVisibility[index] ? 'mdi-eye-off' : 'mdi-eye'"
          :placeholder="valueLabel"
          filled
          dense
          dark
          hide-details
          :type="passwordVisibility[index] ? 'text' : 'password'"
          @click:append="togglePasswordVisibility(index)"
          class="OptionsList__input"
        />
      </v-flex>

      <v-btn icon dark class="ml-4 mr-3" color="red" @click="deleteOption(option)">
        <v-icon>delete</v-icon>
      </v-btn>
    </v-layout>

    <span @click="addOption()" class="OptionsList__add_option">
      <v-icon color="y1"> mdi-plus </v-icon> Add new environment variable
    </span>
    <br />
    <div align="right">
      <v-btn dark color="y1" class="OptionsList__save_options" @click="saveOptions()">
        <span> Save</span>
      </v-btn>
    </div>

    <div v-if="errorMain" class="error-main pa-4">
      {{ errorMain }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  name: "OptionsList",
  props: {
    options: Array,

    keyLabel: {
      type: String,
      default: "Key",
    },
    valueLabel: {
      type: String,
      default: "Value",
    },
    appsTab: {
      type: Boolean,
      default: false,
    },

    readonly: Boolean,
  },

  data() {
    return {
      newKey: "",
      newValue: "",
      newOptions: _.cloneDeep(this.options),
      errorMain: "",
      newOptAdded: false,
      passwordVisibility: {},
    };
  },
  methods: {
    checkPremiumEnvKey() {
      const isPremiumKey = this.newKey.replace(" ", "").toUpperCase() == 'MAX_UPLOAD_SIZE';
      return isPremiumKey && ['bronze', 'iron', 'copper'].includes(this.$store.state.user.current.plan_id)
        || !isPremiumKey;
    },
    saveOptions() {
      this.errorMain = "";
      if (this.appsTab) {
        let params = {
          id: this.$store.state.site_app.parseSetting.id,
          environment: this.newOptions
        }
        this.$store.dispatch("site_app/updateParseEnvironments", params).then(() => {
          this.newOptAdded = true;
        })
      }
      else
        this.$store.dispatch("sites/updateEnvironmens", { site: { new_environements: this.newOptions } })
          .then((res) => {
            if (res.body.site.environments == null)
              this.newOptions = []
            else
              this.newOptions = res.body.site.environments;
            this.newOptAdded = true;
          })
    },
    addOption() {
      this.errorMain = "";
      const newIndex = this.newOptions.length;
      this.newOptions.push({ key: "", value: "" });
      this.$set(this.passwordVisibility, newIndex, false);
    },
    deleteOption(opt) {
      const index = this.newOptions.findIndex((env) => env.key === opt.key);
      if (index !== -1) {
        this.newOptions.splice(index, 1);
        this.$delete(this.passwordVisibility, index);
        // Re-index the passwordVisibility object
        this.passwordVisibility = Object.fromEntries(
          Object.entries(this.passwordVisibility)
            .filter(([key, _]) => key > index)
            .map(([key, value]) => [key - 1, value])
        );
      }
    },
    togglePasswordVisibility(index) {
      this.$set(this.passwordVisibility, index, !this.passwordVisibility[index]);
    },
  },
  watch: {
    "$store.state.site_app.parseSetting": {
      handler(newVal, oldVal) {
        if (this.appsTab)
          this.newOptions = _.cloneDeep(newVal.environments);
          this.passwordVisibility = Object.fromEntries(
            this.newOptions.map((_, index) => [index, false])
          );
      },
      deep: true
    }
  },
};
</script>

<style lang="scss">
$style: OptionsList;

.#{$style} {
  &__row {
    gap: 16px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__add_option {
    color: $Y1;
    cursor: pointer;
  }

  &__save_options {
    margin-right: 17px;
  }

  &__new_opt_notification {
    width: 83.5%;
    padding: 10px;
    background: orange;
    margin-bottom: 15px;
    border-radius: 4px;

    &.app_tab {
      width: 90%;
    }
  }

  &__input {
    &.v-text-field--filled.v-input--dense>.v-input__control>.v-input__slot {
      background-color: transparent;
      border: 1px solid $N3;
      box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
      filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.4));
      border-radius: 4px;
    }
  }
}
</style>
