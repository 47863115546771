<template>
  <div class="narrow-container mx-2" v-if="!loading">
    <div class="members_table mx-4 mt-6">
	    <v-data-table 
        :headers="headers"
        :items="productionSites"
        :sort_by="sortBy"
        class="ProjectSites__members_table"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title> {{ project.name}} Sites</v-toolbar-title>
          </v-toolbar>
        </template>

        <template v-slot:item.site_name="{item}" >
          <span @click="openSite(item.id)" class="ProjectSites__open_site"> 
            {{item.site_name}}
          </span>
        </template>

        <template v-slot:item.currentVersionStatus="{item}">
          <span :class="'ProjectSites__' +item.currentVersionStatus">
            {{ item.currentVersionStatus }}
          </span>
        </template>

        <template v-slot:item.link="{item}">
          <span @click="openSiteUrl(item.url)" class="ProjectSites__open_site"> 
            {{ "http://" + item.url }}
             <the-icon class="ml-1" icon="arrow-up-and-right" />
          </span>
        </template>

        <template v-slot:item.currentVersionScopedId="{item}" class="ProjectSites__version">
          <span> {{ item.currentVersionScopedId }} </span>
        </template>

      </v-data-table>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import _ from 'lodash';
  export default {
  	name: 'ProjectSites',
  	data() {
	  return{
	  	loading: false,
      sortBy: [
        { key: 'site_name', order: 'asc' },
        { key: 'currentVersionDate', order: 'asc' },
        { key: 'currentVersionScopedId', order: 'asc' },
        { key: 'currentVersionStatus', order: 'asc' },

      ],
	  	headers: [
          {
            text: 'NAME',
            value: 'site_name',
            key: 'site_name',
            width: "20%",
            sortable: true
          },
          {
            text: 'LINK',
            key: 'link',
            value: 'link',
            width: "35%",
            sortable: false
          },
          {
            text: "VERSION",
            key: 'currentVersionScopedId',
            value: 'currentVersionScopedId',
            width: "110px",
            sortable: true
          },
          {
            text: 'STATUS',
            key: 'currentVersionStatus',
            value: 'currentVersionStatus',
            sortable: true
          },
          { 
            text: 'DATE ADDED', 
            key: 'currentVersionDate',
            value: 'currentVersionDate', 
            sortable: true 
          }
        ],
        arrayObject: []
	  }
  	},
  	created() {
      this.fetchProjectSites();
  	},
  	methods: {
      fetchProjectSites() {
        this.loading = true;
        this.current_project_id = this.project.id;
	      this.$store
	        .dispatch("sites/loadSitesList", this.project.id)
	        .then(() => {
	          this.loading = false;
            this.arrayObject = _.cloneDeep(this.$store.state.projects.active.allObjects).filter((s) => s != undefined);
	        })
	        .catch((error) => {
	          console.log(error);
	        });
      },
      openSiteUrl(url) {
        window.open('http://' + url, '_blank')
      },
      openSite(id) {
        this.$router.push("/site/" + id);
      }
  	},
    mounted() {
      this.fetchProjectSites();
    },
  	computed: {
	  ...mapState({
        project: ({ projects }) => projects.active,
      }),
      productionSites() {
        if (this.arrayObject.length > 0)
          return this.arrayObject.filter((s) => s.mode == 'production')
        else
         return [];
      }
  	},
  	watch: {
      "$store.state.projects.active.id": {
        handler (newVal, oldVal) {
          this.fetchProjectSites();
        },
        deep: true
      },
    }
  }
</script>

<style lang="scss" scoped>
	$style: ProjectSites;
  .#{$style} {
    &__open_site {
      cursor: pointer;
    }

    &__members_table {
      background: $N4 !important;
      border: 1px solid $N5;
      header {
        background-color: $N4 !important;
      }
    }

    &__deploying {
      padding: 10px;
      border-radius: 8px;
      background: rgba(255, 126, 33, 0.2);
      color: $Y1;
    }

    &__deployed {
      padding: 10px;
      border-radius: 8px;
      background: green;
      color: lightgreen;
    }

    &__failed {
      padding: 10px;
      border-radius: 8px;
      background: #361c2a;
      color: #E94A53;
    }
  }
</style>
