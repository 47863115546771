<template>
  <div>
  	<v-btn
      class="mt-6 mx-auto d-block cloudAuthBtn"
      :disabled="loading"
      @click="googleAuth"
      type="submit"
    >
      <the-icon class="mr-2" icon="google" />
      Continue with Google
    </v-btn>

    <v-btn
      class="mt-2 mx-auto d-block cloudAuthBtn"
      :disabled="loading"
      @click="githubAuth"
      type="submit"
    >
      <v-icon dark left> mdi-github </v-icon>
      Continue with Github
    </v-btn>

    <v-btn
      class="mt-2 mx-auto d-block cloudAuthBtn"
      :disabled="loading"
      @click="bitbucketAuth"
      type="submit"
    >
      <v-icon dark class="ml-3" left color="blue">mdi-bitbucket</v-icon>
      Continue with Bitbucket
    </v-btn>
  </div>
</template>

<script>
  import {
  GITHUB_CLIENT_ID,
  BITBUCKET_KEY,
  } from "@/env";
  export default {
    name: "CloudAuth",
    props: {
      loading: Boolean
    },
    methods: {
      async googleAuth() {
	      const authCode = await this.$gAuth.getAuthCode()
	      const res = await this.$store.dispatch('user/googleAuth', authCode)
        if (!res.body.registration)
        {
          await this.$store.dispatch("application/load")
          if (this.$route.query.redirect) 
            this.$router.push({ path: this.$route.query.redirect})
          else 
            this.$router.push({ name: "dashboard"}) 
        }
        else
        {
          if (this.$route.name == 'registration'){
            this.$emit('finishedReg', true)
          }
          else
            this.$router.push( {name: 'registration', params: { finishedReg: true} })
        }

	    },
	    bitbucketAuth() {
        let redirect_host = window.location.host.split('.')[0];
	  	  let redirect_uri = "https://getforge.com/oauth/bitbucket/" + redirect_host + "?logAuth=true"
	  	  let oauthUrl =
          "https://bitbucket.org/site/oauth2/authorize?client_id=" +
          BITBUCKET_KEY +
          "&response_type=code&redirect_uri=" + redirect_uri;
          window.location = oauthUrl;
	    },
	  githubAuth() {
      let redirect_host = window.location.host.split('.')[0];
	  	let redirect_uri = 'https://getforge.com/oauth/github/' + redirect_host + "?logAuth=true"
	  	let oauthUrl =
          "http://github.com/login/oauth/authorize?client_id=" +
          GITHUB_CLIENT_ID +
          "&scope=repo,user&redirect_uri=" + redirect_uri;
        window.location = oauthUrl;
	  }
    }
  }
	
</script>

<style lang="scss" scoped>
  .cloudAuthBtn {
    width: 100%;
    background-color: #232235 !important;
    border: 1px solid #36344C;
  }
</style>