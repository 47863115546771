<template>
  <div class="PluginPublishFlowPreviewStep">
    <div class="d-flex mb-8">
      <img :src="appContent.keyImage" v-if="appContent.keyImage" class="key-image" />
      <div v-else class="key-image placeholder"></div>
      <div class="ml-4">
        <h3 class="mb-4">{{app.Name}}</h3>
        <div v-if="categoryNames">
          <span class="tag" v-for="categoryName in categoryNames">{{categoryName}}</span>
        </div>
      </div>
    </div>
    <h6 class="mb-2">Submit to Review</h6>
    <p class="mb-3">For the plugin to be published to the marketplace, it needs to go through the audit process established by <b>{{ publisher.publisher_name }}</b>. The necessary checks include:</p>
    <ul class="mb-6">
      <li>Compliance check</li>
      <li>Security check</li>
      <li>Performance check</li>
    </ul>
    <p class="mb-2">Before pressing Submit, make sure to review our full guide:</p>
    <a>Marketplace Plugin Guide</a>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  export default {
    name: "PluginPublishFlowPreviewStep",
    props: {
      categories: Array
    },
    data() {
      return {
      };
    },

    computed: {
      ...mapState('plugin_publishing', {
        app: state => state.app,
        appData: state => state.appData,
        appContent: state => state.appContent,
      }),
      ...mapState({
        publisher: state => state.sites.active.publisherInfo,
      }),
      categoryNames() {
        if (this.categories && this.appContent?.Categories) {
          return this.categories
            .filter(category => this.appContent.Categories.includes(category.value))
            .map(category => category.text)
        }
        return [];
      }
    },
  };
</script>
<style scoped lang="scss">
  $style: PluginPublishFlowPreviewStep;
  .#{$style} {
    h3 {
      font-weight: normal;
    }
    span.tag {
      padding: 5px 8px;
      font-size: 12px;
      font-weight: 400;
      border: 1px solid $N3;
      border-radius: 6px;
      margin-right: 8px;
    }
    h6 {
      font-size: 14px;
      font-weight: normal;
    }
    p, li {
      color: $N2;
      font-size: 14px;
    }
    ul {}
    li {
      margin-bottom: 12px;
    }
    a {
      color: $Y1;
    }
    .key-image {
      max-height: 64px;
      width: auto;
      border-radius: 4px;
    }
    .key-image.placeholder {
      background: green;
      width: 64px;
      height: 64px;
    }
  }
</style>