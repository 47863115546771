<template>
  <div class="FigmaPicker">
    <v-alert
      v-if="error"
      :value="true"
      severity="error"
      class="figma-picker-error"
    >
      {{ error }}
    </v-alert>
    <v-row>
      <v-col cols="6">
        <span class="FigmaPicker__label">Project</span>
        <div class="project-list">
          <v-autocomplete
            v-model="selectedProject"
            :items="projects"
            placeholder="Find a project..."
            @input="onProjectSelect"
            item-text="name"
            item-value="id"
            outlined
            hide-details
            dense
          ></v-autocomplete>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(file, index) in files" :key="index" cols="12" sm="6" md="4" lg="3">
        <figma-card :file="file" :onSelect="onSelect" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Parse from 'parse';
import FigmaCard from "./FigmaCard";
export default {
  props: {
    token: String,
    onSelect: Function,
    teamId: String
  },
  components: {
    FigmaCard
  },
  data() {
    return {
      projects: [],
      selectedProject: null,
      files: [],
      error: '',
    };
  },
  created() {
    this.onInitLoad();
  },
  methods: {
    async onInitLoad() {
      try {
        const res = await Parse.Cloud.run('getTeamProjects', { token: this.token, teamId: this.teamId });
        if (res.status === 'success') {
          this.projects = res.result.projects;
          this.isLoading = false;
        }
      } catch (e) {
        console.error('Error retrieving getTeamProjects.', e);
      }
    },
    async onProjectSelect() {
      const res = await Parse.Cloud.run('getProjectFiles', { token: this.token, projectId: this.selectedProject });
      if (res.status === 'success') {
        this.files = res.result.files;
        this.isLoading = false;
      }
    }
  },
  watch: {
	  'teamId': {
	  	handler() {
  		  this.onInitLoad();
	  	}
	  },
    'token': {
	  	handler() {
  		  this.onInitLoad();
	  	}
	  }
  },
};
</script>
