<template>
  <div class="MuralIntegration">
    <div v-if="status === 'loading'" class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div v-else-if="status === 'success'">
      <MuralPicker :token="token" :onMuralSelect="onMuralSelect" v-if="token"/>
    </div>
    <div v-else-if="status === 'failure'" class="mb-40">
      <a href="https://www.mural.co/" class="text-center">
        <img src="/logo.svg" alt="Mural" width="150" class="mx-auto" />
      </a>
      <div class="bg-white px-12 py-8 mt-6 rounded-3xl text-xl text-center w-400">
        Something went wrong 😳
      </div>
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import { initParse } from "@/utils/parse";
import { safeJSONParse, getExtensionState } from "@/utils/common";
import MuralPicker from "./Mural/MuralPicker";

export default {
  name: 'MuralIntegration',
  components: {
    MuralPicker
  },
  data() {
    return {
      extensionId: '',
      token: '',
      status: '',
      pluginSlug: '',
      siteId: ''
    }
  },
  methods: {
    async init () {
      const query = window.location.href.split('?')[1];
      const urlParams = new URLSearchParams(query);
      const code = urlParams.get('code');
      if (code) {
        try {
          const tokenResponse = await Parse.Cloud.run('token', { code });
          if (tokenResponse.success && tokenResponse.accessToken) {
            this.token = tokenResponse.accessToken;
            this.status = 'success';
          } else {
            this.status = 'failure';
          }
        } catch (error) {
          this.status = 'failure';
          console.error('error in token cloud code', error);
        }
      }
    },
    onMuralSelect(mural) {
      let extensionState = getExtensionState(this.extensionId);
      extensionState = {...extensionState, picker: { mural: { url: mural._canvasLink  } }};
      localStorage.setItem(this.extensionId, JSON.stringify(extensionState));

      this.$router.push({ name: "site_plugin", params: { id: this.siteId, slug: this.pluginSlug } });
    }
  },
  async mounted() {
    const integrationState = safeJSONParse(localStorage.getItem('integrationState'));
    if (integrationState.kind !== 'mural') return;
    const {parseAppId, parseServerURL, pluginSlug, siteId, extensionId} = integrationState;
    if (parseAppId && parseServerURL && pluginSlug && siteId && extensionId) {
      this.pluginSlug = pluginSlug;
      this.siteId = siteId;
      this.extensionId = extensionId;
      initParse(parseServerURL, parseAppId);
      this.status = 'success';
      await this.init();
    }
  },
};
</script>

<style scoped lang="scss">
  $style: MuralIntegration;
  .#{$style} {
    background-color: $N4;
    border: 1px solid $N5;
    margin: 30px;
    padding: 20px;
    min-height: calc(100vh - 150px);
  }
</style>
