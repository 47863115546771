<template>
  <div>
    <div v-if="showBrowser">
      <div class='header-browser' align="left">
        <v-icon dark left>mdi-github</v-icon>
        <span> CHOOSE A REPO </span>
        <v-btn color="error" class="one_line" text @click="showBrowser = false"> CANCEL </v-btn>
        <span v-show="!reposSelected">
          <v-select
            v-model="selectedOrganisation"
            :items="filteredOrgs"
            placeholder="Organisation"
            @change="fetchRepos"
            item-text="login"
            item-value="login"
            class="d-inline-block"
            clearable
            dark
            ></v-select>
        </span>
        <span v-if="!reposSelected">
          <v-text-field
            dark
            class="d-inline-block"
            placeholder='Filter'
            v-model="filterText"
            filled
          ></v-text-field>
        </span>
        <br/>
        <br/>
        <div class="mt-3 mb-3">
          <span @click="clearRepoPath"> <v-icon dark left>mdi-folder</v-icon> Repositories </span>
            <span v-if="currentGitRepoPath"> 
              <span @click='selectRepo(currentGitPath)'> > {{currentGitPath}} </span>
              <span v-for="(component, index) in pathComponents" @click='selectFolderInRepo(component, index)'>
                > <v-icon dark left>mdi-folder</v-icon> {{component}}

              </span>
              <span align='right'>
                <v-btn small @click="selectFolder(reposCursor)" class="white--text choose-btn" color="y1">Choose This Folder</v-btn>
              </span>
          </span>
        </div>
      </div>

      <div v-if="!currentGitRepoPath" class="mt-4 repos-block" align="left">
        <div class="mt-1 git-row" v-for="repo in filteredRepos" @click="selectRepo(repo.full_name)">
          <v-icon v-if="repo.private" dark left>mdi-folder-key</v-icon>
          <v-icon dark v-else left>mdi-folder</v-icon>
          <span> {{repo.full_name}} </span>
        </div>
      </div>

      <div class='mt-2 repos-block' align="left" v-if="currentGitRepoPath">
        <div>
          <v-icon dark left>mdi-source-branch</v-icon>
          <v-select
            v-model="selectedBranch"
            :items="gitBranches"
            @change='branchSelected'
            item-text="name"
            item-value="name"
            class="d-inline-block"
            dark
          ></v-select>
        </div>
        <div class="mt-1 git-row" v-for="folder in reposInList" @click="selectFolderInRepo(folder)">
          <v-icon  dark left>mdi-folder-outline </v-icon>
          <span> {{folder.name}} </span>
        </div>
        <div class="mt-1 git-row" v-for="file in filesInList">
          <v-icon dark left>mdi-file-document-outline</v-icon>
          <span> {{file.name}} </span>
        </div>
      </div>
    </div>
    <div class="syncing-text" v-else>
      <p>
        <b>{{activeSite.url}} </b> is syncing with <b>{{selectedRepo}}</b>
        <span v-if="branch != null"> {{branch}}</span>
      </p>
      <v-btn @click="showBrowser = true" class="white--text mt-3" color="orange darken-4">
        <span v-if="selectedRepo ==null" class="pr-1">Select </span> <span v-else class="pr-1"> Change  </span> Github Folder
      </v-btn>
      <div v-if="selectedRepo != null" align="center">
        <v-checkbox
          dark
          class="align-center justify-center d-block checkbox-auto"
          @change="changeAutoDeploy"
          v-model="deployHandler"
          :label="`${deployHandler.toString() == 'true' ? 'Disable' : 'Enable'} Auto-Deployment`"
        ></v-checkbox>
        Push to your github repository and automatically trigger a re-deployment
      </div>
    </div>
  </div>
</template>

<script >
  export default {
    name: "GithubBrowser",
    props: {
        branch: String,
        path: String,
        url: String,
        token: String,
        autoDeploy: Boolean
    },

    data() {
      return {
        repos: [],
        selectedRepo: this.path,
        selectedBranch: this.branch,
        branches: [],
        files: [],
        reposFolderPath: "",
        organisations: [],
        reposCursor: this.path,
        showBrowser: this.path == null,
        deployHandler: this.autoDeploy,
        filePath: "",
        filterText: "",
        selectedOrganisation: null
      }
    },
    methods: {
      async getDataFromApi(path, type, withPage = true) {
        let page = 1;
        let newPage = true;
        while(newPage == true ) {
          let reqParams = {params: {}, headers: {'Authorization': 'token ' + this.token}}
          if (withPage)
            reqParams["params"] ={page: page};
          let res = await this.$http.get(this.url + path, reqParams)
          if (res.body != undefined)
          {
            if (res.body.length > 0)
            {
              newPage = true;
              page += 1;
              if (type == 'branches' && this.selectedBranch == null)
                this.selectedBranch = res.body[0].name;
              if (type == 'files')
                this[type] = res.body;
              else
                this[type] = this[type].concat(res.body);
            }
            else
              break;
            if (!withPage)
              break;
          }
          else
            break;
        }
      },
      fetchRepos() {
        this.repos = [];
        if (this.selectedOrganisation != null && this.selectedOrganisation.length > 0)
          this.getDataFromApi(`/orgs/${this.selectedOrganisation}/repos`, 'repos')
        else
          this.getDataFromApi("/user/repos", 'repos');
      },
      fetchOrganisations() {
        this.organisations = [];
        this.getDataFromApi("/user/orgs", 'organisations');
      },
      selectRepo(full_name) {
        this.reposCursor = full_name;
        this.branches = [];
        this.files = [];
        let branchContent = ""
        if (this.selectedBranch != null)
          branchContent = '?ref=' + this.selectedBranch;
        let contentPath = ''
        if (this.pathComponents.join('/').length > 0)
          contentPath = '/' + this.pathComponents.join('/');
        contentPath += branchContent;
        this.getDataFromApi("/repos/"+ this.currentGitPath +"/branches", 'branches');
        this.getDataFromApi("/repos/" + this.currentGitPath + "/contents" + contentPath, 'files', false)
      },
      branchSelected() {
        this.files = [];
        let contentPath = '';
        let branchContent = ""
        if (this.selectedBranch != null)
          branchContent = '?ref=' + this.selectedBranch;
        if (this.pathComponents.join('/').length > 0)
          contentPath = '/' + this.pathComponents.join('/');
        contentPath += branchContent;
        this.getDataFromApi("/repos/" + this.currentGitPath + "/contents" + contentPath, 'files', false)
      },
      selectFolderInRepo(folder, index = -1) {
        if (index == -1) {
          this.reposCursor = this.currentGitPath + '/' + folder.path;
        }
        else {
         this.reposCursor = this.currentGitPath + '/' + this.pathComponents.slice(0, index + 1).join('/');
        }
        this.files = [];
        let branchContent = '';
        if (this.selectedBranch != null)
          branchContent = '?ref=' + this.selectedBranch;
        let contentPath = this.pathComponents.join('/') + branchContent;
        this.getDataFromApi(/repos/ + this.currentGitPath + '/contents/' + contentPath , 'files', false)
      },
      clearRepoPath() {
        this.reposCursor = null;
        this.selectedRepo = null;
        this.selectedBranch = null;
      },
      selectFolder(folderPath) {
        let params = {
          github_path: folderPath,
          github_branch: this.selectedBranch,
          bitbucket_path: null,
          bitbucket_branch: null,
          dropbox_path: null,
          oauth_user_id: this.$store.state.user.current.id
        };
        this.$store.dispatch('sites/update', {site: params});
        this.selectedRepo = folderPath;
        this.showBrowser = false;
      },
      changeAutoDeploy() {
        this.$store.dispatch('sites/update', {site: {github_autodeploy: this.deployHandler}});
      }
    },
    mounted() {
        this.fetchOrganisations();
        this.fetchRepos();
        if (this.selectedRepo != null)
            this.selectRepo(this.selectedRepo);
    },
    computed: {
      gitRepos() {
        let sorted = this.repos.sort((a,b) => {
          a.name.localeCompare(b.name);
        });
        return sorted;
      },
      currentGitRepoPath() {
        return this.reposCursor != null || this.selectedRepo != null;
      },
      filesInList() {
        let sorted = this.files.filter(f => f.type == 'file').sort((a,b) => a.name.localeCompare(b.name));
        return sorted;
      },
      reposInList() {
        let sorted = this.files.filter(f => f.type == 'dir').sort((a,b) => a.name.localeCompare(b.name));
        return sorted;
      },
      currentGitPath() {
        let repoPath = this.reposCursor == null ? this.selectedRepo : this.reposCursor;
        return repoPath.split('/').slice(0, 2).join('/');
      },
      pathComponents() {
        let repoPath = this.reposCursor == null ? this.selectedRepo : this.reposCursor;
        return repoPath.split('/').slice(2, repoPath.length)
      },
      gitBranches() {
        let sorted = this.branches.sort((a, b) => a.name.localeCompare(b.name));
        return sorted;
      },
      filteredOrgs() {
        let sorted = this.organisations.sort((a, b) => a.login.localeCompare(b.login));
        return sorted;
      },
      reposSelected() {
        return this.reposCursor != null && this.reposCursor.length > 0;
      },
      filteredRepos() {
        if (this.filterText.length > 0)
        {
          let filtered = this.gitRepos.filter(f => f.full_name.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1);
          return filtered; 
        }
        else
          return this.gitRepos;
      }
    }
  }
</script>

<style>
  .one_line {
    margin-top: -5px;
  }
  .header-browser {
    border-bottom: 1px solid lightgray;
    border-bottom-color: rgba(255,255,255,0.1);
    padding-bottom: 4px;
  }
  .git-row {
    overflow: hidden;
    height: 36px;
    border-bottom: 1px solid lightgray;
    border-bottom-color: rgba(255,255,255,0.1);
    padding-top: 6px;
  }
  .syncing-text {
    align-items: center;
    text-align: center;
  }
  .repos-block {
    max-height: 700px;
    overflow-y: auto;
  }
  .choose-btn {
    margin-top: 8px;
    float: right;
    bottom: 10px;
  }
  .checkbox-auto .v-input--selection-controls__input {
    vertical-align: top;
  }
  .checkbox-auto .v-input__slot {
    display: block;
    text-align: center;
  }
</style>
