<template>

  <div class="d-flex SiteVersionComments__comments_container">
  	<div class="SiteVersionComments__comments_block" id="SiteVersionComments__comments_block" v-if="comments.length > 0 && !loading">
      <div v-for="comment in comments" class="SiteVersionComments__comment" :id="'comment_' + comment.id">
        <SiteVersionComment :comment="comment" :version="version"/>
      </div>
    </div>
    <div class="SiteVersionComments__noComment" v-else-if="!loading">
    	<the-icon
    	  class="SiteVersionComments__comment_icon"
          icon="comment"
        />
    	<h3> No comments yet </h3>
    	<div class="no_comments_small"> Once you start writing and receiving comments they’ll appear here... </div>
    </div>
    <div v-else-if="loading" class="spinner mt-12" align="center">
      <IntersectingCirclesSpinner :animation-duration="1200" :size="100" :color="'#496DDB'" />
    </div>


    <v-footer class="SiteVersionComments__footer" fixed>
	 <SiteVersionMentionInput @input="send" :placeholder="placeholder" ref="childComponent" />
	 <v-btn class="y1 SiteVersionComments__footer_btn" @click="addComment">
	   <v-icon >
         mdi-send
       </v-icon>
     </v-btn>
    </v-footer>

  </div>
	
</template>

<script>

import {IntersectingCirclesSpinner} from "epic-spinners";
import SiteVersionMentionInput from "@/components/TheSite/SiteVersion/SiteVersionComments/SiteVersionMentionInput";
import SiteVersionComment from "@/components/TheSite/SiteVersion/SiteVersionComments/SiteVersionComment"
import _ from "lodash";
export default {
  name: "SiteVersionComments",
  components: { SiteVersionMentionInput, IntersectingCirclesSpinner, SiteVersionComment },
  props: {
    comments: Array,
    placeholder: String,
    version: Object,
    loading: Boolean,
    cloudCode: Boolean
  },
  data() {
    return {
      msg: "",
      mentions: [],
    };
  },
  methods: {
  	send(v) {
      this.msg = v.value;
      this.mentions = v.mentions;
  	},
  	scrollToBottom() {
      let elem = document.getElementById('SiteVersionComments__comments_block');
      if (elem)
        elem.scrollTop = elem.scrollHeight;
    },
  	addComment() {
  	  const currentUserId = this.$store.state.user.current.id;
      let siteUsers = _.cloneDeep(this.$store.state.site_user.list);
      let usersIds = siteUsers.filter(su => this.mentions.includes(su.id)).map(su => su.user_id);
      this.$store.dispatch('comments/create', {
	    message: this.msg,
        user_id: currentUserId, 
        mentioned_users: usersIds,
        version_id: this.version.id
      })
      this.$refs.childComponent.clearInput()
  	}
  },
  mounted() {
    this.scrollToBottom();
  },
}
</script>

<style lang="scss" scoped>
$style: SiteVersionComments;
.spinner {
  height: 53vh;
}
.#{$style} {

  &__comments_container {
    justify-content: center;
  }

  &__comment {
    display: inherit;
  }

  &__noComment {
  	display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 231px);
    h3 {
      font-size: 14px;
      font-family: Montserrat;
      font-weight: 500;
      line-height: 20px;
    }
    .no_comments_small {
      margin-top: 8px;
      width: 344px;
      text-align: center;
    }
    .#{$style}__comment_icon {
      margin-bottom: 15px;
    }
    div {
      color: $N2;
    }
  }

  &__comments_block {
    padding-top: 16px;
    padding-left: 16px;
    padding-right:16px;
    height: calc(100vh - 244px);
    display: flex;
    flex-shrink: 0;
    flex-direction: column-reverse;
    width: 100%;
    overflow-y: scroll;
    overscroll-behavior: contain;
  }


  &__footer {
  	min-height: 80px;
  	padding: 16px 28px 24px 28px;
    align-items: flex-start;
    border-radius: 0px 0px 0px 12px;
    border-top: 1px solid $N7;
    background: $N5;
    .#{$style}__footer_btn {
      position: absolute;
      right: 16px;
    }
  }
}

</style>