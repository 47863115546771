<template>
  <div>
    <div class="spinner" v-if="showSpinner">
      <IntersectingCirclesSpinner :animation-duration="1200" :size="100" :color="'#496DDB'" />
    </div>
  </div>
</template>

<script>
  import {Site as SiteResource} from "@/resources/forge";
  import {Site} from '@/models/Site';
  import {IntersectingCirclesSpinner} from "epic-spinners";
  import { MODAL_ALERT } from "@/components/Modals"; 
  export default {
    name: "ConfirmChange",
    data() {
      return {
        code: '',
        showSpinner: true
      }
	  },
    components: {IntersectingCirclesSpinner},
    created() {
      let params = {
        confirm_change_id: this.$route.params.confirm_change_id,
        confirmation_token: this.$route.query.token
      }
      let site_id =  this.$route.params.site_id;
      SiteResource.confirmChangeOwner({id: site_id}, params)
        .then(({body}) => {
          let site = new Site(body.site);

          this.$store.commit("sites/update", site);
          if (site.projectId) {
            this.$store.dispatch("projects/load", null, { root: true });
            this.$router.push({path: "/project/" + site.projectId + '/access'})
          }
          else{
            this.$store.dispatch('invitation/load', {type: 'site', type_id: site_id });
            this.$store.dispatch('site_user/load', site_id)
            this.$router.push({ path: "/site/" + site_id + "/access"})
          }
        })
        .catch((e) => {
          console.log(e);
          this.showSpinner = false;
          this.$store.commit("application/openModal", {
            component: MODAL_ALERT,
            props: {
              title: "Confirm Site Rights",
              text: "Sorry but this confirmation is wrong.",
              actionBtnLabel: "Confirm",
              showCancelBtn: false,
              action: () => {
                this.$router.push({ name: "dashboard" });
              },
            },
          });
        })
    }
  }
</script>

<style lang="scss" scoped>
  .spinner {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
</style>
