import {Project as ProjectResource} from "@/resources/forge";
import {Project} from "@/models/Project";
import router from "@/router";

const store = {
  namespaced: true,
  state: {
    list: [],
    active: null
  },
  mutations: {
    /**
     * Sets the list of projects in the state.
     * @param {Array} list - The list of projects.
     */
    setList (state, list) {
      state.list = list;
    },
    /**
     * Sets the active project in the state.
     * @param {Object} activeProject - The active project.
     */
    setActive (state, activeProject) {
      state.active = activeProject;
    },
    /**
     * Creates a new project and adds it to the state.
     * @param {Object} project - The new project to be added.
     */
    create(state, project) {
      state.list = [...state.list, project];
    },
    /**
     * Updates a project in the state.
     * @param {Object} params - The parameters for updating the project.
     */
    update(state, params) {
      const project = state.list.find(p => p.id == params.id);
      if (!project)
        return;

      Object.assign(project, params);
    },
    /**
     * Deletes a project from the state.
     * @param {string} id - The ID of the project to be deleted.
     */
    delete(state, id) {
      state.list = state.list.filter(p => id != p.id);
    },
  },
  actions: {
    /**
     * Loads the projects and sets them in the state.
     */
    async load({commit}) {
      const res = await ProjectResource.get();
      commit('setList',
        res.body.projects.map(p => new Project(p)));
    },
    /**
     * Creates a new project.
     * @param {Object} params - The parameters for creating the project.
     * @returns {Promise} A promise indicating the success of the operation.
     */
    create({commit, rootState}, params) {
      if (!params.name)
        return;

      return ProjectResource.create({project: params})
        .then(({body}) => {
          commit("create", new Project(body.project));
          if (rootState.organisations.current.id != 0)
            commit("organisations/incProjectsCount", {}, {root: true});
          else
            commit("user/incProjectsCount", {}, {root: true});
          router.push("/project/" + body.project.id);
        })
    },
    /**
     * Sets the active project.
     * @param {string} id - The ID of the project to be set as active.
     */
    setActive({commit, getters}, id) {
      const project = getters['findById'](id);
      commit('setActive', project);
    },
    /**
     * Updates a project.
     * @returns {Promise} A promise indicating the success of the operation.
     */
    update({commit}, params) {
      return ProjectResource.update({id: params.id}, params.project)
        .then(res => {
          commit("update", new Project(res.body.project));
          return res;
        })
    },
    /**
     * Deletes a project.
     * @param {string} id - The ID of the project to be deleted.
     */
    delete({state, commit, rootState}, id) {
      ProjectResource.delete({id: id})
        .then(() => {
          commit("delete", id);
          commit("sites/deleteProjectsSite", id, {root: true});
          if (rootState.organisations.current.id != 0)
            commit("organisations/descProjectsCount", {}, {root: true});
          else
            commit("user/descProjectsCount", {}, {root: true});
          if (id == state.active.id) {
            if (state.list.length)
              router.push("/project/" + state.list[0].id);
            else
              router.push("/");
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  getters: {
    /**
     * Finds a project by ID.
     * @param {String} id -  Target project id
     * @returns {Object|null} The found project or null if not found.
     */
    findById: state => id => {
      return state.list.find(p => p.id == id);
    }
  }
};

export default store;
