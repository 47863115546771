<template>
  <v-card class="ModalNewVersionDeployed">
    <div class="d-flex justify-space-between align-center mb-4">
      <v-btn icon color="white darken-3" class="btn-status">
        <v-icon v-if="siteType == deployed">check_circle</v-icon>
        <v-icon v-else>warning</v-icon>
      </v-btn>
      <v-btn icon text color="n3" @click="close">
        <v-icon>close</v-icon>
      </v-btn>
    </div>
    <template v-if="deployed == 'plugin'">
      <h4 class="ModalNewVersionDeployed__title" >
        New plugin deployed
      </h4>
      <span class="ModalNewVersionDeployed__label" v-if="siteType =='plugin'">
        <b>{{siteName}}</b> was successfully deployed.<br />To configure plugin visit Plugin Settings.
      </span>
      <template v-else>
        <span class="ModalNewVersionDeployed__label">
          <b>{{siteName}}</b> was deployed as a plugin, as we have identified Forge SDK file.
        </span>
        <a class="ModalNewVersionDeployed__link" href="">How to deploy sites?</a>
      </template>
    </template>
    <template v-else>
      <h4 class="ModalNewVersionDeployed__title">
        New Site deployed
      </h4>
      <span class="ModalNewVersionDeployed__label" v-if="siteType =='site'">
        <b>{{siteName}}</b> was successfully deployed. <br />You can update your site by uploading new versions.
      </span>
      <template v-else>
        <span class="ModalNewVersionDeployed__label">
          <b>{{siteName}}</b> was deployed as a site, as we haven’t identified any SDK file for a plugin. <br/>
          To create plugin, upload a new version with SDK file.
        </span>
        <a class="ModalNewVersionDeployed__link" href="">How to deploy plugins?</a>
      </template>
    </template>
    <div class="ModalNewVersionDeployed__footer">
      <template v-if="deployed == 'plugin'">
        <v-btn @click="close" color="n5" class="">Maybe later</v-btn>
        <v-btn @click="onGoToSettings" color="y1">Go to Settings</v-btn>
      </template>
      <template v-else>
        <v-btn @click="close" color="y1">Continue</v-btn>
      </template>
    </div>
  </v-card>
</template>

<script>
import {generateDomain} from '@/utils/common';

export default {
    name: "ModalNewVersionDeployed",
    props: {
      siteName: String,
      siteType: String,
      deployed: String 
    },
    methods: {
      onGoToSettings() {
        this.$router.push({ name: 'site_settings' });
        this.$emit('close');
      },
      close() {
        this.$emit('close');
      }
    }
  }
</script>

<style lang="scss">
$style: ModalNewVersionDeployed;
.#{$style} {
  &.theme--dark.v-card {
    @extend %modal-body-styles;
    width: 420px;
    padding: 24px 24px 0;
  }
  &__title {
    font-weight: 500;
    font-size: $H16;
    color: $white;
    margin-bottom: 12px;
  }
  &__label {
    @extend %label-styles;
    b {
      color: $N2;
    }
  }
  a.#{$style}__link {
    color: $Y1;
    text-decoration: none;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 0;
    gap: 16px;
    margin-bottom: 10px;
    & > .theme--dark.v-btn {
      color: $white;
    }
  }
  .btn-status:before {
    opacity: .08;
  }
}
</style>
