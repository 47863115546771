<template>
  <div class="field-boolean-switch">
    <div class="field-boolean-switch__input">
      <div class="field-boolean-switch__option" @click="setTrue">
        <the-icon :icon="model[schema.model] === true ? 'radio-on' : 'radio-off'" />
        <div class="field-boolean-switch__option-label ml-2">Yes</div>
      </div>
      <div class="field-boolean-switch__option" @click="setFalse">
        <the-icon :icon="model[schema.model] === false ? 'radio-on' : 'radio-off'" />
        <div class="field-boolean-switch__option-label ml-2">No</div>
      </div>
      <a class="ml-2 reset" @click.prevent="reset">Reset</a>
    </div>
  </div>
</template>

<script>
import VueFormGenerator from "vue-form-generator";
export default {
  name: "FieldBooleanSwitch",
  mixins: [VueFormGenerator.abstractField],
  props: [
    "model",
    "schema",
    "options"
  ],
  computed: {
    value() {
      return this.model[this.schema.model];
    }
  },
  methods: {
    setTrue() {
      this.$set(this.model, this.schema.model, true);
    },
    setFalse() {
      this.$set(this.model, this.schema.model, false);
    },
    reset() {
      this.$set(this.model, this.schema.model, null);
    }
  },
}
</script>

<style scoped lang="scss">
.field-boolean-switch {
  display: flex;
  flex-direction: column;
}

.field-boolean-switch__input {
  display: flex;
  align-items: center;
}

.field-boolean-switch__option {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;
  cursor: pointer;
}

.field-boolean-switch__option-label {
  margin-right: 8px;
}

a.reset {
  color: $Y1;
}
</style>
