<template>
  <v-menu
    :close-on-content-click="true"
    class="mx-4"
    offset-y
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        elevation="0"
        v-bind="attrs"
        v-on="on"
        @click="filterExpanded=!filterExpanded"
        class="PublisherDashboardTopPluginFilter__filter-button mx-2"
      >
        <the-icon
          icon="filter"
          class="PublisherDashboardTopPluginFilter__icon-prefix"
        />
        <span>{{ filterLabel }}</span>
        <the-icon
          icon="expand-down"
          :class="filterExpanded ? 'expanded PublisherDashboardTopPluginFilter__icon-inactive' : 'PublisherDashboardTopPluginFilter__icon-active'"
        />
      </v-btn>

    </template>

    <v-card max-height="250">
      <v-list>
        <v-list-item v-for="filterOption in filterOptions" :key="filterOption.slug" @click="onSelectFilterOption(filterOption)" 
          :class="`${filterOption.slug} ${ filter === filterOption.slug ? 'active' : '' }`">
          {{ filterOption.label }}
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>


<script>
export default {
  name: "PublisherDashboardTopPluginFilter",
  props: {
    filter: String,
    disabled: Boolean
  },
  data() {
    return {
      filterExpanded: false,
      filterOptions: [
        { slug: 'installsCount', label: 'By installs' },
        { slug: 'income', label: 'By income' },
        { slug: 'rating', label: 'By popularity' }
      ]
    }
  },
  computed: {
    filterLabel() {
      const filterOption = this.filterOptions.find(option => option.slug === this.filter);
      return filterOption?.label ?? '';
    },
  },
  methods: {
    onSelectFilterOption(filterOption) {
      this.$emit('updateSortBy', { sortBy: filterOption.slug });
    } 
  }
}
</script>

<style lang="scss" scoped>
  $style: PublisherDashboardTopPluginFilter;
  .#{$style} {
    &__filter-button {
      background: transparent !important;
      border: 1px solid $N3;
      span {
        margin-left: 5px;
        margin-right: 15px;
        width: 100px;
        display: block;
      }
    }
    &__icon-prefix {
      color: $B1;
      fill: $B1;
    }
    &__icon-active {
      color: $N2;
      fill: $N2;
    }
    &__icon-inactive {
      color: $N3;
      fill: $N3;
      &.expanded {
        transform: rotate(-180deg);
      }
    }
  }

</style>