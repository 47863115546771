import Vue from 'vue';
import VueRouter from 'vue-router';

import routes from './routes';
import hooks from './hooks';


Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  routes
});

hooks.forEach(hook => hook(router));

export default router;