<template>
  <div class="narrow-container mx-2">
    <div class="d-flex flex-row align-center justify-space-between pa-0 px-4 mt-4 w-full">
      <v-text-field
        v-model.lazy="search"
        filled
        dark
        dense
        hide-details
        prepend-inner-icon="search"
        placeholder="Search Report"
        class="pa-0 flex-grow-0 search-input w-full"
        :disabled="loading"
      />
    </div>
    <div class="TheReport">
      <report-card 
        v-for="report in list" 
        :title="report.title" 
        :lastUpdate="report.lastUpdate" 
        :route="report.route" 
        :key="report.title" 
        />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { size, orderBy, slice } from "lodash";
import moment from 'moment';

import { guid } from "@/utils/common";

import ReportCard from "./ReportCard";
export const MODE_GALLERY = "MODE_GALLERY";
export const MODE_LIST = "MODE_LIST";

export default {
  name: "TheReport",
  components: {
    ReportCard,
  },
  data() {
    return {
      list: [
        { title: 'Plugin Installs', route: 'report_plugin_installs' },
        { title: 'Top Developer', route: 'report_top_developers' }
      ],
      search: '',
      loading: false
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.projects.list,
      sites: ({ sites }) => sites.list,
    }),
    productionSites(){
      return this.sites.filter((s) => s.mode == 'production')
    },
    getRecentSites() {
      const sevenDaysBefore = moment().subtract(7, 'days');
      const sortedSites = orderBy(this.productionSites, ['updatedAt'], 'desc');
      const filteredSites = sortedSites.filter( site => moment(site.updatedAt) > sevenDaysBefore);
      if (size(filteredSites) > 4) {
        return slice(filteredSites, 0, 4);
      }
      return filteredSites;
    },
  },
  methods: {
    createSite() {
      if (!this.name) return;

      const siteData = {
        url: this.name,
      };
      this.$store
        .dispatch("sites/create", siteData)
        .then((res) => {
          this.errorMain = "";
          this.$router.push("/site/" + res.id);
        })
        .catch((error) => {
          if (!error) return;

          let errorMain = "";
          error.body.errors.url.forEach((message) => {
            errorMain = errorMain + message + ". ";
          });
          this.errorMain = errorMain.substr(0, errorMain.length - 4) + ".";
        });
    },
    createProject() {
      if (!this.name) return;

      const projectData = {
        id: guid(),
        name: this.name,
      };
      this.$store.dispatch("projects/create", projectData);
    },
  },
};
</script>
<style lang="scss">
$style: TheReport;
.#{$style} {
  padding: 42px 24px 24px;
  &__row {
    margin-bottom: 58px;
  }
}
.w-full {
  width: 100%;
}
</style>
