<template>
  <div class="text-center mt-6">
    <img src="@/assets/logo.png" class="logo mx-auto" />
    <h1 class="mt-4 title">Error 404</h1>
    <h3 class="mt-4 subtitle">Page not found</h3>
    <router-link
      to="/"
      class="d-block text-center mt-10"
    >
      Return to main page
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Page404"
}
</script>

<style scoped lang="scss">
.logo {
  height: 75px;
  filter: grayscale(1);
}

.title {
  color: black !important;
}
</style>