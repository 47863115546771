<template>
  <div class="navigation-sites" :class="{ _small: isMiniVariant }">
    <v-list-item
      v-for="site in sites"
      :key="site.id"
      :to="`/site/${site.id}`"
      class="navigation-sites__list-item"
      :class="{__plugin: isPlugin}"
      active-class="_active"
    >
      <span class="navigation-sites__dot" v-if="!isPlugin"/>
      <span class="" v-else>
        <img class="navigation-sites__plugin_img" v-if="site.image" :src="site.image" />
      </span>
      <v-list-item-content class="navigation-sites__nav-content">
        {{ site.url }}
      </v-list-item-content>
      <v-btn @click.stop="openSiteUrl(site)" class="ml-2" text icon x-small>
        <v-icon small>open_in_new</v-icon>
      </v-btn>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "NavigationSites",
  props: {
    sites: Array,
    isMiniVariant: {
      type: Boolean,
      default: false,
    },
    isPlugin: false
  },
  methods: {
    openSiteUrl(site) {
      window.open("http://" + site.url, "_blank");
    },
  },
};
</script>
<style lang="scss">
$style: navigation-sites;
.#{$style} {
  padding-left: 4px;
  &._small {
    padding-left: 0;
    .#{$style}__plugin_img {
      margin-left: 17px !important;
    }
  }
  & &__nav-icon {
    color: $N2;
  }
  .__plugin {
    padding-left: 5px;
  }
  &__plugin_img {
    width: 16px;
    height: 16px;
    margin-left: 7px;
    margin-right: 7px;
  }
  &__dot {
    display: flex;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $G1;
    margin-right: 15px;
  }
  &__nav-content {
    color: $N2;
    font-weight: 500;
    font-size: .8125rem;
    white-space: nowrap;
    letter-spacing: normal;
  }
  &__list-item {
    &._active {
      color: $white;
      .#{$style}__nav-icon  {
        color: $white;
      }
      .#{$style}__nav-content {
        color: $white;
      }
      &:before {
        content: none;
      }
    }
  }
}
</style>
