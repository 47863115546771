<template>
  <pre v-if="value" v-html="parsedValue"></pre>
</template>
<script>
  import { prettyJSON } from '@/utils/common';
  export default {
    name: "JSONPrint",
    props: ["value"],
    computed: {
      parsedValue() {
        return prettyJSON(this.value);
      }
    },
  };
</script>
