<template>
  <v-snackbar :color="color" v-model="snackbar" multi-line top vertical :timeout="5000">
    <span>{{ text }}</span>
    <v-btn text @click="snackbar = false">Close</v-btn>
  </v-snackbar>
</template>

<script>
  export default {
    name: "Snackbar",
    mounted() {
      this.$root.$on("showSnack", data => {
        this.text = data.message;
        this.snackbar = true;
        this.color = data.color;
      });
    },
    data() {
      return {
        text: "",
        snackbar: false,
        color: "primary"
      };
    }
  };
</script>

<style>
  .v-snack__content {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    padding: 20px !important;
  }

  .v-snack--vertical .v-snack__content .v-btn.v-btn {
    margin: 0 10px !important;
  }
</style>
