import {ProfileConfig} from '@/models/ProfileConfig';
import {ProfileConfig as ProfileConfigResource} from "@/resources/forge";

const store = {
  namespaced: true,
  state: {
    current: null
  },
  mutations: {
    /**
     * Sets the current profile configuration in the state.
     * @param {Object} config - The new profile configuration.
     */
    setConfig (state, config) {
      state.current = config;
    },
    /**
     * Updates the current profile configuration in the state.
     * @param {Object} updatedConfig - The updated profile configuration.
     */
    update(state, updatedConfig) {
      const config = state.current;
      if (!config)
        return;

      Object.assign(config, updatedConfig);
    }
  },
  actions: {
    /**
     * Loads the profile configuration from the server and sets it in the state.
     */
    async load({commit}) {
      const res = await ProfileConfigResource.get();
      commit('setConfig', new ProfileConfig(res.body));
      return res;
    },
    /**
     * Updates the profile configuration on the server and updates it in the state.
     * @param {Object} params - The updated profile configuration parameters.
     */
    async update({commit}, params) {
      const res = await ProfileConfigResource.update({id: params.id}, params)
      commit('update', new ProfileConfig(res.body))
    }
  }
};

export default store;
