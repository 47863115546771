export class siteApp {
  id = null;
  site_id = null;
  user_id = null;
  activated = false;
  all_app_id = null;
  restKey = null;
  jsKey = null;

  app = null;

  constructor(origin) {
    if (!origin)
      return;
    this.id = origin.id;
    this.site_id = origin.site_id;
    this.user_id = origin.user_id;
    this.restKey = origin.restkey;
    this.jsKey = origin.jskey;
    this.activated = origin.activated;
    this.all_app_id = origin.all_app_id;
    if (!origin.parse_server) {
      this.app = new AppChiselCMS(origin.chisel_config, this.activated, origin.percent_deployed);
    }
    else{
      this.app = new AppParseServer(origin.parse_config, this.activated, origin);
    }

  }

  getServerObject() {
    return {
      site_id: this.site_id,
      user_id: this.user_id,
      activated: this.activated,
      all_app_id: this.all_app_id,
    };
  }
}

export const APP_PARSE_SERVER = "1";
export const APP_CHISEL_CMS = "2";

export const APPS_DATA = [
  {
    id: APP_PARSE_SERVER,
    name: "Forge Ignite",
    image: require("@/components/TheSite/SiteApps/tempPics/parse-server.png"),
    serviceType: 'PaaS',
    description: "Run your own Parse Server with Forge Ignite. \n A supercharged, open-source backend solution for your sites and plugins."
  },
  {
    id: APP_CHISEL_CMS,
    name: "Chisel CMS",
    image: require("@/components/TheSite/SiteApps/tempPics/chisel-app.jpg"),
    serviceType: 'Content Management',
    description: "Chisel is an API-first headless CMS running on Parse Server. \n A powerful, free and open-source content management solution"
  }
];

export class AppParseServer {
  id = '';
  url = '';
  appId = '';
  masterKey = '';
  clientKey = '';
  chiselUrl = '';
  dashboardPassword = '';
  site_app_id = '';
  redeploy = false;
  deployed = false;
  deploying = false;
  serviceType = 'PaaS';
  installCount = 1500;
  https_port = '';
  port = '';
  github_branch = "";
  github_repository = "";
  appData = APPS_DATA.find(d => d.id == APP_PARSE_SERVER);
  environments = {};
  serviceHealth = 'loading';
  parse_logs_available = false;
  cloud_code_enabled = false;
  messages = [];
  parse_dashboard_url = "";
  parse_c66_ssh_key = "";
  parse_server_app_url = "";

  constructor(origin, status = false, site_app) {
    if (!origin)
      return;
    this.https_port = origin.https_port;
    this.port = origin.port;
    this.github_branch = origin.github_branch == null ? "" : origin.github_branch;
    this.github_repository = origin.github_repository == null ? "" : origin.github_repository;
    this.url = origin.url == undefined ? '' : origin.url;
    this.appId = origin.app_id;
    this.masterKey = origin.master_key;
    this.clientKey = origin.client_key;
    this.chiselUrl = origin.chisel_cms_url == undefined ? '' : origin.chisel_cms_url;
    this.dashboardPassword = origin.dashboard_pass == undefined ? '' : origin.dashboard_pass;
    this.activated = status;
    this.id = origin.id;
    this.parse_c66_ssh_key = site_app.parse_c66_ssh_key;
    this.environments = site_app.environments;
    if (this.environments == null)
      this.environments = [];
    this.site_app_id = origin.site_app_id;
    this.deployed = origin.deployed;
    this.redeploy = origin.redeploy;
    this.deploying = origin.deploying;
    this.parse_server_app_url = site_app.parse_server_app_url;
    this.parse_logs_available = origin.parse_logs_available;
    this.cloud_code_enabled = site_app.cloud_code_enabled;
    this.parse_dashboard_url = site_app.parse_dashboard_url;
    if (!origin.parse_logs_available) 
      this.serviceHealth = 'not deployed'
  }

  getServerObject() {
    return {
      app_id: this.appId,
      chisel_cms_url: this.chiselUrl,
      master_key: this.masterKey,
      client_key: this.clientKey,
      github_repository: this.github_repository,
      github_branch: this.github_branch,
      parse_dashboard_pass: this.dashboardPassword
    }
  }
}

export class AppChiselCMS {
  id = '';
  url = '';
  parse_app_id = '';
  restkey = '';
  jskey = '';
  chiselUrl = '';
  site_app_id = '';
  deployed = false;
  activated = false;
  parse_url = "";
  serviceType = 'headless CMS';
  installCount = 1500;
  current_version_id = 0;
  messages = [];
  percent_deployed = 1;

  appData = APPS_DATA.find(d => d.id == APP_CHISEL_CMS);


  constructor(origin, status = false, percent_deployed = 1) {
    if (!origin)
      return;
    this.id = origin.id;
    this.parse_url = origin.parse_url == null ? "" : origin.parse_url;
    this.url = origin.url == null ? "" : origin.url;
    this.parse_app_id = origin.parse_app_id;
    this.restkey = origin.restkey;
    this.jskey = origin.jskey;
    this.current_version_id = origin.current_version_id;
    this.activated = status;
    this.site_app_id = origin.site_app_id;
    this.deployed = origin.deployed;
    this.percent_deployed = percent_deployed;
    if (localStorage.getItem(`version_${this.current_version_id}`) == null)
      this.messages = []
    else
      this.messages = JSON.parse(localStorage.getItem(`version_${this.current_version_id}`));      
  }

  getServerObject() {
    return {
      id: this.id,
      url: this.url,
      parse_app_id: this.parse_app_id,
      restkey: this.restkey,
      site_app_id: this.site_app_id,
      jskey: this.jskey,
      parse_url: this.parse_url
    }
  }
}
