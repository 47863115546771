<template>
  <div class="ProfileInvoices">
    <div class="ProfileInvoices__head">
      <h2 class="ProfileInvoices__title" style="text-transform: capitalize;">
        Your invoices
      </h2>
      <span @click="downloadCsv" class="ProfileInvoices__csv">
        <img src="@/assets/csv.png" />
      </span>
    </div>
    <div v-if="loading" class="spinner">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="100"
        :color="'#496DDB'"
      />
    </div>
    <div
      class="ProfileInvoices__empty"
      v-else-if="!loading && invoices && invoices.length === 0"
    >
      <h3 class="rofileInvoices__title">There are no invoices yet</h3>
    </div>
    <div v-else>
      <v-card
        outlined
        dark
        class="mt-2 invoice "
        v-for="invoice in invoices"
        :key="invoice.id"
      >
        <v-list-item class="invoices-background pa-4" dark>
          <p>
            Invoice #<b>{{ invoice.id }}</b>
            <small class="paid ml-3" v-if="invoice.paid">Paid</small>
            <time right
              >From <b>{{ toTime(invoice.periodStart) }}</b> - To
              <b>{{ toTime(invoice.periodEnd) }}</b></time
            >
          </p>
          <ul class="lines mt-6">
            <li class="mt-1" v-for="line in invoice.lines.data" :key="line.id">
              <span v-if="line.description">{{ line.description }} </span>
              <span v-else>
                {{ line.type }}
                <span style="text-transform: capitalize;" v-if="line.plan">
                  - {{ line.plan.name }} Plan
                </span>
              </span>
              <span class="subtotal"> {{ toMoney(line.amount) }} </span>
            </li>
          </ul>
          <span class="total mt-1">Total: {{ toMoney(invoice.total) }}</span>
        </v-list-item>
      </v-card>
    </div>
  </div>
</template>

<script>
import { IntersectingCirclesSpinner } from "epic-spinners";
import { mapState } from "vuex";
import dayjs from "dayjs";
import { isNull } from "lodash";
var calendar = require('dayjs/plugin/calendar')
dayjs.extend(calendar)

export default {
  name: "ProfileInvoices",
  components: { IntersectingCirclesSpinner },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.fetchInvoices();
  },
  computed: {
    ...mapState({
      invoices: (state) => state.invoices.list,
    }),
  },
  methods: {
    isNull,
    toTime(time) {
      return dayjs(time).calendar();
    },
    toMoney(amount) {
      return "$" + (amount / 100).toFixed(2);
    },
    fetchInvoices() {
      this.$store
        .dispatch("invoices/load")
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    downloadCsv() {
      this.$store.dispatch("invoices/downloadCsv").then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Invoices.csv");
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$style: ProfileInvoices;
.#{$style} {
  padding: 42px 24px 24px;

  &__head {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    color: $white;
    font-weight: 500;
    font-size: $H20;
    margin-bottom: 20px;
  }
  &__csv {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: filter 0.2s ease;
    img {
      width: 100%;
      height: 100%;
    }
    &:hover {
      filter: brightness(60%);
    }
  }
  &__empty {
    background: $N4;
    border: 1px solid $N5;
    border-radius: 8px;
    padding: 32px 24px 40px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__empty-title {
    font-weight: 500;
    font-size: $H20;
    color: $white;
    margin-bottom: 24px;
  }
}
// .csv-download {
//   float: right;
//   display: inline-block;
//   width: 30px;
//   height: 30px;
//   margin: 0 8px;
//   cursor: pointer;
//   background: center center no-repeat;
//   background-size: contain;
// }
.subtotal {
  float: right;
  font-weight: 600;
}
.spinner {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.total {
  text-align: right;
  right: 0;
  display: block;
  font-weight: 600;
}
time {
  color: #666;
  position: relative;
  display: inline-block;
  float: right;
  font-style: italic;
}
.invoice {
  position: relative;
  display: block;
  width: 100%;
}
.paid {
  color: #fff;
  font-size: 12px;
  border-radius: 20px;
  background-color: $G1;
  padding: 2px 8px;
}
.invoices-background {
  display: block;
  background: $N4 !important;
  border-radius: 8px !important;
}
.small-text {
  opacity: 0.7;
  font-style: italic;
}
</style>
