import { getRelativeTime } from "@/utils/common";

export class OrganisationUser {
  id = null;
  user_id = null;
  email = null;
  name = null;
  last_active = null;
  organisation_id = null;
  role = null;
  invited = false;
  avatar = null;

  constructor(origin) {
    if (!origin)
      return;

    this.id  = origin.id;
    this.role = origin.role;
    this.email = origin.email;
    this.user_id = origin.user_id;
    this.avatar = origin.avatar_url;
    this.name = origin.name;
    if (origin.last_active != null)
      this.last_active = getRelativeTime(new Date(origin.last_active)).toLowerCase();
    this.organisation_id = origin.organisation_id;
  }

  getServerObject() {
    return {
      organisation_id: this.organisation_id,
      user_id: this.user_id
    };
  }
}
