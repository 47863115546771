<template>
  <div :class="{ SiteForms: emptyForms }">
    <div class="SiteForms__card" v-if="loading">
      <div class="spinner">
        <IntersectingCirclesSpinner
          :animation-duration="1200"
          :size="100"
          :color="'#496DDB'"
        />
      </div>
    </div>
    <div v-else-if="emptyForms" class="SiteForms__container">
      <div class="SiteForms__card" >
        <div>
          <h3 class="SiteForms__title">Forge Forms</h3>
          <div class="SiteForms__texts">
            <p>
              Add <span>data-forge-name</span> to your
              <span>&lt;form&gt;</span> and <span>&lt;input&gt;</span> elements...
            </p>
            <p>Upload to Forge...</p>
            <p>And we'll do the rest.</p>
          </div>
        </div>
        <a
          href="https://docs.getforge.com/docs/about-forms"
          target="_blank"
          class="SiteForms__link"
        >
          ?
        </a>
      </div>
      <div class="SiteForms__card">
        <div class="form-creation-container">
          <SiteFormCreation />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="narrow-container mx-4">
        <div class="members_table mt-6">
          <v-data-table
            :headers="headers"
            class="SiteForms__forms_table"
            :items="forms"
            @click:row="handleClick"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Forge Forms</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="showFormModal = true"><v-icon class="mr-2">mdi-sparkles</v-icon>Formsmith</v-btn>
              </v-toolbar>
            </template>

            <template v-slot:item.name="{ item }">
              {{ item.human_name }}
            </template>

            <template v-slot:item.submissions="{ item }">
              {{ item.form_datum_ids.length }}
            </template>

            <template v-slot:item.active="{ item }">
              <v-icon dark color="orange" v-if="item.active">
                mdi-checkbox-marked-circle
              </v-icon>

              <v-icon v-else color="red" dark>
                mdi-cancel
              </v-icon>
            </template>

            <template v-slot:item.notifications="{ item }">
              <v-icon dark color="orange" v-if="item.notifications">
                mdi-checkbox-marked-circle
              </v-icon>

              <v-icon v-else color="red" dark>
                mdi-cancel
              </v-icon>
            </template>

            <template v-slot:item.auto_response="{ item }">
              <v-icon dark color="orange" v-if="item.auto_response">
                mdi-checkbox-marked-circle
              </v-icon>

              <v-icon v-else color="red" dark>
                mdi-cancel
              </v-icon>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <v-dialog class="formsmith-modal" v-model="showFormModal" max-width="80%">
      <v-card class="formsmith-modal">
        <v-card-text>
          <SiteFormCreation />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showFormModal = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { IntersectingCirclesSpinner } from "epic-spinners";
import SiteFormCreation from "./SiteFormCreation";
export default {
  name: "SiteFormsList",
  components: { IntersectingCirclesSpinner, SiteFormCreation },
  data() {
    return {
      forms: [],
      loading: true,
      showFormModal: false,
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Submissions",
          value: "submissions",
        },
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Notifications",
          value: "notifications",
        },
        {
          text: "Autoresponse",
          value: "auto_response",
        },
      ],
    };
  },
  methods: {
    handleClick(row) {
      this.$router.push("settings/" + row.id);
    },
  },
  computed: {
    emptyForms() {
      return !this.loading && this.forms.length == 0;
    },
  },
  mounted() {
    this.$store.dispatch("form/load", this.activeSite.id).then((res) => {
      this.loading = false;
    });
  },
  watch: {
    "$store.state.form.list": {
      handler(newVal, oldVal) {
        this.forms = _.cloneDeep(newVal);
        this.loading = false;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.spinner {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-bottom: 15px;
  justify-content: center;
}
$style: SiteForms;
.#{$style} {
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 80%;
    width: 80%;
  }
  &__card {
    background: $N4;
    border: 1px solid $N5;
    border-radius: 8px;
    padding: 32px 24px 40px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media screen and (max-width: 600px) {
      margin: 0 24px;
    }
  }
  &__forms_table {
    background: #1f1e31 !important;
    min-width: 80%;
    border: 1px solid #2e2c42;
    header {
      background-color: #1f1e31 !important;
    }
  }
  &__title {
    font-weight: 500;
    font-size: $H20;
    color: $white;
    margin-bottom: 24px;
  }
  &__texts {
    text-align: center;
    p {
      font-size: $H14;
      font-weight: 300;
      span {
        font-weight: 500;
      }
    }
  }
  &__link {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: $N5;
    border-radius: 50%;
    color: $white !important;
    font-weight: 500;
    font-size: $H20;
    transition: background-color 0.2s ease;
    &:hover {
      background-color: $N3;
    }
  }
  .form-creation-container {
    margin-top: 20px;
    width: 100%;
  }
}
</style>
