<template>
  <v-container>
    <!-- SAML SSO Section -->
    <v-card class="section-card" flat>
      <v-card-title>SAML SSO</v-card-title>
      <v-card-text>
        <p>
          Enable Single Sign-On (SSO) for your organization using SAML. SSO
          allows your users to log in using their corporate credentials.
        </p>
        <a href="https://docs.getforge.com" target="_blank"
          >Learn more about SAML SSO in the docs &rarr;</a
        >
        <div class="config-area">
          <v-card class="nested-card" flat>
            <v-card-title>SSO Configuration</v-card-title>
            <v-card-text>
              <p>
                Configure your SAML SSO settings to integrate with your identity
                provider.
              </p>
            </v-card-text>
          </v-card>
          <div class="overlay">
            <div class="overlay-content">
              <p>Upgrade to Enterprise to enable SAML SSO.</p>
              <spark-button @click="upgradeToEnterprise"
                >Contact Sales</spark-button
              >
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <!-- 2FA Section -->
    <v-card class="section-card" flat>
      <v-card-title>Two-Factor Authentication (2FA)</v-card-title>
      <v-card-text>
        <p>
          Enhance your account security by enabling two-factor authentication
          (2FA). 2FA requires a second form of authentication in addition to
          your password.
        </p>
        <a href="https://docs.getforge.com" target="_blank"
          >Learn more about 2FA in the docs &rarr;</a
        >
        <div class="config-area">
          <v-card class="nested-card" flat>
            <v-card-title>2FA Configuration</v-card-title>
            <v-card-text>
              <p>Configure 2FA settings for your account.</p>
            </v-card-text>
          </v-card>
          <div class="overlay">
            <div class="overlay-content">
              <p>
                Upgrade to Enterprise to enable Two-Factor Authentication (2FA).
              </p>
              <spark-button @click="upgradeToEnterprise"
                >Contact Sales</spark-button
              >
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <!-- Deploy Approvals Section -->
    <v-card class="section-card" flat>
      <v-card-title>Deploy Approvals</v-card-title>
      <v-card-text>
        <p>
          Require deploy approvals for non-Organization members. This ensures
          that all deploys are reviewed and approved by an authorized member of
          your organization.
        </p>
        <a href="https://docs.getforge.com" target="_blank"
          >Learn more about Deploy Approvals in the docs &rarr;</a
        >
        <div class="config-area">
          <v-card class="nested-card" flat>
            <v-card-title>Approval Configuration</v-card-title>
            <v-card-text>
              <p>Configure deploy approval settings for your organization.</p>
            </v-card-text>
          </v-card>
          <div class="overlay">
            <div class="overlay-content">
              <p>Upgrade to Enterprise to enable Deploy Approvals.</p>
              <spark-button @click="upgradeToEnterprise"
                >Contact Sales</spark-button
              >
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SparkButton from "../_Common/SparkButton.vue";

export default {
  name: "IdentityAndAccess",
  components: {
    SparkButton,
  },
  methods: {
    contactSales() {
      console.log("Contact Sales clicked");
    },
  },
};
</script>

<style scoped>
.section-card {
  margin-bottom: 20px;
  background: #1c1b2e;
}
.nested-card {
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.config-area {
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1;
}
.overlay-content {
  text-align: center;
}
</style>
