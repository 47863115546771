<template>
  <v-menu v-model="menu" offset-y class="SiteModeSelect">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        v-bind="attrs"
        depressed
        v-on="on"
        class="SiteModeSelect__btn"
        :ripple="false"
      >
        <v-icon left small color="white">
          {{ siteModeData.icon }}
        </v-icon>
        <span :style="{ color: siteModeData.color }">{{
          siteModeData.name
        }}</span>
        <v-icon
          right
          dark
          color="n3"
          class="SiteModeSelect__chevron"
          :class="{ _active: menu }"
        >
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </template>
    <v-list dense color="#44464F" dark class="SiteModeSelect__list">
      <v-list-item
        v-for="mode in SITE_MODES"
        :key="mode.value"
        @click="$emit('input', mode.value)"
      >
        <v-list-item-icon class="mr-2">
          <v-icon :color="mode.color" small>{{ mode.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title :style="{ color: mode.color }">{{
          mode.name
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  SITE_MODE_PRODUCTION,
  SITE_MODE_DEVELOPMENT,
  SITE_MODE_STAGING,
} from "@/models/Site";

const SITE_MODES = [
  {
    name: "Production",
    icon: "mdi-factory",
    value: SITE_MODE_PRODUCTION,
  },
  {
    name: "Development",
    icon: "mdi-hammer-screwdriver",
    value: SITE_MODE_DEVELOPMENT,
  },
  {
    name: "Staging",
    icon: "mdi-crane",
    value: SITE_MODE_STAGING,
  },
];

export default {
  name: "SiteModeSelect",
  props: {
    value: String,
  },
  data() {
    return {
      SITE_MODES,
      menu: false,
    };
  },
  computed: {
    siteModeData() {
      return SITE_MODES.find((m) => m.value == this.value);
    },
  },
};
</script>

<style lang="scss">
$style: SiteModeSelect;
.#{$style} {
  &__btn {
    &.v-btn {
      border: 1px solid $N5;
      background: $N7 !important;
    }
     &:focus:before {
      background: $N7;
    }
  }
  &__chevron {
    transition: transform 0.2s ease;
    &._active {
      transform: rotate(180deg);
    }
  }
  &__list {
    background-color: $N7 !important;
    border: 1px solid $N7 !important;
    border-radius: 8px !important;
    margin-top: 10px;
  }
}
</style>
