export class Invoice {
  id = null;
  date = "";
  periodStart = "";
  periodEnd = "";
  lines = null;
  subtotal = null;
  total = null;
  paid = false;

  constructor(origin) {
    if (!origin)
      return;
    this.id  = origin.id;
    this.date  = new Date(origin.date);
    this.periodStart = new Date(origin.period_start);
    this.periodEnd = new Date(origin.period_end);
    this.lines = origin.lines;
    this.subtotal = origin.subtotal;
    this.total = origin.total;
    this.paid  = origin.paid;

    // Object.assign(this.roles, origin.roles);
  }

  getServerObject() {
    return {
      date: this.date,
      period_start: this.periodStart,
      period_end: this.periodEnd,
      lines: this.lines,
      subtotal: this.subtotal,
      total: this.total,
      paid: this.paid
    }
  }
}
