<template>
  <div class="PublisherPluginStatus">
    <div class="PublisherPluginStatus__chart">
      <canvas id="plugin-status"></canvas>
      <div class="PublisherPluginStatus__total-label">{{ total }}</div>
    </div>
    <div class="PublisherPluginStatus__legend">
      <div v-for="legendRow in legendsData" :key="legendRow.slug" class="PublisherPluginStatus__legend-row">
        <div class="PublisherPluginStatus__legend-color" :style="{ background: legendRow.color }"></div>
        <div>
          <div class="PublisherPluginStatus__legend-label">{{ legendRow.label }}</div>
          <div class="PublisherPluginStatus__legend-value">{{ legendRow.value }} plugins</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js'

const legends = [
  { label: 'Ready for Sale', color: 'rgb(87, 76, 250)', slug: 'ready-for-sale' },
  { label: 'In Review', color: 'rgba(87, 76, 250, 0.5)', slug: 'in-review' },
  { label: 'Draft', color: 'rgba(87, 76, 250, 0.2)', slug: 'draft' }
];
export default {
  name: 'PublisherPluginStatus',
  props: {
    chartData: Array
  },
  data() {
    return {
      data: {
        labels: [
          'Ready for Sale',
          'In Preview',
          'Draft'
        ],
        datasets: [{
          data: [300, 50, 100],
          backgroundColor: [
            'rgb(87, 76, 250)',
            'rgba(87, 76, 250, 0.5)',
            'rgba(87, 76, 250, 0.2)'
          ],
          borderColor: '#1C1B2E',
          borderWidth: 4,
          borderAlign: 'inner',
          spacing: 10,
          hoverOffset: 4
        }]
      },
      options: {
        cutoutPercentage: 80,
        legend: false
      },
      legendsData: legends,
      total: 0
    }
  },
  async mounted() {
    this.legendsData = legends.map(legend => {
      const record = this.chartData.find(row => row.slug === legend.slug);
      if (record && record.value) return {...legend, value: record.value}; else return {...legend, value: 0};
    });
    this.data.datasets[0].data = this.chartData.map(row => row.value);
    this.total = this.chartData.reduce((acc, cur) => acc + cur.value, 0);
    const ctx = document.getElementById('plugin-status');
    new Chart(ctx, {
      type: 'doughnut',
      data: this.data,
      options: this.options
    });
  }
}
</script>


<style lang="scss">
$style: PublisherPluginStatus;
.#{$style} {
  display: flex;
  flex-direction: row;
  align-items: center;
  &__chart {
    position: relative;
  }
  &__total-label {
    position: absolute;
    font-size: 28px;
    font-weight: bold;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  &__legend-row {
    display: flex;  
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 15px;
  }
  &__legend-color {
    width: 30px;
    height: 4px;
    border-radius: 10px;
    margin-top: 9px;
    margin-right: 15px;
  }
  &__legend-label {
    color: #585B6A;
    margin-bottom: 4px;
    font-weight: 500;
  }
  &__legend-value {
    color: white;
    font-weight: 600;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    &__chart {
      margin-bottom: 12px;
    }
    &__legend-row {
      margin-bottom: 4px;
    }
  }
}
.w-full {
  width: 100%;
}
</style>
