<template>
  <v-list class="the-navigation-publisher__list pt-10" dark dense>
    <v-list-item
      link
      to="/publisher/dashboard"
      class="the-navigation-publisher__list-item"
      active-class="_active"
    >
      <v-list-item-icon :class="{ 'mr-5': !isNavDrawerMiniVariant }">
        <the-icon icon="home" class="the-navigation-publisher__nav-icon" />
      </v-list-item-icon>

      <v-list-item-content class="the-navigation-publisher__nav-content">
        Dashboard
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      link
      :to="{ name: 'plugin_list' }"
      class="the-navigation-publisher__list-item"
      :class="{'_active': isActiveRoute('plugin_list') || isActiveRoute('plugin_detail')}"
      exact
    >
      <v-list-item-icon :class="{ 'mr-5': !isNavDrawerMiniVariant }">
        <the-icon icon="plugins" class="the-navigation-publisher__nav-icon" />
      </v-list-item-icon>

      <v-list-item-content class="the-navigation-publisher__nav-content">
        Manage Plugins
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      link
      :to="{ name: 'developer_list' }"
      class="the-navigation-publisher__list-item"
      :class="{'_active': isActiveRoute('developer_list') || isActiveRoute('developer_detail')}"
      active-class="_active"
    >
      <v-list-item-icon :class="{ 'mr-5': !isNavDrawerMiniVariant }">
        <the-icon icon="developers" class="the-navigation-publisher__nav-icon" />
      </v-list-item-icon>

      <v-list-item-content class="the-navigation-publisher__nav-content">
        Developers
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      link
      to="/publisher/reports"
      class="the-navigation-publisher__list-item"
      active-class="_active"
    >
      <v-list-item-icon :class="{ 'mr-5': !isNavDrawerMiniVariant }">
        <the-icon icon="report" class="the-navigation-publisher__nav-icon" />
      </v-list-item-icon>

      <v-list-item-content class="the-navigation-publisher__nav-content">
        Reports
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      link
      to="/publisher/policies"
      class="the-navigation-publisher__list-item"
      active-class="_active"
    >
      <v-list-item-icon :class="{ 'mr-5': !isNavDrawerMiniVariant }">
        <the-icon icon="policy" class="the-navigation-publisher__nav-icon" />
      </v-list-item-icon>

      <v-list-item-content class="the-navigation-publisher__nav-content">
        Policies
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      link
      to="/publisher/reviews"
      class="the-navigation-publisher__list-item"
      active-class="_active"
    >
      <v-list-item-icon :class="{ 'mr-5': !isNavDrawerMiniVariant }">
        <the-icon icon="review" class="the-navigation-publisher__nav-icon" />
      </v-list-item-icon>

      <v-list-item-content class="the-navigation-publisher__nav-content">
        Reviews
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      link
      to="/publisher/sdk"
      class="the-navigation-publisher__list-item"
      active-class="_active"
    >
      <v-list-item-icon :class="{ 'mr-5': !isNavDrawerMiniVariant }">
        <the-icon icon="SDK" class="the-navigation-publisher__nav-icon" />
      </v-list-item-icon>

      <v-list-item-content class="the-navigation-publisher__nav-content">
        SDK
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      link
      to="/publisher/settings"
      class="the-navigation-publisher__list-item hide"
      active-class="_active"
      v-if="false"
    >
      <v-list-item-icon :class="{ 'mr-5': !isNavDrawerMiniVariant }">
        <the-icon icon="settings" class="the-navigation-publisher__nav-icon" />
      </v-list-item-icon>

      <v-list-item-content class="the-navigation-publisher__nav-content">
        Settings
      </v-list-item-content>
    </v-list-item>

  </v-list>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "NavigationPublisherList",
  computed: {
    ...mapState({
      isNavDrawerMiniVariant: ({ application }) =>
        application.isNavDrawerMiniVariant,
    }),
  },
  methods: {
    isActiveRoute(routeName) {
      return this.$route.name === routeName;
    }
  }
};
</script>
<style lang="scss">
$style: the-navigation-publisher;

.#{$style} {
  padding: 82px 24px 191px;
  &__list {
    padding: 0;
  }
  &__list-item {
    margin-bottom: 20px;
  }
  &__nav-icon {
    fill: $N2;
    color: $N2;
  }
  &__nav-content {
    color: $N2;
    font-weight: 500;
    font-size: $H14;
    white-space: nowrap;
  }
  &__list-item {
    &._active {
      color: $white;
      .#{$style}__nav-icon {
        fill: $B1;
      }
      .#{$style}__nav-content {
        color: $B1;
      }
      &:before {
        content: none;
      }
    }
  }
}
</style>
