<template>
  <div class="PluginInstallParamsBuilder">
    <PluginInstallParamsPreview v-if="isInPreview" :categories="categories" />
    <div v-else>
      <PluginInstallParamsMainView v-if="!editingElement" />
      <PluginInstallParamsElementDetail v-else />
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import PluginInstallParamsMainView from "./Views/MainView";
  import PluginInstallParamsElementDetail from "./Views/ElementDetail";
  import PluginInstallParamsPreview from "./Views/Preview";

  export default {
    name: "PluginInstallParamsBuilder",
    components: {
      PluginInstallParamsMainView,
      PluginInstallParamsElementDetail,
      PluginInstallParamsPreview
    },
    props: {
      categories: Array
    },
    data() {
      return {
        drawer: false
      };
    },

    computed: {
      ...mapState({
        editingElement: state => state.formBuilder.editingElement,
        isInPreview: state => state.formBuilder.isInPreview,
      })
    }
  };
</script>
<style scoped lang="scss">
  $style: PluginInstallParamsBuilder;
  .#{$style} {
    height: 100%;
    > div {
      height: 100%;
    }
  }
</style>
<style lang="scss">
.vue-form-generator .field-switch .field-wrap label {
  width: 80px;
}
.vue-form-generator .field-switch input:checked~.label {
  background: $Y1;
}
</style>