<template>
  <v-menu
    :close-on-content-click="true"
    class="mx-4"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <span :class="`status-chip ${chipClassName}`" v-bind="attrs" v-if="status" v-on="on">
        {{status}}
      </span>

    </template>

    <v-card max-height="250">
      <v-list>
        <v-list-item v-for="statusOption in statusOptions" :key="statusOption.class" @click="onSelectStatusOption(statusOption)" 
          :class="`status-filter-option ${statusOption.class} ${ status === statusOption.value ? 'active' : '' }`">
          {{ statusOption.value }}
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>


<script>
export default {
  name: "StatusChip",
  props: {
    status: String,
    dataId: String
  },
  data() {
    return {
      statusOptions: [
      { class: 'sale-ready', value: 'Ready for Sale' },
      { class: 'in-review', value: 'In Review' },
      { class: 'rejected', value: 'Rejected' },
      { class: 'other-status', value: 'Prepare for Upload' },
      { class: 'other-status', value: 'Waiting for Upload' },
      { class: 'other-status', value: 'Upload Received' },
      { class: 'other-status', value: 'Waiting for Review' },
      { class: 'other-status', value: 'Pending Contract' },
      { class: 'other-status', value: 'Waiting for Compliance' },
      { class: 'other-status', value: 'Pending Developer Release' },
      { class: 'other-status', value: 'Pending Release' },
      { class: 'other-status', value: 'Processing for Store' },
      { class: 'other-status', value: 'Removed from Sale' },
      { class: 'other-status', value: 'Missing Content' },
      { class: 'other-status', value: 'Developer Rejected' },
      { class: 'other-status', value: 'Developer Removed from Sale' },
      { class: 'other-status', value: 'Security Audit Issue' },
      { class: 'other-status', value: 'Lab Ready' }
      ]
    }
  },
  computed: {
    chipClassName() {
      const status = this.status ? this.status.toLowerCase() : '';
      if (status == 'ready for sale') return 'sale-ready'
      if (status == 'in review') return 'in-review'
      if (status == 'rejected') return 'rejected'
      return 'other-status';
    },
  },
  methods: {
    onSelectStatusOption(statusOption) {
      this.$emit('updateStatus', { status: statusOption.value, dataId: this.dataId });
    } 
  }
}
</script>

<style lang="scss" scoped>
  .status-chip {
    position: relative;
    padding: 6px 15px;
    width: 160px;
    display: block;
    text-align: center;
    border-radius: 16px;
    background: $N2;
    color: $N4;
    font-size: 13px;
    margin: 0 auto;
    &:before {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0.1;
      display: block;
      border-radius: 999px;
      content: '';
    }
  }
  .sale-ready {
    color: $G1 !important;
    background: $G1_5;
  }
  .in-review {
    color: $Y1 !important;
    background: $Y1_5;
  }
  .rejected {
    color: $R2 !important;
    background: $R1_5;
  }
  .other-status {
    color: $N1 !important;
  }
</style>