<template>
  <div class="d-flex align-center">
    <el-select
      v-model="value"
      placeholder="Select an option"
      :multiple="schema.multiple"
      class="field-select"
    >
      <el-option
        v-for="option in schema.options"
        :key="option.value"
        :label="option.text"
        :value="option.value"
      ></el-option>
    </el-select>
    <a class="ml-2 reset" @click.prevent="resetValue">{{ schema.resetText || 'Reset' }}</a>
  </div>
</template>

<script>
import VueFormGenerator from "vue-form-generator";

export default {
  name: "FieldSelectReset",
  mixins: [VueFormGenerator.abstractField],
  props: [
    "model",
    "schema"
  ],
  methods: {
    resetValue() {
      this.value = null;
    },
  }
};
</script>

<style scoped lang="scss">
.field-select {
  flex-grow: 1;
  .el-input__inner {
    border-color: $N3;
  }
}
a.reset {
  color: $Y1;
}
</style>


<style lang="scss">
.field-select {
  flex-grow: 1;
  .el-input__inner {
    border-color: $N3;
    color: white;
  }
  &.el-select .el-tag {
    background: $B4;
    border-color: $B1;
    color: $B1;
    .el-tag__close {
      background: $B1;
      color: $N7;
    }
  }
}
.el-select-dropdown {
  background: $N5;
  .el-select-dropdown__item, &.is-multiple .el-select-dropdown__item {
    font-family: $body-font-family;
    background: $N5;
    color: $N1;
    &:hover {
      color: $B1;
    }
    &.selected {
      background: $N5;
      color: $B1;
      &:hover {
        background: $N5;
      }
      &.hover {
        color: $Y1;
      }
    }
  }
}
</style>