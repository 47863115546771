<template>
  <div class="the-app-bar__notify">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      offset-y
      internal-activator
      :left="true"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          text
          plain
        >
          <v-icon color="n2" :class="{Y1: menu}">mdi-bell-outline</v-icon>
          <span class="the-app-bar__notify-badge" v-if='has_unread_notifications'/>
        </v-btn>
      </template>

      <v-card class="Notifications__card" min-width="384">
        <div class="Notifications__card_header"> <span class="header_text"> Notifications </span>
          <v-icon class="settings_icon"> mdi-cog-outline </v-icon>
        </div>
        <v-list class="Notifications__list" id="Notifications__list" v-if='notifications.length > 0'>
          <v-list-item
            v-for="notification in notifications" :key="notification.id" @click="moveToObject(notification)"
          >
            <div class="d-inline-block Notifications__avatar_image">
                <img v-if="!avatarEmpty(notification)" :src='notification.createdAvatarUrl' alt=""  class="the-app-bar__image-wrapper" />
                <avatar
                  v-else
                  :rounded="true"
                  :initials="initials(notification)"
                  username="name"
                  :size="26"
               />
              </div> 
              <div class="d-inline-block Notifications__text"> 
                <p  v-html="notification.header"> </p>
                <div class="Notifications__timestamps"> 
                  {{notification.createdAt}} 
                  <span class="Notifications__version_number" v-if="['comments','mentions'].includes(notification.notificationType)">
                    <div class="version_dot"></div>
                    <span> Version #{{notification.linkInfo['scoped_id']}} </span>
                  </span>
                </div>

                <div v-if="['comments','mentions'].includes(notification.notificationType)" class="Notifications__html_message">
                  <div v-html="notification.htmlText" ></div>
                </div>
              </div>
              <div class="Notifications__unread_dot" v-if="notification.viewed == false"/>
              <div class="viewed_button" @click.stop="changeViewedState(notification)">
                <v-icon v-if="notification.viewed == false">mdi-checkbox-marked-circle-outline </v-icon>
                <v-icon v-else>mdi-check-circle</v-icon>
              </div>
          </v-list-item>
        </v-list>
      </v-card>
      <div class="Notifications__footer">
        <v-btn text color="#ff7e21" @click.stop="setAllToView"> Mark all as read </v-btn>
        <v-btn class="y1" style="float: right;" @click="openAll()"> View All Notifications </v-btn>
      </div>
   </v-menu>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import Avatar from 'vue-avatar';
  import _ from 'lodash'
  export default {
    name: "Notifications",
    components: { Avatar },
    props: {
      openAllNotificationsModal: Function
    },
    data() {
      return {
        showNotificationsBlock: false,
        timer: null,
        defaultScroll: false,
        menu: false
      }
    },
    computed: {
      ...mapState({
        notifications: (state) => _.cloneDeep(state.notifications.list)
      }),
      messages() {
        return this.$store.state.notifications.messages;
      },
      has_unread_notifications() {
        return this.notifications.filter(n => n.viewed == false).length > 0
      },
    },
    methods: {
      showNotifications() {
        if (this.timer != null){
          clearTimeout(this.timer)
          this.timer = null;
        }
        this.showNotificationsBlock = true;
        this.timer = setTimeout(this.hideNotifications, 3000);
      },
      hideNotifications() {
        this.$store.commit("notifications/removeAllMessages");
        this.showNotificationsBlock = false;
      },
      avatarEmpty(notification) {
        return notification.createdAvatarUrl == null || notification.createdAvatarUrl.length == 0;
      },
      initials(notification) {
        return notification.createdByName[0].toUpperCase();
      },
      changeViewedState(notification) {
        this.$store.dispatch('notifications/updateViewState', notification.id)
      },
      setAllToView() {
        this.$store.dispatch('notifications/setAllToView')
      },
      moveToObject(notification) {
        if (['new_version', 'version_deletion', 'version_rollback', 'site_in_project'].includes(notification.notificationType)) {
          const pathToObject = `/site/${notification.linkInfo['site_id']}/versions`;
          if (this.$route.path == pathToObject || notification.notificationType == 'site_in_project')
            document.location = pathToObject
          else{           
            this.$router.push(pathToObject);
            this.menu = false;
          }
        }
        else if (['comments', 'mentions'].includes(notification.notificationType) )
        {
          let pathToObject = `/site/${notification.linkInfo['site_id']}/versions?versionId=${notification.linkInfo['version_id']}`;
          pathToObject += `&commentId=${notification.linkInfo['comment_id']}`
          document.location = pathToObject;
        }
        else if (notification.notificationType == 'invitation')
          document.location = `/${notification.linkInfo['object_type'].toLowerCase()}/${notification.linkInfo['object_id']}/access`
      },
      openAll() {
        this.menu = false;
        this.openAllNotificationsModal();
      } 
    },
    watch: {
      "$store.state.notifications.messages": {
        handler (newVal, oldVal) {
          this.showNotifications();
        },
        deep: true
      },
      'menu': {
        handler(newVal, oldVal) {
          if (!newVal)
            this.defaultScroll = false;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  $style: 'Notifications';
  ::v-deep .mention {
    color: $Y1
  }

  ::v-deep .#{$style}__html_message p {
    margin-bottom: 0px;
  }

  ::v-deep .#{$style}__text p {
    margin-bottom: 0px;
  }

  .#{$style} {

    &__card {
      max-width: 384px;
      max-height: 432px;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      box-shadow: none !important;
    }

    &__avatar_image {
      top: 20px;
      position: absolute;
    }

    &__version_number {
      .version_dot {
        display: inline-block;
        width: 5px;
        height: 5px;
        background: #585B6A;
        vertical-align: middle;
        margin-left: 3px;
        border-radius: 50%;
        margin-right: 3px;
      }
    }

    &__html_message {
      margin-top: 10px;
      border-radius: 6px;
      background: $N7;
      padding: 8px;
      max-height: 50px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__unread_dot {
      width: 10px;
      height: 10px;
      background: red;
      border-radius: 50%;
      position: absolute;
      right: 15px;
      top: 21px;
    }

    &__text {
      margin-left: 35px;
      margin-right: 20px;
    }

    &__card_header {
      border-bottom: 1px solid $N7;
      background: $N5;

      padding-left: 20px;
      padding-top: 16px;
      padding-bottom: 16px;

      .settings_icon {
        vertical-align: bottom;
        font-size: 20px;
        color: $N2;
      }

      .header_text {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }

    &__footer {
      height: 64px;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 17px;
      padding-bottom: 15px;
      border-radius: 0px 0px 8px 8px;
      border-top: 1px solid $N7;
      background: $N5;

      .v-btn {
        font-size: 12px;
        height: 32px;
      }

    }

    &__list {
      background: $N5 !important;
      max-height: 316px;
      overflow: scroll;
      overscroll-behavior: contain;
      overflow-y: auto;

      .viewed_button {
        display: none;
        &:hover {
          cursor: pointer;
          i {
            color: $Y1 !important;
          }
        }
      }

      .v-list-item {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 16px;
        padding-bottom: 16px;

        &:hover {
          background: $N7 !important;
          .#{$style}__unread_dot {
            display: none !important;
          }

          .viewed_button {
            display: block;
            position: absolute;
            top: 16px;
            right: 10px;
          }
        }

        &:not(:first-child) {
          border-top: 1px solid $N7;
        }
      }
    }
  }
  .avatar-placeholder-wrapper {
    height: 26px;
    width: 26px;
    border-radius: 100%;
    border: 1px solid #B3B3B3;
    overflow: hidden;

    .avatar-placeholder {
      height: 24px;
    }
  }
</style>
