<template>
  <div class="mx-2 screen-cover pt-8">
    <div v-if="policyDetail" class="PolicyDetail mx-6">
      <div class="mb-6">
        <router-link class="breadcrumb-link" to="/publisher/policies">
          Policies
        </router-link>
        <span class="breadcrumb-separator">/</span>
        <span class="breadcrumb-link" v-if="policyDetail">{{ policyDetail.name }}</span>
      </div>
      <div class="d-flex flex-column flex-sm-row gap-3 align-sm-center w-full section-block mb-6 justify-space-between">
        <div class="d-flex align-center">
          <div class="PolicyDetail__icon-button pa-2 mr-4">
            <the-icon icon="document" class="PolicyDetail__icon" />
          </div>
          <div>
            <h3>{{ policyDetail.name }}</h3>
            <p class="mt-2 mb-0">{{ formatDate(policyDetail.updatedAt) }}</p>
          </div>
        </div>
      
        <v-btn
          class="v-btn exportBtn"
          @click="onExport"
          elevation="2"
          large
        >
          <v-icon dark left> mdi-export-variant </v-icon>
          Export
        </v-btn>
      </div> <!-- End of policy Head section -->

      <v-row>
        <v-col cols="12" sm="6">
          <div class="section-block">
            <h6 class="PolicyDetail__section-heading">Policy Information</h6>
            <div class="PolicyDetail__info-row">
              <span class="PolicyDetail__label">Eval Safe Pass Max</span>
              <div class="PolicyDetail__info-value">{{ policyDetail.EvalSafe_Pass_Max || '' }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <span class="PolicyDetail__label">Eval Safe Pass Min</span>
              <div class="PolicyDetail__info-value">{{ policyDetail.EvalSafe_Pass_Min || '' }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <span class="PolicyDetail__label">Eval Safe Warning Max</span>
              <div class="PolicyDetail__info-value">{{ policyDetail.EvalSafe_Warning_Max || '' }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <span class="PolicyDetail__label">Eval Safe Warning Min</span>
              <div class="PolicyDetail__info-value">{{ policyDetail.EvalSafe_Warning_Min || '' }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <span class="PolicyDetail__label">Eval Safe Fail Max</span>
              <div class="PolicyDetail__info-value">{{ policyDetail.EvalSafe_Fail_Max || '' }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <span class="PolicyDetail__label">Eval Safe Fail Min</span>
              <div class="PolicyDetail__info-value">{{ policyDetail.EvalSafe_Fail_Min || '' }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <span class="PolicyDetail__label">Require SSL</span>
              <div class="PolicyDetail__info-value">{{ getBooleanPropertyLabel(policyDetail.RequireSSL) }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <span class="PolicyDetail__label">Require Force SSL</span>
              <div class="PolicyDetail__info-value">{{ getBooleanPropertyLabel(policyDetail.RequireForceSSL) }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <span class="PolicyDetail__label">Allow External Network Requests</span>
              <div class="PolicyDetail__info-value">{{ getBooleanPropertyLabel(policyDetail.AllowExternalNetworkRequest) }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <div class="PolicyDetail__label">External Request <br /> Allow List</div>
              <div class="PolicyDetail__info-value">
                <div v-if="policyDetail.ExternalRequestAllowList" v-for="url in policyDetail.ExternalRequestAllowList" class="d-flex align-center mb-1">
                  <span class="PolicyDetail__audit-green audit-chip mr-2"></span>
                  {{ url }}
                </div>
              </div>
            </div>
            <div class="PolicyDetail__info-row">
              <div class="PolicyDetail__label">External Request <br />Block List</div>
              <div class="PolicyDetail__info-value">
                {{policyDetail.ExternalRequestsBlockList}}
                <div v-if="policyDetail.ExternalRequestsBlockList" v-for="url in policyDetail.ExternalRequestsBlockList" class="d-flex align-center mb-1">
                  <span class="PolicyDetail__audit-red audit-chip mr-1"></span>
                  {{ url }}
                </div>
              </div>
            </div>
            <div class="PolicyDetail__info-row">
              <div class="PolicyDetail__label">Allow Insecure <br />Network URLs</div>
              <div class="PolicyDetail__info-value">{{ getBooleanPropertyLabel(policyDetail.AllowInsecureNetworkURLs) }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <div class="PolicyDetail__label">Bandwidth Day Usage Limit</div>
              <div class="PolicyDetail__info-value">{{ policyDetail.Bandwidth_Day_Usage_Limit || '' }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <div class="PolicyDetail__label">Bandwidth Week Usage Limit</div>
              <div class="PolicyDetail__info-value">{{ policyDetail.BandWidth_Week_Usage_Limit || '' }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <div class="PolicyDetail__label">Forms Allowed</div>
              <div class="PolicyDetail__info-value">{{ getBooleanPropertyLabel(policyDetail.Forms_Allowed) }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <div class="PolicyDetail__label">Forms Limit</div>
              <div class="PolicyDetail__info-value">{{ policyDetail.Forms_Limit || '' }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <div class="PolicyDetail__label">Allow Collaborators</div>
              <div class="PolicyDetail__info-value">{{ getBooleanPropertyLabel(policyDetail.Allow_Collaborators) }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <div class="PolicyDetail__label">Collaborator Limit</div>
              <div class="PolicyDetail__info-value">{{ policyDetail.Collaborator_Limit || '' }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <div class="PolicyDetail__label">Media Microphone Allowed</div>
              <div class="PolicyDetail__info-value">{{ getBooleanPropertyLabel(policyDetail.Media_Microphone_Allowed) }}</div>
            </div>
            <div class="PolicyDetail__info-row">
              <div class="PolicyDetail__label">Media Camera Allowed</div>
              <div class="PolicyDetail__info-value">{{ getBooleanPropertyLabel(policyDetail.Media_Camera_Allowed) }}</div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="section-block">
            <h6 class="PolicyDetail__section-heading">Plugins Using Policy</h6>
            <div>
              <v-data-table 
                :headers="headers"
                :items="pluginsList"
                class="plugins-list-table"
                hide-default-footer
                :sort_by="['name']"
              >
                <template v-slot:item.name="{item}">
                  <div class="plugin-link" @click="onNavigateToPlugin(item)">{{ item.name }}</div>
                </template>
                <template v-slot:item.audit="{item}">
                  <div v-for="index in 4" :key="index" class="audit-chip" :class="getAuditClass()" />
                </template>
              </v-data-table>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="loading" class="spinner">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="100"
        :color="'#496DDB'"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import { IntersectingCirclesSpinner } from "epic-spinners";
import {MODAL_ALERT} from "@/components/Modals";

const Parse = require('parse');
export default {
  name: "PolicyDetail",
  components: {
    IntersectingCirclesSpinner
  },
  data() {
    return {
      policyDetail: null,
      loading: false,
      pluginsList: [],
      columns: ['name', 'url', 'audit'],
      columnOptions: [
        { text: 'NAME', value: 'name' },
        { text: 'URL', value: 'url' },
        { text: 'Audit', value: 'audit' }
      ]
    }
  },
  computed: {
    ...mapState({
      currentPublisherInfo: ({ publisher_infos }) => publisher_infos.current
    }),
    isPublisherAdmin() {
      return (this.currentPublisherInfo.role === 'admin');
    },
    headers() { // Plugin list table header to be calculated selected columns filter
      const { columns, columnOptions } = this;
      const options = columnOptions.reduce((acc, cur) => cur.children ? [...acc, ...cur.children] : [...acc, cur], [])
      return columns.map(column =>options.find(option => option.value === column));
    },
  },
  async mounted() {
    Parse.initialize(this.currentPublisherInfo.parse_server_app_id);
    Parse.serverURL = this.currentPublisherInfo.parse_server_url;
    this.loading = true;
    await this.getPolicyDetail();
    await this.getPluginsListByPolicy();
    this.loading = false;
  },
  methods: {

    // get app detail, called on page load
    // - get app detail common fields
    //  -- images
    //  -- color(Status color, eval safe color)
    //  -- Check if the current loggedin user is the owner  
    async getPolicyDetail() {
      const res = await Parse.Cloud.run('getPoliciesList', { parseServerSiteId: this.currentPublisherInfo.parse_server_site_id });
      if (res.policiesList && res.policiesList.length > 0) {
        this.policyDetail = res.policiesList.find((policy) => policy.id === this.$route.params.policyId);
      }
    },

    getBooleanPropertyLabel(value) {
      if (value === null || value === undefined || value === '') return '';
      return value ? 'Yes' : 'No'
    },

    onExport() {

    },
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY hh:mm');
    },
    async getPluginsListByPolicy() {
      const res = await Parse.Cloud.run('getPluginsList', { 
        parseServerSiteId: this.currentPublisherInfo.parse_server_site_id,
        filter: {
          policy: this.$route.params.policyId
        }
      });
      this.pluginsList = res.apps;
    },

    getAuditClass() {
      const audit = Math.floor(Math.random() * 4);
      switch (audit) {
        case 0: 
          return 'PolicyDetail__audit-green';
        case 1:
          return 'PolicyDetail__audit-red';
        case 2:
          return 'PolicyDetail__audit-blue';
        case 3:
          return 'PolicyDetail__audit-orange';
      }
    },

    onNavigateToPlugin(value) {
      this.$router.push({ name: "plugin_detail", params: { pluginId: value.slug } });
    },
  },
}
</script>

<style lang="scss" scoped>
  .screen-cover {
    position: relative;
    min-height: 100vh;
  }
  .section-block {
    border: 1px solid $N5;
    background: $N4;
    border-radius: 4px;
    padding: 24px;
  }
  $style: PolicyDetail;
  .#{$style} {
    &__icon-button {
      background: $N5;
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
    }
    &__icon {
      color: $B1;
      fill: $B1;
    }
    h3 {
      font-size: 20px;
      line-height: 24px;
    }
    p {
      font-size: 14px;
      line-height: 22px;
    }
    .v-btn {
      display: block;
      margin-left: 10px;
    }
    .exportBtn {
      color: $B1;
      background: $N5;
    }
    &__label {
      width: 200px;
      flex-shrink: 0;
      color: $N28;
      font-size: 14px;
    }
    &__section-heading {
      color: white;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 24px;
      font-weight: normal;
    }
    &__info-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    &__info-value {
      
    }

    &__audit-green {
      background: $G1;
    }
    &__audit-red {
      background: $R1;
    }
    &__audit-blue {
      background: $B1;
    }
    &__audit-orange {
      background: $Y1;
    }
  }

  .plugins-list-table {
    background: #1F1E31;
    border: 1px solid #2e2c42;
    header {
      background-color: #1F1E31 !important;
    }
  }
  
  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0; 
    right: 0;
    background: rgba(200, 200, 200, 0.3);
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 80;
  }

  .plugin-link {
    cursor: pointer;
    color: $B1;
  }
</style>