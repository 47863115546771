<template>
  <div class="narrow-container mx-2">
    <div class="d-flex flex-column flex-sm-row align-sm-center justify-space-between pa-0 px-4 mt-4 w-full gap-3">
      <v-text-field
        v-model.lazy="search"
        @change="onSearchUpdate"
        filled
        dark
        dense
        hide-details
        prepend-inner-icon="search"
        placeholder="Search by name, url or status"
        class="pa-0 flex-grow-0 search-input"
        :disabled="loading"
      />

      <div class="d-flex flex-column flex-sm-row align-sm-center gap-3">
        <columns-filter :columns.sync="columns" :columnOptions="columnOptions" :disabled="loading" />
        <v-select 
          v-model="filter.developer" 
          @blur="onDeveloperFilterBlur"
          height="40"
          :items="developersList" 
          item-text="name"
          item-value="id"
          class="developer-filter"
          placeholder="Developer Filter"
          hide-details
          filled
          dark
          clearable
          dense
          chips
          small-chips
          :disabled="loading"
          :menu-props="{ offsetY: true, nudgeBottom: 3 }"
          multiple>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.name }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ filter.developer.length - 1 }} others)
            </span>
          </template>
        </v-select>
        <StatusFilter :status="filter.status" @updateFilter="updateStatusFilter" :disabled="loading" />
      </div>
    </div>

    <div class="mt-6 mx-4" v-if="!loading">
      <v-data-table 
        :headers="headers"
        :items="pluginList"
        class="data-table"
        :sort-by="['status', 'name']"
        :sort-desc="[false, false]"
        multi-sort
        @click:row="handleClickPluginRow"
        :custom-sort="customSort"
      >
        <!-- <template slot="header" :headers="headers">
          <tr v-for="headerRow in headerDetails">
            <th v-for="header in headerRow" :rowspan="header.rowspan" :key="header.value" :colspan="header.colspan" :class="header.className">
              {{header.text}}
            </th>
          </tr>
        </template> -->
        <template v-slot:item.status="{item}">
          <div class="text-center">
            <StatusChip v-if="!!item.developerData" :status="item.developerData.status" :dataId="item.developerData.id" @updateStatus="onUpdateStatus" />
          </div>
        </template>
        <template v-slot:item.featured="{item}">
          <div class="text-center">
            <v-icon v-if="item.developerContent && item.developerContent.featured" color="yellow">mdi-star</v-icon>
          </div>
        </template>
        <template v-slot:item.fee="{item}">
          <FeeChip v-if="!!item.developerData" :data="item.developerData" />
        </template>
        <template v-slot:item.rating="{item}">
          <v-rating v-if="item.developerData && item.developerData.rating" v-model="item.developerData.rating"
            background-color="red lighten-3"
            color="red"
            small dense
          ></v-rating>
        </template>
        <template v-slot:item.version="{item}">
          <div class="text-center">
            <div v-if="!!item.version">{{ item.version.current_version_id }}</div>
          </div>
        </template>
      </v-data-table>
    </div>
    <div v-else class="spinner">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="100"
        :color="'#496DDB'"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { MODAL_STATUS_CHANGE_CONFIRMATION } from "@/components/Modals";
import { IntersectingCirclesSpinner } from "epic-spinners";
import ColumnsFilter from '@/components/_Common/ColumnsFilter';
import StatusFilter from './StatusFilter';
import StatusChip from '@/components/_Common/StatusChip';
import FeeChip from './FeeChip';
const Parse = require('parse');

export default {
  name: "PluginList",
  components: {
    ColumnsFilter,
    StatusFilter,
    StatusChip,
    FeeChip,
    IntersectingCirclesSpinner
  },
  data() {
    return {
      search: '',
      loading: true,
      columns: ['name', 'url', 'status', 'featured', 'fee', 'rating', 'version'],
      columnOptions: [
        { text: 'NAME', value: 'name' },
        { text: 'URL', value: 'url' },
        { text: 'Status', value: 'status' },
        { text: 'Featured', value: 'featured', sortable: false },
        { text: 'Fee', value: 'fee', sortable: false }, 
        { text: 'Rating', value: 'rating' }, 
        { text: 'Version', value: 'version', sortable: false }, 
        { 
          text: 'Developer', value: 'developer', sortable: false, 
          children: [
            { text: 'Name', value: 'developer.name', sortable: false },
            { text: 'Company', value: 'developer.company', sortable: false }
          ]
        }
      ],
      filter: {
        developer: '',
        status: ''
      },
      pluginList: [],
      wholePluginList: [],
      developersList: [],
      previousDeveloperFilters: '', // Used to prevent unnecessary api request from developer select unchanged
      headerRowspan: 1
    }
  },
  computed: {
    ...mapState({
      currentPublisherInfo: ({ publisher_infos }) => publisher_infos.current
    }),
    // Which columns are to be displayed on the table based on selected columns filter
    // Header rowspan applied are handled by header details
    headers() { 
      const { columns, columnOptions } = this;
      const options = columnOptions.reduce((acc, cur) => cur.children ? [...acc, ...cur.children] : [...acc, cur], [])
      return options.filter(option => columns.indexOf(option.value) !== -1);
    },
    // Header rowspan details based on selected columns filter
    headerDetails() { 
      const { columns, columnOptions } = this;
      let rowSpan = 1;
      let childrenCollection = [];
      let mappedOptions = columnOptions.map((column) => {
        const isIncluded = columns.includes(column.value);
        let children = [];
        if (column.children && column.children.length > 0) {
          children = column.children.filter((childColumn) => columns.includes(childColumn.value));
        }
        if (!isIncluded && (!children || children.length === 0)) return null;
        if (children && children.length > 0) {
          childrenCollection = [...childrenCollection, ...children]
          rowSpan = 2;
        }
        return { ...column, children, rowspan: 1, colspan: 1 };
      }).filter(column => !!column);
      this.headerRowspan = rowSpan;
      if (rowSpan !== 1) {
        mappedOptions = mappedOptions.map((column) => {
          return {...column, rowspan: column.children.length > 0 ? 1 : 2, colspan: column.children.length === 0 ? 1: column.children.length, className: column.children.length > 0 ?  'parent' : '' }
        });
        childrenCollection = childrenCollection.map((column) => {
          return {...column, colspan: 1, rowspan: 1, className: 'child'}
        });
        return [mappedOptions, childrenCollection];
      }
      return [mappedOptions];
    },
  },
  async mounted() {
    Parse.initialize(this.currentPublisherInfo.parse_server_app_id);
    Parse.serverURL = this.currentPublisherInfo.parse_server_url;
    await this.getDevelopersList();
    await this.getPluginList();
  },
  methods: {
    async getDevelopersList() {
      try {
        const res = await Parse.Cloud.run('getDevelopersList', { parseServerSiteId: this.currentPublisherInfo.parse_server_site_id });
        if (res.status === 'success') {
          this.developersList = res.developersList.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        }
      } catch (error) {
        console.error('getDevelopersList error', error);
      }
    },
    async getPluginList() {
      this.loading = true;
      try {
        const res = await Parse.Cloud.run('getPluginsList', { filter: this.filter, parseServerSiteId: this.currentPublisherInfo.parse_server_site_id });
        if (res && res.apps) {
          let plugins = this.mergePluginsWithDevelopers(res.apps);
          plugins = plugins.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
          this.pluginList = plugins;
          this.wholePluginList = plugins
        }
      } catch(error) {
        console.error('Error in getPluginList', error);
      }
      this.loading = false;
      this.onSearchUpdate();
    },
    // Merge plugins with developers list on frontend to reduce payload from server
    mergePluginsWithDevelopers(pluginList) {
      return pluginList.map(plugin => {
        if (plugin.developer) {
          const developer = this.developersList.find(developer => developer.id === plugin.developer);
          return { ...plugin, developer };
        }
        return plugin;
      });
    },
    handleClickPluginRow(value) {
      this.$router.push({ name: "plugin_detail", params: { pluginId: value.slug } });
    },

    // Search Input update event handler, frontend filtering
    onSearchUpdate() {
      if (!!this.search) {
        const search = this.search.toLowerCase();
        const result = this.wholePluginList.filter(plugin => {
          const pluginName = (plugin.name || '').toLowerCase();
          const url = (plugin.url || '').toLowerCase();
          const developerName = (plugin.developer?.name || '').toLowerCase();
          const status = (plugin.developerData?.status || '').toLowerCase();
          return ((pluginName.indexOf(search) !== -1) ||
            (url.indexOf(search) !== -1) || 
            (developerName.indexOf(search) !== -1) ||
            (status.indexOf(search) !== -1)
          );
        });
        this.pluginList = result;
      } else {
        this.pluginList = this.wholePluginList;
      }
    },
    // Developer select update event handler
    onDeveloperFilterBlur() {
      let developerFiltercontent;
      if (this.filter.developer && Array.isArray(this.filter.developer)) {
        developerFiltercontent = this.filter.developer.sort((a, b) => a > b ? 1 : -1).join(' ');
        if (this.previousDeveloperFilters !== developerFiltercontent) {
          this.getPluginList();
        }
      }
      this.previousDeveloperFilters = developerFiltercontent;
    },

    onUpdateStatus(params) {
      this.$store.commit('application/openModal', {
        component: MODAL_STATUS_CHANGE_CONFIRMATION,
        props: {
          title: "Are you sure you want to change status?" ,
          text: "Status change affect its operation and display on other sites.",
          showCancelBtn: true,
          actionBtnLabel: "Confirm",
          action: () => {
            this.updatePluginStatus(params);
          }
        }
      });
    },
    // Listener of Status Chip component update status
    async updatePluginStatus(params) {
      const { dataId, status } = params;
      this.loading = true;
      // Update status on Forge Ignite
      try {
        await Parse.Cloud.run('updateDeveloperAppData', { appDataId: dataId, status, parseServerSiteId: this.currentPublisherInfo.parse_server_site_id  });
      } catch(error) {
        console.error('Error while Updating DeveloperAppData', error);
        this.loading = false;
        return;
      }
      // Smart Update instead of fetching plugin list again
      const pluginIndex = this.pluginList.findIndex(plugin => plugin.developerData && plugin.developerData.id === dataId);
      if (pluginIndex !== -1) {
        let plugin = this.pluginList[pluginIndex];
        plugin = { ...plugin, developerData: { ...plugin.developerData, status } };
        Vue.set(this.pluginList, pluginIndex, plugin)
      }
      this.loading = false;
    },

    async updateStatusFilter(params) {
      const { status } = params;
      this.filter.status = status;
      await this.getPluginList();
    },

    // (items: any[], sortBy: string[], sortDesc: boolean[])
    customSort(items, sortFields, sortDesc) {
      return items.sort((a, b) => {
        for (let i = 0; i < sortFields.length; i++) {
          const result = this.customSortByOneField(sortFields[i], a, b)
          if (result !== 0) return sortDesc[i] ? result : -result;
        }
        return 0;
      });
    },
    customSortByOneField(sortField, a, b) {
      if (sortField === 'rating') return this.ratingSort(a, b);
      if (sortField === 'status') return this.statusSort(a, b);
      const valueA = a[sortField].toLowerCase(), valueB = b[sortField].toLowerCase();
      if (valueA === valueB) {
        return 0;
      } else if (valueA > valueB) {
        return -1;
      } else {
        return 1;
      }
    },
    ratingSort(a, b) {
      const firstRating = a.developerData?.rating || -1;
      const secondRating = b.developerData?.rating || -1;
      if (firstRating === secondRating) return 0;
      return secondRating > firstRating ? 1: -1;
    },
    statusSort(a, b) {
      const firstStatus = (a.developerData?.status || '').toLowerCase();
      const secondStatus = (b.developerData?.status || '').toLowerCase();

      if (firstStatus === secondStatus) return 0;
      if (secondStatus.toLowerCase() === 'waiting for review') return -1;
      if (firstStatus.toLowerCase() === 'waiting for review') return 1;
      return secondStatus > firstStatus ? 1 : -1;    
    }
  },
}
</script>

<style lang="scss">
.data-table {
  background: $N4 !important;
  border: 1px solid $N5;
}
</style>

<style lang="scss" scoped>
  .owner {
    background: orange;
    color: white;
    border-radius: 4px;
  }

  .remove {
    cursor: pointer;
    color: #E94A53;
    border: none;
  }
  .access-tab {
    background: #1c1b2e;
  }

  .avatar-placeholder-wrapper {
    height: 26px;
    width: 26px;
    border-radius: 100%;
    border: 1px solid #B3B3B3;
    overflow: hidden;

    .avatar-placeholder {
      height: 24px;
    }
  }

  $style: PluginList;
  .#{$style} {
    &__filter-button {
      background: transparent !important;
      border: 1px solid $N3;
      span {
        margin-left: 10px;
        margin-right: 15px;
      }
    }
    &__icon-active {
      color: $N2;
      fill: $N2;
    }
    &__icon-inactive {
      color: $N3;
      fill: $N3;
      &.expanded {
        transform: rotate(-180deg);
      }
    }
    &__add-btn {
      background-color: $B1 !important;
    }
  }

  .dense-item {
    max-height: 40px;
  }
  .search-input {
    width: 344px;
  }
  .min-height-0 {
    min-height: 0px;
  }
  .parent-checkbox {
    max-height: 40px;
    margin-top: 6px;
  }
  th {
    padding: 10px;
    border-bottom: thin solid hsla(0,0%,100%,.12);
    &.parent {
      border-left: thin solid hsla(0,0%,100%,.12);
      border-right: thin solid hsla(0,0%,100%,.12);
    }
    &.child {
      border-left: thin solid hsla(0,0%,100%,.12);
      border-right: thin solid hsla(0,0%,100%,.12);
    }
  }  
  .sale-ready {
    color: $G1;
    &:before {
      background: $G1;
    }
  }
  .need-review {
    color: $Y1;
    &:before {
      background: $Y1;
    }
  }
  .declined {
    color: $R2;
    &:before {
      background: $R2;
    }
  }
  .spinner {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
  }
  .developer-filter {
    border: 1px solid #5e636d;
    border-radius: 8px;
    width: 300px;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  // class=""
  // the-navigation v-navigation-drawer v-navigation-drawer--fixed v-navigation-drawer--custom-mini-variant v-navigation-drawer--open theme--dark
  // the-navigation v-navigation-drawer v-navigation-drawer--fixed v-navigation-drawer--custom-mini-variant v-navigation-drawer--open theme--dark v-navigation-drawer--is-mobile
</style>