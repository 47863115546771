import store from '@/store';

export class Project {
  id = null;
  name = null;
  image = null;
  sites = [];
  plugins = [];
  allObjects = [];
  items_count = 0;
  user_id = null;
  icon = 'folder';
  iconColor = '#a4a4ae';


  constructor(origin) {
    if (!origin)
      return;

    this.id  = origin.id;
    this.name = origin.name;
    this.user_id = origin.user_id;
    this.icon = origin.icon;
    this.iconColor = origin.icon_color;
    if (origin.site_ids.length > 0) {
      for (let site_id of origin.site_ids)
      {
        let site = store.getters['sites/findById'](site_id);
        this.allObjects.push(site);
        if (site == undefined)
          continue;
        if (site.kind == 'site')
          this.sites.push(site);
        else
          this.plugins.push(site);
        this.items_count += 1;
      }
      // this.sites = origin.site_ids.map(id => store.getters['sites/findById'](id));
    }
  }

  getServerObject() {
    return {
      name: this.name,
      image: this.image,
      sites: this.sites.map(s => s.id)
    };
  }
}
