<template>
  <v-app-bar app dense class="the-app-bar" dark :height="82">
    <v-app-bar-nav-icon class="d-sm-none" @click="toggleMobileNav" />
    <v-divider class="the-app-bar__divider" />
    <h4 v-if="$route.name === 'dashboard'" class="the-app-bar__route-name">
      Dashboard
    </h4>
    <h4 v-if="$route.meta && $route.meta.title" class="the-app-bar__route-name">
      {{ $route.meta.title }}
    </h4>
    <site-mode-select v-if="type == TYPE_SITE" :value="siteMode" @input="modeChanged" />
    <div class="the-app-bar__tabs-container mx-auto">
      <div v-if="$route.name !== 'dashboard' && !isMobile">
        <v-tabs class="the-app-bar__tabs" background-color="transparent" v-model="activeTab">
          <v-tab
            class="the-app-bar__tab"
            active-class="_active"
            v-for="tab in tabs"
            :key="tab.name"
            :to="tab.to"
            @click="onTabClick(tab)"
          >{{ tab.name }}</v-tab>
          <v-tab class="the-app-bar__more-icon-tab" active-class="_active" @click="toggleMoreAppsBar" v-if="type == TYPE_SITE && apps && apps.length > 0 && !publisherRoute">
            <the-icon class="mr-2" icon="more-vert" />
          </v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="mr-4 the-app-bar__upgrade-bar" :class="publisherRoute ? '' : 'client-link'" v-if="notPaid">
      <router-link :to="{ name: 'profile_billing' }">
        <v-icon :color="publisherRoute ? '#496ddb' : 'y1'">mdi-arrow-up-bold-circle-outline</v-icon>
        <span> Upgrade Your Plan </span>
      </router-link>
    </div>
    <div class="the-app-bar__right-block">
      <Notifications :openAllNotificationsModal="openAllNotificationsModal" />
      <span class="the-app-bar__user-name d-none d-md-block">
        {{ user.name }}
      </span>
      <span class="d-inline-block" style="cursor: pointer;">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <div class="d-inline-block">
                <img :src='user.avatar' alt="" v-if="!avatarEmpty" class="the-app-bar__image-wrapper" />
                <avatar v-else :rounded="true" :initials="initials" username="name" :size="26" />
              </div>
              <v-icon class="d-inline-block ml-2 mr-1" color="grey" dark dense>
                mdi-chevron-down
              </v-icon>
            </span>
          </template>
          <v-list dark class="the-app-bar__dropdown">
            <template v-for="(tab, index) in TABS_PROFILE_DROPDOWN">
              <v-list-item
                v-if="!tab.divider && !tab.external"
                :key="index"
                :to="tab.to"
              >
                <v-list-item-title>{{ tab.name }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!tab.divider && tab.external"
                :key="index"
                @click="openExternalLink(tab.to)"
              >
                <v-list-item-title>{{ tab.name }}</v-list-item-title>
              </v-list-item>
              <v-divider v-if="tab.divider" :key="index" />
            </template>
            <v-list-item @click="onLogOut">
              <v-list-item-title>
                Log out
                <v-icon class="ml-2">exit_to_app</v-icon>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
    </div>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Avatar from 'vue-avatar';
import router from "@/router";
import { SITE_MODE_PRODUCTION } from "@/models/Site";
import SiteModeSelect from "./TheAppBar/SiteModeSelect.vue";
import Notifications from '@/components/elements/Notifications';
import { MODAL_ALL_NOTIFICATIONS } from "@/components/Modals";

const TYPE_PROFILE = "TYPE_PROFILE";
const TYPE_PROJECT = "TYPE_PROJECT";
const TYPE_SITE = "TYPE_SITE";
const TYPE_ORGANISATION = "TYPE_ORGANISATION";

const TABS_PROFILE_DROPDOWN = [
  {
    name: "Settings",
    to: { name: "profile_settings" },
  },
  {
    name: "Billing",
    to: { name: "profile_billing" },
  },
  {
    name: "Invoices",
    to: { name: "profile_invoices" },
  },
  {
    divider: true,
  },
  {
    name: "Community",
    to: "https://community.beach.io/c/products/forge",
    external: true,
  },
  {
    name: "Documentation",
    to: "https://docs.getforge.com",
    external: true,
  },
  {
    divider: true,
  }
];

const TABS_PROFILE_NAVBAR = [
  {
    name: "Settings",
    to: { name: "profile_settings" },
  },
  {
    name: "Billing",
    to: { name: "profile_billing" },
  },
  {
    name: "Invoices",
    to: { name: "profile_invoices" },
  }
];

const TABS_PROJECT = [
  {
    name: "Sites",
    to: { name: 'project_sites'}
  },
  {
    name: "Main",
    to: { name: "project_main" },
  },
  {
    name: "Access",
    to: { name: "project_access" },
  },
];

const TABS_ORGANISATION = [
  {
    name: "General",
    to: {name: 'organisation_main'}
  },
  {
    name: 'Members',
    to: {name: 'organisation_access'}
  },
  {
    name: 'Audit',
    to: {name: 'organisation_audit'}
  },
  {
    name: 'Security',
    to: {name: 'organisation_security'}
  },
  {
    name: 'Identity & Access',
    to: {name: 'organisation_identity_access'}
  },
  {
    name: 'Infrastructure',
    to: {name: 'organisation_infra'}
  }
]

const TABS_SITE = [
  {
    name: "Version",
    to: { name: "site_versions" },
  },
  {
    name: "Forms",
    to: { name: "site_forms_list" },
  },
  {
    name: "Usage",
    to: { name: "site_usage" },
  },
  {
    name: "Settings",
    to: { name: "site_settings" },
  },
];

const TAB_ACCESS = {
  name: "Sharing",
  to: { name: "site_access" },
};

export default {
  components: { SiteModeSelect, Avatar, Notifications },
  name: "TheAppBar",
  data() {
    return {
      TYPE_PROFILE,
      TYPE_PROJECT,
      TYPE_SITE,
      TABS_PROFILE_DROPDOWN,
      TABS_PROFILE_NAVBAR,
      activeTab: null
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.current,
      showMoreAppBar: (state) => state.sites.showMoreAppBar,
      organisation: (state) => state.organisations.current,
      apps: (state) => state.sites.apps,
      notifications: (state) => state.notifications.list
    }),
    
    navDrawer() {
      return this.$store.state.application.navDrawer;
    },
    type() {
      if (!this.$route.matched[0]) return null;

      switch (this.$route.matched[0].name) {
        case "profile":
          return TYPE_PROFILE;
        case "project":
          return TYPE_PROJECT;
        case "site":
          return TYPE_SITE;
        case "organisation":
          return TYPE_ORGANISATION;
      }
      return null;
    },
    notPaid() {
      return !['iron', 'bronze', 'copper', 'lead'].includes(this.user.plan_id) &&
        this.$route.name != 'profile_billing';
    },
    siteMode() {
      return this.activeSite.mode;
    },
    siteTabs() {
      let app_access;
      if (this.organisation.id == 0)
        app_access = this.user.appAccess;
      else
        app_access = this.organisation.app_access && this.organisation.subscription_active;
      if (app_access) {
        return TABS_SITE.concat({
          name: "Apps",
          to: { name: "site_apps" },
        });
      } else return TABS_SITE;
    },
    tabs() {
      switch (this.type) {
        case TYPE_PROFILE:
          return TABS_PROFILE_NAVBAR;
        case TYPE_PROJECT: {
          let tabs;
          if (this.$store.state.organisations.current.id == 0)
            tabs = TABS_PROJECT
          else
            tabs = TABS_PROJECT.filter((tab) => tab.name != 'Access');
          return tabs;
        }
        case TYPE_ORGANISATION:
          {
            let arr = _.cloneDeep(TABS_ORGANISATION)
              if (this.$store.state.organisations.current.role == 'admin')
                arr.splice(2, 0, ...[{
                  name: 'Billing',
                  to:  {name: 'organisation_billing'}
                }])
            return arr
          }
        case TYPE_SITE: {
          if (!this.activeSite.projectId &&
              this.$store.state.organisations.current.id == 0 &&
              this.activeSite.mode == 'production')
            return this.siteTabs.concat(TAB_ACCESS);
          else return this.siteTabs;
        }
      }
      return null;
    },
    avatarEmpty() {
      return this.user.avatar == null || this.user.avatar.length == 0;
    },
    initials() {
      if (this.$store.state.user.current.name != null && this.$store.state.user.current.name.length > 0)
        return this.$store.state.user.current.name[0].toUpperCase();
      else
        return this.$store.state.user.current.email[0].toUpperCase();
    },
    userAvatar() {
      if (this.user.avatar) return this.user.avatar;
      return require("@/assets/avatar-placeholder.svg");
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    publisherRoute() {
      return (this.$route.meta.publisherRoute === true);
    }
  },
  mounted() {
    this.$root.$on('extensionPoints', this.handleExtensionPoints);
  },
  beforeDestroy() {
    this.$root.$off('extensionPoints', this.handleExtensionPoints);
  },
  methods: {
    ...mapMutations("sites", ["setActiveApp", "setApps", "setShowMoreAppBar"]),
    ...mapMutations("application", ["toggleMobileNav"]),
    changeDrawer() {
      this.$store.commit("application/setNavDrawer", !this.navDrawer);
    },
    onLogOut() {
      this.$store.dispatch("application/logOut", null);
    },
    openSite() {
      let site_url = "";
      if (this.activeSite.use_ssl) site_url = "https://" + this.activeSite.url;
      else site_url = "http://" + this.activeSite.url;
      window.open(site_url, "_blank");
    },
    modeChanged(value) {
      if (this.siteMode != value)
        this.$store.dispatch("sites/getSiteMode", {id: this.activeSite.id, mode: value});
    },
    onTabClick() {
      this.setShowMoreAppBar(false);
    },
    onAppClick(item) {
      if (item.component) {
        router.push({ name: 'site_plugin', params: { slug: item.slug || item.extensionId } });
      } else if (item.onClick)
        this.$root.$emit('sendMessage', item)
    },
    handleExtensionPoints(data) {
      if (data && data.siteTab) {
        const unique = [...new Map(data.siteTab.map((m) => [(m.id || m.slug || m.extensionId), m])).values()];
        this.setApps(unique);
        this.findAndSetActiveApp();
      }
    },
    findAndSetActiveApp() {
      if (this.$route.name.indexOf('site_plugin') !== -1 && this.$route?.params?.slug) {
        const slug = this.$route.params.slug;
        let app = this.apps.find(p => (p.slug === slug || p.extensionId === slug));
        this.setActiveApp(app);
        this.activeTab = this.tabs.length;
      }
    },
    toggleMoreAppsBar() {
      this.setShowMoreAppBar(!this.showMoreAppBar);
    },
    openAllNotificationsModal() {
      this.$store.commit("application/openModal", {
        component: MODAL_ALL_NOTIFICATIONS
      });
    },
    openExternalLink(url) {
      window.open(url, "_blank");
    }
  },
  watch: {
    showMoreAppBar: {
      handler(newValue) {
        if (newValue) this.activeTab = this.tabs.length;
      },
      immediate: true
    },
    '$route.name': {
      handler() {
        this.findAndSetActiveApp();
        if (this.type !== TYPE_SITE && this.showMoreAppBar) this.setShowMoreAppBar(false);
      }
    }
  }
};
</script>

<style lang="scss">
$style: the-app-bar;
.#{$style} {
  &.theme--dark.v-app-bar.v-toolbar.v-sheet {
    background-color: $bg-color;
    box-shadow: none;
  }
  & .v-toolbar__content {
    padding: 24px;
  }
  &__image-wrapper {
    height: 26px;
    width: 26px;
    border-radius: 100%;
    border: 1px solid #b3b3b3;
    overflow: hidden;
    vertical-align: middle;
  }
  &__user-image {
    height: 24px;
  }
  &__tabs-container {
    overflow: auto;
  }
  &__tabs {
    & .v-tabs-bar {
      height: 82px;
    }
  }
  &__tab {
    &.v-tab {
      text-transform: initial;
      font-size: $H14;
      color: $N2;
      &._active {
        color: $white;
      }
    }
  }
  &__more-icon-tab {
    padding: 0;
    &.v-tab {
      width: 56px;
      min-width: 56px;
    }
  }
  &__divider {
    position: absolute;
    bottom: 0;
    left: 24px;
    right: 0;
    &.v-divider {
      background-color: $N5;
    }
  }
  &__route-name {
    color: $N2;
    font-size: $H24;
    font-weight: 500;
    margin: 0;
  }
  &__user-name {
    color: $N2;
    font-weight: 500;
    font-size: $H14;
    display: inline-block;
    margin: 0 18px;
  }
  @media screen and  (max-width: 1495px) {
    &__upgrade-bar {
      display: none !important;
    }
  }
  &__upgrade-bar {
    a {
      text-decoration: unset;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    &.client-link span {
      color: #FF7E21;
    }
  }
  &__right-block {
    display: flex;
    align-items: center;
  }
  &__notify {
    position: relative;
    color: $N2;
  }
  &__notify-badge {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $R1;
    position: absolute;
    right: 3px;
    top: 3px;
    border: 1px solid $bg-color;
  }
  &__dropdown {
    &.theme--dark.v-list {
      background: rgba(37, 36, 55, 0.9);
      border: 1px solid $N5;
      box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(10px);
      border-radius: 8px;
    }
  }
  @media screen and (max-width: 600px) {
    &__divider {
      right: 24px;
    }
    &__route-name {
      font-size: $H16;
    }
  }
}
</style>
