<template>
  <div class="ReportCard px-4 mb-2">
    <div class="d-flex py-4 items-center">
      <div class="ReportCard__icon-button pa-2 mr-4">
        <the-icon icon="document" class="ReportCard__icon" />
      </div>
      <div v-if="lastUpdate">
        <div class="mb-1">{{ title }}</div>
        <div class="date-cell">Last updates {{ lastUpdate }}</div>
      </div>
      <div v-else>{{ title }}</div>
    </div>
    <div class="d-flex"
      v-if="lastUpdate">
      <v-btn
        class="v-btn viewBtn"
        @click="onViewReport"
        elevation="2"
        large
      >
        <v-icon dark left> mdi-eye </v-icon>
        View
      </v-btn>

      <v-btn
        class="v-btn exportBtn"
        @click="onExportReport"
        elevation="2"
        large
      >
        <v-icon dark left color="white">mdi-export-variant</v-icon>
        Export
      </v-btn>
    </div>
    <v-btn 
      v-else
      class="v-btn playBtn"
      @click="onRunReport"
      elevation="2"
      large
    >
      <v-icon dark left> mdi-play </v-icon>
      Run
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: "ReportCard",
    props: {
      title: String,
      route: String,
      lastUpdate: String
    },
    methods: {
      onRunReport() {
        if (this.route) this.$router.push({ name: this.route });
      },
      onViewReport() {
        alert('view report');
      },
      onExportReport() {
        alert('export report');
      },
    }
  }
</script>

<style lang="scss" scoped>
  $style: ReportCard;
  .#{$style} {
    background: $N7;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__icon-button {
      background: $N5;
      height: 40px;
    }
    &__icon {
      color: $B1;
      fill: $B1;
    }
    .items-center {
      align-items: center;
    }
    .v-btn {
      display: block;
      margin-left: 10px;
    }
    .viewBtn {
      color: $B1;
      background: $N5;
    }
    .exportBtn {
      color: white;
      background: $N5;
    }
    .playBtn {
      background: $B1;
      color: white;
    }
    .date-cell {
      color: #585B6A;
    }
  }
</style>