<template>
  <div>
    <div class="spinner">
      <IntersectingCirclesSpinner :animation-duration="1200" :size="100" :color="'#496DDB'" />
    </div>
  </div>
</template>

<script>
  import {IntersectingCirclesSpinner} from "epic-spinners";
  export default {
    name: "OauthGoogle",
    data() {
      return {
        code: ''
      }
	  },
    components: {IntersectingCirclesSpinner},
    async created() {
      let url = ""
      let params = {};
      if (this.$route.query.logAuth)
      {
          let oauthClient = this.$route.name == 'githubAuth' ? 'github' : 'bitbucket';
          let redirect_host = window.location.host.split('.')[0];
          let redirect_uri = `https://getforge.com/oauth/${oauthClient}/${redirect_host}?logAuth=true`
          const res = await this.$store.dispatch(`user/${this.$route.name}`, {code: this.$route.query.code, redirect_uri: redirect_uri})
          if (res.body.registration)
            this.$router.push( { name: 'registration', params: { finishedReg: true} })
          else
            this.$router.push('/')
      }
      else {
        switch(this.$route.name) {
          case 'githubAuth':
            url = 'oauth/github';
            params = {code: this.$route.query.code};
            break;
          case 'bitbucketAuth':
            url = 'oauth/bitbucket';
            params = {code: this.$route.query.code};
            break;
          case 'dropboxAuth':
            url = 'oauth/dropbox';
            let access_token = this.$route.hash.substring(1).split('&')[0].replace("access_token=", '');
            params = {access_token: access_token}
            break;
          default: 
            console.log("wrong route name");
        }
    
        this.$http.get(url, { params: params})
        .then(() => {
           window.close();
        })
        .catch(() => {
          window.close();
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .spinner {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
</style>
