import {ParseCloud} from '@/models/ParseCloud';
import {ParseCloud as ParseCloudResource} from "@/resources/forge";

const store = {
  namespaced: true,
  state: {
    current: null
  },
  mutations: {
    /**
     * Sets the current ParseCloud object in the store state.
     * @param {Object} current - The current ParseCloud object.
     */
    setCurrent (state, current) {
      state.current = current;
    },
    /**
     * Deletes the current ParseCloud object from the store state.
     * @param {string} id - The ID of the ParseCloud object to delete.
     */
    delete(state, id) {
      state.current = null;
    },
    /**
     * Creates a new ParseCloud object and sets it as current in the store state.
     * @param {Object} current - The new ParseCloud object to set as current.
     */
    create(state, current) {
      state.current = current;
    },
    /**
     * Updates the current ParseCloud object in the store state.
     * @param {Object} params - The parameters to update the current ParseCloud object.
     */
    update(state, params) {
      if (state.current.id != params.current.id)
        return;

      state.current = params.current;
    }
  },
  actions: {
    /**
     * Loads a ParseCloud object from the API and sets it as current in the store state.
     * @param {string} parse_setting_id - The ID of the Parse setting.
     * @returns {Promise} A promise resolving to the API response.
     */
    async load({commit, dispatch}, parse_setting_id) {
      const res = await ParseCloudResource.get({parse_setting_id: parse_setting_id});
      const parseCloud = new ParseCloud(res.body.parse_custom_codes[0]);
      commit('setCurrent', parseCloud);
      if (parseCloud.id != null) {
        dispatch(
          "versions/loadParse",
          parseCloud.id,
          {root: true}
        );
      }
      return res;
    },
    /**
     * Updates the ParseCloud object.
     * @param {Object} params - The parameters for updating the ParseCloud object.
     * @returns {Promise} A promise resolving to the API response.
     */
    update({commit, dispatch}, params) {
      return ParseCloudResource.update({id: params.id}, {parse_custom_code: params.parse_custom_code})
        .then((res) => {
          commit("setCurrent", new ParseCloud(res.body.parse_custom_code));
          if (params.parse_custom_code.current_version_id == undefined)
            dispatch(
              "versions/addParseVersion",
              res.body.version,
              {root: true}
            );
          })
        .catch((err) => {
          console.log(err);
        }); 
    }
  },
  getters: {
    /**
     * Finds a ParseCloud object by its ID.
     * @param {string} id - The ID of the ParseCloud object to find.
     * @returns {Object|null} The ParseCloud object if found, otherwise null.
     */
    findById: state => id => {
      return state.list.find(v => v.id == id);
    }
  }
};

export default store;
