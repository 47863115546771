<template>
  <div class="narrow-container mx-2">
    <div class="mt-6 mx-4" v-if="!loading">
      <v-data-table 
        :headers="headers"
        :items="sdkList"
        class="data-table"
        show-expand
      >
        <template v-slot:item.changeLog="{item}">
          <div v-html="item.changeLog" class="change-log"></div>
        </template>
        <template v-slot:item.updatedAt="{item}">
          {{ formatDate(item.updatedAt) }}
        </template>
        <template v-slot:item.url="{item }">
          <a v-if="item.url" :href="item.url" target="_blank" download>Download</a>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div v-html="item.changeLog"></div>
          </td>
        </template>
      </v-data-table>
    </div>
    <div v-else class="spinner">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="100"
        :color="'#496DDB'"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import { IntersectingCirclesSpinner } from "epic-spinners";
const Parse = require('parse');

export default {
  name: "ReviewList",
  components: {
    IntersectingCirclesSpinner
  },
  data() {
    return {
      loading: false,
      columns: ['version', 'changeLog', 'updatedAt', 'url'],
      columnOptions: [
        { text: 'Version', value: 'version' },
        { text: 'Change Log', value: 'changeLog' },
        { text: 'Last Updated', value: 'updatedAt' },
        { text: '', value: 'url' }
      ],
      sdkList: []
    }
  },
  computed: {
    ...mapState({
      currentPublisherInfo: ({ publisher_infos }) => publisher_infos.current
    }),
    // Which columns are to be displayed on the table based on selected columns filter
    // Header rowspan applied are handled by header details
    headers() { 
      const { columns, columnOptions } = this;
      const options = columnOptions.reduce((acc, cur) => cur.children ? [...acc, ...cur.children] : [...acc, cur], [])
      return options.filter(option => columns.indexOf(option.value) !== -1);
    },
  },
  async mounted() {
    Parse.initialize(this.currentPublisherInfo.parse_server_app_id);
    Parse.serverURL = this.currentPublisherInfo.parse_server_url;
    await this.getSDKList();
  },
  methods: {
    async getSDKList() {
      this.loading = true;
      try {
        const res = await Parse.Cloud.run('getSDKList', { parseServerSiteId: this.currentPublisherInfo.parse_server_site_id });
        this.sdkList = res.data;
      } catch(error) {
        console.error('Error in getSDKList', error);
      }
      this.loading = false;
    },
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY hh:mm');
    }
  },
}
</script>

<style lang="scss">
.data-table {
  .change-log {
    width: 350px;
    * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

</style>
