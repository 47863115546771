<template>
	<div></div>
</template>

<script>
  import {Intercom, shutdown} from '@intercom/messenger-js-sdk';
  import {INTERCOM_APP_ID, INTERCOM_API_KEY} from "@/env";
  import crypto from 'crypto';


  export default {
  	name: "IntercomScript",
  	data() {
	  return {
	  	intercomAdded: false
	  }
  	},
  	methods: {
	  setUpIntercom() {
	  	if (this.intercomAdded && this.$store.state.user.current == null){
  		  shutdown();
	      this.intercomAdded = false;
	  	  return
	  	}
        const user_hash = crypto.createHmac('sha256', INTERCOM_API_KEY).update(this.$store.state.user.current.id.toString()).digest('hex');
        Intercom({
		    app_id: INTERCOM_APP_ID,
		    user_id: this.$store.state.user.current.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
		    name: this.$store.state.user.current.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
		    email: this.$store.state.user.current.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
		    user_hash: user_hash, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
		  });
        this.intercomAdded = true;

	  }
  	},
  	watch: {
	  '$store.state.user.current': {
	  	handler(newVal) {
  		  this.setUpIntercom();
	  	}
	  }
  	},
  	mounted() {
	  if (!this.$store.state.user.current)
	  	return
	  else 
	    this.setUpIntercom()
  	}
  }
</script>