export class Organisation {
  id = 0;
  name = null;
  role = null;
  user_id = null;
  sites_count = 0;
  bandwidth = 0;
  members = 0;
  buildMinutes = 0;
  maximum_sites = 0;
  maximum_projects = 0;
  plan_id = null;
  projects_count = 0;
  logo = null;
  app_access = false;
  companyEmails = [];
  trial = false;
  organisation_subscription_id = null;
  card_added = false;
  subscription_features = [];
  payment_period = 'monthly';
  subscription_end_at = null;
  subscription_active = true;
  
  constructor(origin) {
    if (!origin)
      return;

    this.id = origin.id;
    this.name = origin.name;
    this.app_access = origin.app_access;
    this.bandwidth = origin.bandwidth;
    this.buildMinutes = origin.build_minutes;
    this.logo  = origin.logo_url;
    this.subscription_active = origin.subscription_active;
    this.role = origin.role;
    this.members = origin.members;
    this.plan_id = origin.plan_id;
    this.user_id = origin.user_id;
    this.subscription_features = origin.subscription_features;
    this.companyEmails = origin.domains;
    this.sites_count = origin.sites_count;
    this.maximum_sites = origin.maximum_sites;
    this.projects_count = origin.projects_count;
    this.maximum_projects = origin.maximum_projects;
    this.trial = origin.trial;
    this.subscription_end_at = origin.subscription_end_at;
    this.organisation_subscription_id = origin.organisation_subscription_id;
    this.card_added = origin.card_added;
    this.payment_period = origin.payment_period;
  }

  getServerObject() {
    return {
      id: this.id,
      name: this.name
    }
  }
}