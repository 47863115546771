<template>
  <div class="narrow-container mx-2">
    <div class="d-flex flex-column flex-sm-row align-sm-center justify-space-between pa-0 px-4 mt-4 w-full gap-3">
      <v-text-field
        v-model.lazy="search"
        @change="onSearchUpdate"
        filled
        dark
        dense
        hide-details
        prepend-inner-icon="search"
        placeholder="Search by name, email or company"
        class="pa-0 flex-grow-0 search-input"
        :disabled="loading"
      />

      <div class="d-flex flex-column flex-sm-row gap-3 align-sm-center">
        <columns-filter :columns.sync="columns" :columnOptions="columnOptions" :disabled="loading" />
        <v-checkbox 
          type="checkbox"
          :checked="verified"
          :indeterminate="verifiedIndeterminate"
          @click.stop="onUpdateVerifiedFilter"
          @keyup.space.prevent="onUpdateVerifiedFilter"
          label="Verified"
          hide-details
          :disabled="loading"
          class="mx-2 mt-0" />
      </div>
    </div>

    <div class="mt-6 mx-4" v-if="!loading">
      <v-data-table 
        :headers="headers"
        :items="developerList"
        class="developers-list-table"
        :sort_by="['name']"
        @click:row="toDeveloperDetail"
      >
        <template v-slot:item.country="{item}">
          <td>
            {{ formatCountry(item.country) }}
          </td>
        </template>
        <template v-slot:item.verified="{item}">
          <td>
            <span class="verified-chip verified" v-if="!!item.verified">
              <the-icon icon="verified" /> 
            </span>
            <span class="verified-chip warn-verified" v-else>
              <the-icon icon="warn-verified" /> 
            </span>
          </td>
        </template>
        <template v-slot:item.updatedAt="{item}">
          <td>
            {{ formatDate(item.updatedAt) }}
          </td>
        </template>
        <template v-slot:item.action="{item}">
          <td width="30">
            <v-menu
              left
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <span class="action-button" v-bind="attrs" v-on="on">
                  <the-icon icon="action-vertical" />
                </span>
              </template>

              <v-list dense>
                <v-list-item class="action-item view" @click="toDeveloperDetail(item)"><the-icon icon="hide" /> View</v-list-item>
                <v-divider v-if="isPublisherAdmin" />
                <v-list-item class="action-item delete" @click="onRemoveDeveloper(item)" v-if="isPublisherAdmin"><the-icon icon="trash-filled" /> Delete</v-list-item>
              </v-list>
            </v-menu>
            
          </td>
        </template>
      </v-data-table>
    </div>
    <div v-else class="spinner">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="100"
        :color="'#496DDB'"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { IntersectingCirclesSpinner } from "epic-spinners";
import dayjs from "dayjs";
import ColumnsFilter from '@/components/_Common/ColumnsFilter';
import {MODAL_ALERT} from "@/components/Modals";
import { getCountryLabel } from "@/utils/common";

const Parse = require('parse');
export default {
  name: "DeveloperList",
  components: {
    ColumnsFilter,
    IntersectingCirclesSpinner
  },
  data() {
    return {
      search: '',
      loading: true,
      verified: null,
      columns: ['name', 'email', 'country', 'verified', 'updatedAt'],
      columnOptions: [
        { text: 'NAME', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Country', value: 'country' },
        { text: 'Verified', value: 'verified', sortable: false },
        { text: 'Last Update', value: 'updatedAt' }
      ],
      developerList: [],
      wholeDevelopersList: []
    }
  },
  computed: {
    ...mapState({
      currentPublisherInfo: ({ publisher_infos }) => publisher_infos.current
    }),
    // Which columns are to be displayed on the table based on selected columns filter
    headers() { 
      const { columns, columnOptions } = this;
      const headerOptions = columnOptions.filter(columnOption => columns.indexOf(columnOption.value) !== -1);
      return [...headerOptions, { text: 'Action', value: 'action', sortable: false }];
    },
    verifiedIndeterminate() {
      return (this.verified == null);
    },
    isPublisherAdmin() {
      return (this.currentPublisherInfo.role === 'admin')
    }
  },
  mounted() {
    Parse.initialize(this.currentPublisherInfo.parse_server_app_id);
    Parse.serverURL = this.currentPublisherInfo.parse_server_url;
    this.getDeveloperList();
  },
  methods: {
    async getDeveloperList() {
      this.loading = true;
      const res = await Parse.Cloud.run('getDevelopersList', { verified: this.verified, parseServerSiteId: this.currentPublisherInfo.parse_server_site_id });
      this.developerList = res.developersList;
      this.wholeDevelopersList = res.developersList;
      this.loading = false;
    },
    formatCountry(countryCode) {
      return getCountryLabel(countryCode);
    },
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY hh:mm');
    },
    toDeveloperDetail(developer) {
      this.$router.push({ name: "developer_detail", params: { developerId: developer.id } });
    },
    // Search Input update event handler, frontend filtering
    onSearchUpdate() {
      if (!!this.search) {
        const search = this.search.toLowerCase();
        const result = this.developerList.filter(developer => {
          const name = (developer.name || '').toLowerCase();
          const email = (developer.email || '').toLowerCase();
          const company = (developer.company || '').toLowerCase();
          return ((name.indexOf(search) !== -1) ||
            (email.indexOf(search) !== -1) || 
            (company.indexOf(search) !== -1));
        });
        this.developerList = result;
      } else {
        this.developerList = this.wholeDevelopersList;
      }
    },
    onUpdateVerifiedFilter() {
      let newValue;
      switch (this.verified) {
        case true:
          newValue = false;
          break;
        case false:
          newValue = null;
          break;
        case null:
          newValue = true;
          break;
      }
      this.verified = newValue;
      this.getDeveloperList();
    },
    onRemoveDeveloper(developer) {
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: "Remove Developer" ,
          text: `You are going to remove ${developer.name}. Are you sure?`,
          actionBtnLabel: 'Remove',
          showCancelBtn: true,
          action: async () => {
            const res = await Parse.Cloud.run('removeDeveloper', { developerId: developer.id, parseServerSiteId: this.currentPublisherInfo.parse_server_site_id });
            if (res.status === 'success') await this.getDeveloperList();
          }
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .owner {
    background: orange;
    color: white;
    border-radius: 4px;
  }
  .developers-list-table {
    background: #1F1E31;
    border: 1px solid #2e2c42;
    header {
      background-color: #1F1E31 !important;
    }
  }
  .remove {
    cursor: pointer;
    color: #E94A53;
    border: none;
  }
  .access-tab {
    background: #1c1b2e;
  }

  .avatar-placeholder-wrapper {
    height: 26px;
    width: 26px;
    border-radius: 100%;
    border: 1px solid #B3B3B3;
    overflow: hidden;

    .avatar-placeholder {
      height: 24px;
    }
  }

  $style: DeveloperList;
  .#{$style} {
    &__filter-button {
      background: transparent !important;
      border: 1px solid $N3;
      span {
        margin-left: 10px;
        margin-right: 15px;
      }
    }
    &__icon-active {
      color: $N2;
      fill: $N2;
    }
    &__icon-inactive {
      color: $N3;
      fill: $N3;
      &.expanded {
        transform: rotate(-180deg);
      }
    }
    &__add-btn {
      background-color: $B1 !important;
    }
  }

  .verified-chip {
    padding: 7px;
    border-radius: 28px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0.1;
      display: block;
      content: "";
      border-radius: 28px;
    }
    &.verified {
      &:before {
        background: $G1;
      }
      svg {
        fill: $G1;
        color: $G1;
      }
    }

    &.warn-verified {
      &:before {
        background: $Y1;
      }
      svg {
        fill: $Y1;
        color: $Y1;
      }
    }
  }

  .action-button {
    padding: 8px;
    display: flex;
    align-items: center;
    width: 25px;
    flex-grow: 0;
    border-radius: 6px;
    cursor: pointer;
    &:hover {
      background: $N5;
    }
    svg {
      color: white;
      fill: white;
    }
  }

  .action-item {
    display: flex;
    align-items: center;
    width: 120px;
    cursor: pointer;
    &.view {
      svg {
        color: white;
        fill: white;
        margin-right: 5px;
      }
    }
    &.delete {
      color: $R1 !important;
      svg {
        color: $R1;
        fill: $R1;
        margin-right: 5px;
      }
    }
  }
  .search-input {
    width: 344px;
  }
  .spinner {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
  }
</style>