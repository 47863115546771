<template>
  
  <div class="narrow-container" v-if="!loading">
    <div class="">
      <v-btn
        v-if="dashBoardEnabled"
        color="primary"
        class="mt-6 mr-2"
        dark
        @click="OpenDashboard"
      >
        Open Dashboard <v-icon small class="ml-2"> mdi-arrow-top-right </v-icon>
      </v-btn>

      <router-link v-if="cloudEnabled"
        :to="{name: 'parse_cloud' }" 
        class=" pa-2"
      >
        <v-btn dark color="primary" class="mt-6">
          <v-icon small color="#d6d3d3" class="list-item-icon">mdi-square-edit-outline</v-icon>
          <div class="ml-2">Edit Cloud Code</div>
        </v-btn>
      </router-link>
    </div>
    <div class="mt-6">Parse Server URL</div>
    <v-text-field
      dark
      :value="parseSetting.parse_server_app_url"
      class="mt-1"
      filled
      readonly
      dense
      hide-details
    />

    <div class="mt-4">Parse Server APP_ID</div>
    <v-text-field
      dark
      readonly
      v-model="parseSetting.appId"
      class="mt-1"
      filled
      dense
      hide-details
    />

    <div class="mt-4">Parse Server master key</div>
    <v-text-field
      dark
      readonly
      v-model="parseSetting.masterKey"
      class="mt-1"
      filled
      dense
      hide-details
    />

    <div class="mt-4">Parse Server client key</div>
    <v-text-field
      dark
      readonly
      v-model="parseSetting.clientKey"
      class="mt-1"
      filled
      dense
      hide-details
    />

    <div class="mt-4">Chisel CMS URL</div>
    <div class="my-2 note pl-2">You can copy url from chisel-cms app by pressing "Copy" button.</div>
    <v-text-field
      dark
      v-model="parseSetting.chiselUrl"
      class=""
      filled
      dense
      autocomplete="off"
      hide-details
    >
      <template v-slot:append>
        <v-btn
            text
            @click="copyChiselUrl"
            small
            class="mb-2"
            color="orange"
          >
            Copy
        </v-btn>
      </template>
    </v-text-field>

    <div class="mt-4">Your github repository</div>
    <div class="my-2 note pl-2">
      To use private git repository you first need to add the SSH key provided by Forge to your account with your git provider <br/>
      <a href="#" @click="copySSH"> Click Here </a> to copy the SSH Key.<br/>
      Important: You should add the SSH key to your git account before deploying and activating Forge Ignite.
    </div>

    <v-text-field
      dark
      v-model="parseSetting.github_repository"
      class=""
      filled
      autocomplete="off"
      dense
      hide-details
    />

    <div class="mt-4">Your github branch</div>
    <v-text-field
      dark
      v-model="parseSetting.github_branch"
      class=""
      autocomplete="off"
      id='github_branch'
      name='github_branch'
      filled
      dense
      hide-details
    />

    <div class="mt-4">Dashboard Password</div>
    <div class="my-2 note pl-2">Access your data using your Forge account email and this password. Please keep it secure.</div>
    <v-text-field
      dark
      v-model="parseSetting.dashboardPassword"
      class=""
      autocomplete="off"
      filled
      id="dashboard_password"
      name='dashboard_password'
      :type="passType"
      dense
      hide-details
    >
      <template v-slot:append>
        <v-btn
          text
          @click="hide_pass = !hide_pass"
          small
          class="mb-2"
          color="orange"
        >
          {{passLabel}}
        </v-btn>
      </template>
    </v-text-field>

    <div class="mt-4"> Environments </div>
    <OptionsList
      class="mt-2"
      :appsTab="true"
      :options="environments"
      name-label="KEY"
      value-label="VALUE"
    />

    <v-switch
      color="orange"
      class="mt-4"
      v-model="new_activated_value"
      dark
      dense
      hide-details
      inset
    >
      <template v-slot:label>
        <span style="margin-bottom: 2px"> {{parseSetting.activated ? 'Activated' : 'Deactivated'}}</span>
      </template>
    </v-switch>

    <v-btn
      @click="save"
      class="mt-7 mb-4"
      color="orange"
      dark
      :disabled="btnDisabled"
    >
      Save changes
    </v-btn>

    <v-btn
      v-if="parseSetting.activated"
      :disabled="btnDisabled"
      @click="redeploy"
      class="mt-7 ml-4 mb-4"
      color="orange"
      dark
    >
      Redeploy
    </v-btn>
    <v-btn 
      v-if='dashBoardEnabled' 
      dark
      color="orange"
      class="mt-7 ml-4 mb-4"
      :disabled="btnDisabled"
      @click="showServiceLogs"
    >
      Show service Logs
    </v-btn>
    
  </div>
</template>

<script>
  import _ from 'lodash';
  import {MODAL_FORGE_CONSOLE} from "@/components/Modals";
  import OptionsList from "@/components/elements/OptionsList";
  export default {
    name: "SiteAppParseServer",
    components: {OptionsList},
    data() {
      return {
        parseSetting: null,
        loading: true,
        hide_pass: true,
        saving: false,
        new_activated_value: false
      }
    },
    computed: {
      passLabel() {
        return this.hide_pass == true ? 'Show' : 'Hide';
      },
      passType() {
        return this.hide_pass == true ? 'password' : 'text';
      },
      dashBoardEnabled() {
        return this.parseSetting.deployed;
      },
      btnDisabled() {
        return this.saving || this.parseSetting.deploying || this.parseSetting.redeploy;
      },
      environments() {
        if (this.$store.state.site_app.parseSetting != null) {
          return this.$store.state.site_app.parseSetting.environments;
        }
        else
          return {};
      },
      cloudEnabled() {
        if (this.$store.state.site_app.parseSetting != null) {
          return this.$store.state.site_app.parseSetting.cloud_code_enabled;
        }
        else
          return false;
      },
      serviceLogMessages() {
        if (this.$store.state.site_app.parseSetting != null) {
          return this.$store.state.site_app.parseSetting.messages;
        }
        else
          return [];
      }
    },
    watch: {
      "$store.state.site_app.parseSetting": {
        handler (newVal, oldVal) {
          this.parseSetting = _.cloneDeep(newVal);
          this.new_activated_value = this.parseSetting.activated;
          this.loading = false;
        },
        deep: true
      }
    },
    methods: {
      save() {

        this.saving = true;
        let parse_params = {
          id: this.parseSetting.site_app_id,
          site_app: {
            new_activated_value: this.new_activated_value,
            site_id: this.$store.state.sites.active.id,
            all_app_id: 1
          }
        }
        parse_params["site_app"] = Object.assign(parse_params["site_app"], this.parseSetting.getServerObject());
        this.$store.dispatch('site_app/update',(parse_params))
          .then(() => {
            this.parseSetting = _.cloneDeep(this.$store.state.site_app.parseSetting);
            this.new_activated_value = this.parseSetting.activated;
            this.saving = false;
          })
          .catch(e => {
            console.log(e);
            this.saving = false;
          })
      },
      async copySSH() {
        try {
          await navigator.clipboard.writeText(this.parseSetting.parse_c66_ssh_key);
        } catch(e) {
          console.log('Cannot copy');
        }
      },
      redeploy() {
        this.saving = true;
        let parse_params = {
          id: this.parseSetting.site_app_id,
          site_app: {
            app_redeploy: true,
            site_id: this.$store.state.sites.active.id,
            all_app_id: 1
          }
        }
        this.$store.dispatch('site_app/update',(parse_params))
          .then(() => {
            this.parseSetting = _.cloneDeep(this.$store.state.site_app.parseSetting);
            this.saving = false;
          })
          .catch(e => {
            console.log(e);
            this.saving = false;
          })
      },
      OpenDashboard() {
        window.open(this.parseSetting.parse_dashboard_url,'_blank');
      },
      copyChiselUrl() {
        if (this.$store.state.site_app.chiselApp != null && this.$store.state.site_app.chiselApp.url.length != 0)
          this.parseSetting.chiselUrl = `https://${this.$store.state.site_app.chiselApp.url}`;
      },
      showServiceLogs() {
        this.$store.commit("site_app/setServiceMessages", {message: [], id: this.parseSetting.appId});
        this.$store.dispatch('site_app/checkParseLogs', this.parseSetting.site_app_id )
        this.$store.commit('application/openModal', {
          component: MODAL_FORGE_CONSOLE,
          props: {
            app: true,
            appType: 1 
          }});
      }
    },
    mounted() {
      if (this.$store.state.site_app.parseSetting != null)
      {
        this.parseSetting = _.cloneDeep(this.$store.state.site_app.parseSetting);
        this.new_activated_value = this.parseSetting.activated;
        this.loading = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
 .narrow-container {
    // padding: 42px 24px 24px;
    max-width: 800px;
    margin: auto;
 }
 .note {
    background: #fcf4d4;
    color: #9b7406;
    border: 1px solid #f7e9ab;
    border-radius: 3px;
    font-weight: 500;
  }
</style>