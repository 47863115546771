export class PublisherInfo {
  id = null;
  parse_server_url = null;
  parse_server_app_id = null;
  parse_server_site_id = null;
  site_id = null;
  role = null;
  organisation_id = null;
  publisher_name = null;
  publisher_sdk_url = null;
  logo_url = null;

  constructor(origin) {
    if (!origin)
      return;

    this.id = origin.id;
    this.parse_server_url = origin.parse_server_url;
    this.parse_server_app_id = origin.parse_server_app_id;
    this.parse_server_site_id = origin.parse_server_site_id;
    this.site_id = origin.site_id;
    this.role = origin.role;
    this.organisation_id = origin.organisation_id;
    this.publisher_name = origin.publisher_name;
    this.publisher_sdk_url = origin.publisher_sdk_url;
    this.logo_url = origin.logo_url;
  }
}
