import { Comment } from '@/models/Comment';
import { Comment as CommentResource } from "@/resources/forge";
import _ from "lodash";

const store = {
  namespaced: true,
  state: {
    list: [],
    activeVersion: 0
  },
  mutations: {
    /**
     * Sets the list of comments in the store state.
     * @param {Array} list - The list of comments to set.
     */
    setList(state, list) {
      state.list = list;
    },
    /**
     * Deletes a comment from the list in the store state.
     * @param {number} id - The ID of the comment to delete.
     */
    delete(state, id) {
      state.list = state.list.filter(f => id != f.id);
    },
    /**
     * Creates a new comment and adds it to the beginning of the list in the store state.
     * @param {Object} comment - The comment object to create.
     */
    create(state, comment) {
      state.list = [comment, ...state.list];
    },
    /**
     * Sets the active version of the comment.
     * @param {number} version_id - The ID of the active version.
     */
    setActiveVersion(state, version_id) {
      state.activeVersion = version_id;
    },
    /**
     * Updates the properties of a comment in the store state.
     * @param {Object} params - The parameters to update the comment.
     */
    update(state, params) {
      const comment = state.list.find(c => c.id == params.id);
      if (!comment)
        return;

      Object.assign(comment, params);
    }
  },
  actions: {
    /**
     * Loads comments from the server and sets them in the store state.
     * @param {Object} load_params - The parameters to load comments.
     * @returns {Object} The response from the server.
     */
    async load({ commit }, load_params) {
      const res = await CommentResource.get(load_params);
      const commentsArray = res.body.comments;
      commit('setList', commentsArray.map(c => new Comment(c)));
      return res;
    },
    /**
     * Creates a new comment and adds it to the store state.
     * @param {Object} params - The parameters to create the comment.
     */
    create({ commit }, params) {
      CommentResource.create({ comment: params })
        .then((res) => {
          commit('create', new Comment(res.body.comment));
        })
        .catch(e => {
          console.log(e);
        });
    },
    /**
     * Updates the view state of a comment.
     * @param {number} id - The ID of the comment to update.
     */
    updateViewState({ commit, state }, id) {
      let comment = _.cloneDeep(state.list.find(c => c.id == id));
      if (!comment)
        return;
      comment.viewed = true;
      commit('update', comment);
      CommentResource.updateViewState({ id: comment.id }, {});
    },
    /**
     * Deletes a comment from the store state.
     * @param {number} id - The ID of the comment to delete.
     */
    delete({ commit }, id) {
      CommentResource.destroy({ id: id })
        .then(() => {
          commit("delete", id);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * Handles the addition of a new comment to the store state.
     * @param {Object} comment - The newly added comment.
     */
    commentAdded({ commit, state }, comment) {
      if (state.activeVersion == 'all' || comment.version_id == state.activeVersion) {
        let com = _.cloneDeep(state.list.find(c => c.id == comment.id));
        if (com)
          commit('update', new Comment(comment));
        else
          commit('create', new Comment(comment));
      }
    }
  },
  getters: {
    /**
     * Finds a comment by its ID.
     * @param {String} id -  Target comment id
     * @returns {Object|null} The found comment or null if not found.
     */
    findById: state => id => {
      return state.list.find(c => c.id == id);
    }
  }
};

export default store;
