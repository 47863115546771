
export const SITE_MODE_PRODUCTION = 'production';
export const SITE_MODE_DEVELOPMENT = 'development';
export const SITE_MODE_STAGING = 'staging';

export const COMPILER_DISABLED = "nothing";
export const COMPILER_HAMMER = "hammer";
export const COMPILER_JEKYLL = "jekyll";
export const COMPILER_WEBPACK = "webpack";
export const COMPILER_MIDDLEMAN = "middleman";

export class Site {
  id = null;
  url = null;
  image = null;
  token = null;
  thumb_image_url = null;

  publisherInfo = {};

  projectId = null;
  site_name = "";

  currentAccountId = null;
  currentDeploySlug = null;
  currentVersionId = null;
  currentVersionStatus = 'deploying';
  currentVersionScopedId = 0;
  currentVersionDate = null;

  deployCount = 0;
  deployToken = null;

  dropboxAutodeploy = false;
  dropboxCursor = null;
  dropboxPath = null;

  environment = {};
  environments = {};

  githubAutodeploy = false;
  githubBranch = null;
  githubPath = null;
  githubWebhookId = null;

  bitbucketPath = null;

  sslEnabled = false;
  use_ssl = false;
  forceSSLEnabled = false;

  updatedAt = null;

  userId = null;
  usersPlan = null;
  buildCommand = null;
  buildFolder = null;
  userIds = [];

  versionIds = [];

  webhookTriggerIds = [];
  webhookTriggers = [];

  appAccess = false;
  compiler = COMPILER_DISABLED;
  squish = false;
  phishing = false;
  forceDeploy = null;
  kind = 0;
  mode = 'production';
  parent_site_id = null;


  bandwidth = {
    today: 0,
    week: 0,
    month: 0,
    thisMonth: 0,
    previousMonths: []
  };

  apps = [];


  constructor(origin) {
    if (!origin)
      return;
    
    this.id = origin.id;
    this.url = origin.url;
    this.image = origin.favicon_url;
    this.thumb_image_url = origin.thumb_image_url;
    this.token = origin.site_token;
    this.buildCommand = origin.build_command;
    this.buildFolder = origin.build_folder;
    this.site_name = origin.site_name;

    this.projectId = origin.project_id;

    this.publisherInfo = origin.publisher_info;

    this.currentAccountId = origin.current_account_id;
    this.currentDeploySlug = origin.current_deploy_slug;
    this.currentVersionId = origin.current_version_id;
    this.currentVersionDate = new Date(origin.current_version_date);
    this.currentVersionStatus = origin.current_version_status;
    this.currentVersionScopedId = origin.current_version_scoped_id;

    this.deployCount = origin.deploy_count;
    this.deployToken = origin.deploy_token;

    this.dropboxAutodeploy = origin.dropbox_autodeploy;
    this.dropboxCursor = origin.dropbox_cursor;
    this.dropboxPath = origin.dropbox_path;

    this.environment = origin.environment;
    this.environments = origin.environments;

    if (this.environment == null)
      this.environment = [];
    if (this.environments == null)
      this.environments = [];

    this.githubAutodeploy = origin.github_autodeploy;
    this.githubBranch = origin.github_branch;
    this.githubPath = origin.github_path;
    this.githubWebhookId = origin.github_webhook_id;

    this.bitbucketPath = origin.bitbucket_path;
    this.bitbucketBranch = origin.bitbucket_branch;
    this.bitbucketAutodeploy = origin.bitbucket_autodeploy;

    this.sslEnabled = origin.ssl_enabled;
    this.use_ssl = origin.use_ssl;
    this.forceSSLEnabled = origin.force_ssl_enabled;

    this.updatedAt = origin.updated_at;

    this.userId = origin.user_id;
    this.usersPlan = origin.users_plan;
    this.userIds = origin.site_user_ids;

    this.versionIds = origin.version_ids ? origin.version_ids : [];

    this.webhookTriggerIds = origin.webhook_trigger_ids;
    this.webhookTriggers = [];

    for (let webhook_trigger of origin.webhook_triggers){
      webhook_trigger.updated_at = new Date(webhook_trigger.updated_at).toTimeString();
      webhook_trigger.event = webhook_trigger.event.replaceAll("_", " ").replace("deploy", "deployment");
      webhook_trigger.http_method = webhook_trigger.http_method.toUpperCase();
      this.webhookTriggers.push(webhook_trigger);
    }
    
    this.appAccess = origin.app_access;
    this.compiler = origin.compiler ? origin.compiler : COMPILER_DISABLED;
    this.squish = origin.squish;
    this.phishing = origin.phishing;
    this.forceDeploy = origin.force_deploy;
    this.kind = origin.kind;
    this.mode = origin.mode;
    this.parent_site_id = origin.parent_site_id;

    if (origin.bandwidth) {
      this.bandwidth.today = origin.bandwidth.today;
      this.bandwidth.week = origin.bandwidth.week;
      this.bandwidth.month = origin.bandwidth.month;
      this.bandwidth.thisMonth = origin.bandwidth.this_month;

      if (origin.bandwidth.previous_months) {
        for (let month of origin.bandwidth.previous_months) {
          this.bandwidth.previousMonths.push(new BandwidthMonth(month));
        }
      }
    }
  }

  getServerObject() {
    const origin = {
      url: this.url,
      image: this.image,
      thumb_image_url: this.thumb_image_url,
      site_token: this.token,

      project_id: this.projectId,
      site_name: this.site_name,

      current_account_id: this.currentAccountId,
      current_deploy_slug: this.currentDeploySlug,
      current_version_id: this.currentVersionId,

      deploy_count: this.deployCount,
      deploy_token: this.deployToken,

      dropbox_autodeploy: this.dropboxAutodeploy,
      dropbox_cursor: this.dropboxCursor,
      dropbox_path: this.dropboxPath,

      environment: this.environment,
      environments: this.environments,

      github_autodeploy: this.githubAutodeploy,
      github_branch: this.githubBranch,
      github_path: this.githubPath,
      github_webhook_id: this.githubWebhookId,

      ssl_enabled: this.sslEnabled,
      use_ssl: this.use_ssl,
      force_ssl_enabled: this.forceSSLEnabled,

      updated_at: this.updatedAt,

      user_id: this.userId,
      users_plan: this.usersPlan,
      site_user_ids: this.userIds,

      version_ids: this.versionIds,

      webhook_trigger_ids: this.webhookTriggerIds,
      webhook_triggers: this.webhookTriggers,

      app_access: this.appAccess,
      compiler: this.compiler,
      build_command: this.buildCommand,
      build_folder: this.buildFolder,
      squish: this.squish,
      phishing: this.phishing,
      force_deploy: this.forceDeploy,
      kind: this.kind,


      bandwidth: {
        today: this.bandwidth.today,
        week: this.bandwidth.week,
        month: this.bandwidth.month,
        this_month: this.bandwidth.thisMonth,
        previous_months: []
      },

      apps: []
    }

    for (let month of this.bandwidth.previousMonths) {
      origin.bandwidth.previous_months.push(month.getServerObject());
    }

    for (let app of this.apps) {
      origin.apps.push(app.getServerObject());
    }

    return origin;
  }
}


export class BandwidthMonth {
  month = "";
  bandwidth = 0;


  constructor(origin) {
    if (!origin)
      return;

    this.month = origin.month;
    this.bandwidth = origin.bandwidth;
  }

  getServerObject() {
    return {
      month: this.month,
      bandwidth: this.bandwidth
    }
  }
}
