<template>
  <div class="SiteApp">
    <div class="image-wrapper">
      <img :src="app.image" class="image">
      <div class="forge-native-tag">
        <the-icon icon="logo-small" width="16" height="16" class="mr-1" />
        Forge app
      </div>
    </div>
    <div class="pa-5">
      <div class="d-flex justify-space-between items-center">
        <div class="app-name">
          {{ app.name }}
        </div>
        <v-menu offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text icon compact v-on="on">
              <the-icon icon="more-vert" />
            </v-btn>
          </template>
          <v-list dark class="the-app-bar__dropdown">
            <v-list-item>
              <v-list-item-title class="SiteApp__action">
                <span class="SiteApp__icon">
                  <v-icon>exit_to_app</v-icon>
                </span>
                Documentation
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="productSite">
              <v-list-item-title class="SiteApp__action">
                <span class="SiteApp__icon">
                  <v-icon>exit_to_app</v-icon>
                </span>
                Visit Product Site
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="SiteApp__action">
                <span class="SiteApp__icon">
                  <v-icon>help</v-icon>
                </span>
                Get Support
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="app.installed">
              <div @click="remove" class="SiteApp__action dangerous">
                <span class="SiteApp__icon">
                  <the-icon icon="trash" />
                </span>
                <span>Uninstall App</span>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="mt-3 app-tag-wrapper">
        <span class="tag" v-for="categoryName in categoryNames">{{categoryName}}</span>
        <div class="app_type"> {{ app.serviceType }} </div>
      </div>
      <div v-html="app.description" class="app-description my-5"></div>
      <div class="d-flex justify-space-between align-center">
        <v-btn @click="install" large dark color="y1">
          <the-icon class="mr-2" color="y1" icon="download" />
          Install
        </v-btn>
        <div class="d-flex align-center">
          <the-icon class="mr-2" color="y1" icon="download" width="16" height="16" />
          {{ app.installCount}}
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import {createApp} from "@/utils/data";
import {MODAL_ALERT} from "@/components/Modals";

export default {
  name: "SiteAppsAppAvailable",
  props: {
    app: Object
  },
  computed: {
    categoryNames() {
      return []
    }
  },
  methods: {
    productSite() {
      if (this.app.appData.id == '2')
        window.open('https://chiselcms.com/index.html', '_blank');
    },
    install() {
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'App installation',
          text: `You are going to install ${this.app.name}. Are you sure?`,
          actionBtnLabel: 'Install',
          showCancelBtn: true,
          action: () => {
            this.$store.dispatch(
              "site_app/installApp",
              {
                all_app_id: this.app.id,
                site_id: this.activeSite.id,
                activated: false
              }
            )
            .then(() => {
              this.$emit('app-changed');
            })
          }
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
  $style: SiteApp;
  .#{$style} {
    border: 1px solid $N6;
    border-radius: 8px;
    background: $N7;
    .app-name {
      font-size: 16px;
    }
  }
  .#{$style}__icon {
    width: 30px;
    text-align: center;
    .v-icon.v-icon {
      font-size: 20px;
    }
  }
  .#{$style}__action {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    &.dangerous {
      color: $Y1;  
    }
  }
  .image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    aspect-ratio: 16/7;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  span.tag {
    padding: 5px 8px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 6px;
    margin-right: 8px;
    background: $N5;
  }
  .image-wrapper {
    position: relative;
    margin-bottom: 5px;
  }
  .forge-native-tag {
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 6px;
    padding: 4px 6px;
    background: $B5;
    display: flex;
    align-items: center;
  }
</style>