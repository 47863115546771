
// initial state
// elements: [{ type, domID, elements}]
const state = {
  elements: [],
  isSettingsFormOpen: false,
  editingElement: null,
  addDialogVisible: false,
  addDialogCaller: null,
  isInPreview: false
}

export default {
  namespaced: true,
  state,
  getters: {
    /**
     * Retrieves the form elements from the state.
     * @returns {Array} The form elements.
     */
    formElements: (state) => {
      return state.elements;
    },
    /**
     * Retrieves the IDs of the form elements from the state.
     * @returns {Array} The IDs of the form elements.
     */
    elementIDs: (state) => {
      return state.elements.map(({id}) => id);
    }
  },
  actions: {
    /**
     * Dispatches the mutation to add a new element.
     * @param {Object} field - The new element to add.
     */
    addElement({commit}, field) {
      commit("addElement", field);
    },
    /**
     * Dispatches the mutation to delete an element.
     * @param {Object} payload - The payload containing the element to delete.
     */
    deleteElement({commit}, payload) {
      commit("deleteElement", payload);
    },
    /**
     * Dispatches the mutation to update an element.
     * @param {Object} payload - The payload containing the updated element.
     */
    updateElementOnly({commit}, payload) {
      commit("updateElementOnly", payload)
    },
    /**
     * Dispatches the mutation to update the form elements.
     * @param {Object|Array} payload - The payload containing the updated elements.
     */
    updateElements({commit}, payload) {
      commit("updateElements", payload);
    },
    /**
     * Dispatches the mutation to set the editing element.
     * @param {Object} payload - The payload containing the editing element.
     */
    setEditingElement({commit}, payload) {
      commit("setEditingElement", payload);
    },
    /**
     * Dispatches the mutation to set whether the settings form is open.
     * @param {boolean} payload - The payload indicating whether the settings form is open.
     */
    setSettingsFormOpen({commit}, payload) {
      commit("setSettingsFormOpen", payload);
    },
  },
  mutations: {
    /**
     * Adds a new element to the form elements array in the state.
     * @param {Object} field - The new element to add.
     */
    addElement(state, field) {
      state.elements.push(field)
    },
    /**
     * Deletes an element from the form elements array in the state.
     * @param {Object} payload - The payload containing the element to delete.
     */
    deleteElement(state, payload) {
      const elements = state.elements.filter(element => element.domID !== payload.domID)
      state.elements = elements;
    },
    /**
     * Updates an element in the form elements array in the state.
     * @param {Object} payload - The payload containing the updated element.
     */
    updateElementOnly(state, payload) {
      state.editingElement = payload;
      const elements = state.elements.map(instance => {
        if (instance.domID === payload.domID) return {...instance, ...payload}; else return instance;
      });
      state.elements = [...elements];
    },
    /**
     * Updates the form elements array in the state.
     * @param {Object|Array} payload - The payload containing the updated elements.
     */
    updateElements(state, payload) {
      if (payload instanceof Array)
        state.elements = payload;
      else
        state.elements = Object.keys(payload).map((key) => payload[key]);
    },
    /**
     * Sets the editing element in the state.
     * @param {Object} payload - The payload containing the editing element.
     */
    setEditingElement(state, payload) {
      state.editingElement = payload ? {...payload} : null;
    },
    /**
     * Sets whether the settings form is open or closed.
     * @param {boolean} payload - The payload indicating whether the settings form is open.
     */
    setSettingsFormOpen(state, payload) {
      state.isSettingsFormOpen = payload;
    },
    /**
     * Sets whether the form is in preview mode.
     * @param {boolean} payload - The payload indicating whether the form is in preview mode.
     */
    setIsInPreview(state, payload) {
      state.isInPreview = payload;
    },
  }
}
