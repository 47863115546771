// state
const initialApp = {
  id: null,
  Name: '',
  Slug: '',
  URL: '',
  Kind: ['Site']
};
const initialAppContent= {
  Short_Name: '',
  Description: '',
  Screenshots: [],
  Terms_URL: '',
  Privacy_URL: '',
  Categories: [],
  Filters: [],
  Listing: '',
  keyImage: '',
  Icon: ''
};
const initialAppData = {
  Data_Name: '',
  Capabilities: [],
  Fee_Type: '',
  Fee_Amount: '',
  Permissions: [],
  Sandbox_Permissions: [],
  Is_Paid_: false,
  Status: '', // This status field is for internal use only, not submitted as the value here. When submitting, we hard code this value 
  installParams: '',
};
const initialDeveloper = {
  Name: '',
  Company: '',
  Website: '',
  Email: '',
  Country: '',
  IsActive: true,
  Verified: false
};


const state = {
  app: initialApp,
  appContent: initialAppContent,
  appData: initialAppData,
  developer: initialDeveloper,  
  drawerOpened: false,
  settingsDrawerOpened: false,
}

// getters
const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions: {
    /**
     * Sets the app object in the state.
     * @param {Object} app - The new app object.
     */
    setApp({commit}, app) {
      commit('setApp', app);
    },
    /**
     * Sets the appData object in the state.
     * @param {Object} appData - The new appData object.
     */
    setAppData({commit}, appData) {
      commit('setAppData', appData);
    },
    /**
     * Sets the appContent object in the state.
     * @param {Object} appContent - The new appContent object.
     */
    setAppContent({commit}, appContent) {
       commit('setAppContent', appContent);
    },
    /**
     * Sets the developer object in the state.
     * @param {Object} developer - The new developer object.
     */
    setDeveloper({commit}, developer) {
      commit('setDeveloper', developer);
    },
    /**
     * Resets all state properties to their initial values.
     */
    reset({commit}) {
      commit('setApp', initialApp);
      commit('setAppContent', initialAppContent);
      commit('setAppData', initialAppData);
      commit('setDeveloper', initialDeveloper);
    }
  },
  mutations: {
    /**
     * Sets the value of drawerOpened in the state.
     * @param {boolean} value - The new value of drawerOpened.
     */
    setDrawerOpened(state, value) {
      state.drawerOpened = value;
    },
    /**
     * Sets the value of settingsDrawerOpened in the state.
     * @param {boolean} value - The new value of settingsDrawerOpened.
     */
    setSettingsDrawerOpened(state, value) {
      state.settingsDrawerOpened = value;
    },
    /**
     * Sets the app object in the state.
     * @param {Object} app - The new app object.
     */
    setApp(state, app) {
      state.app = app;
    },
    /**
     * Sets the appData object in the state.
     * @param {Object} appData - The new appData object.
     */
    setAppData(state, appData) {
      state.appData = appData;
    },
    /**
     * Sets the appContent object in the state.
     * @param {Object} appContent - The new appContent object.
     */
    setAppContent(state, appContent) {
      state.appContent = appContent;
    },
    /**
     * Sets the developer object in the state.
     * @param {Object} developer - The new developer object.
     */
    setDeveloper(state, developer) {
      state.developer = developer;
    },
    /**
     * Sets the keyImage property in the appContent object in the state.
     * @param {string} keyImage - The new value of the keyImage property.
     */
    setKeyImage(state, keyImage) {
      state.appContent = { ...state.appContent, keyImage };
    }
  }
}
