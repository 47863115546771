<template>
  <div class="mural-selector-container">
    <h5 class="subsection-header">All murals</h5>
    <v-row>
      <v-col v-for="(mural, index) in murals" :key="index" cols="12" sm="6" md="4" lg="3">
        <mural-card :mural="mural" :onMuralSelect="onMuralSelect" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MuralCard from './MuralCard';
export default {
  name: 'MuralList',
  props: {
    murals: Array,
    onMuralSelect: Function,
  },
  components: { MuralCard }
};
</script>

<style scoped>
.subsection-header {
  font-size: 18px;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 8px;
}
</style>
