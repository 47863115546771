<template>
  <div>
    <v-navigation-drawer
      class="PluginInstallParamsDrawer"
      :class="{
        settingsSidebar: true,
        isMobile: this.$vuetify.breakpoint.smAndDown
      }"
      v-model="drawer"
      :input-value="drawer"
      @input="onDrawerChange"
      fixed

      temporary
      height="100%"
      :bottom="isMobile"
      :right="!isMobile"
      :width="widthDimensions"
    >
      <PluginInstallParamsBuilder :categories="categories" />
    </v-navigation-drawer>
  </div>
</template>
<script>
  import Parse from 'parse';
  import {mapState, mapMutations} from 'vuex';
  import PluginInstallParamsBuilder from "./PluginInstallParamsBuilder";
  import { initParse } from '@/utils/parse';
  import { safeJSONParse } from "@/utils/common";

  export default {
    name: "PluginInstallParamsDrawer",
    components: {
      PluginInstallParamsBuilder
    },
    data() {
      return {
        drawer: false,
        categories: []
      };
    },

    computed: {
      ...mapState({
        editingElement: state => state.formBuilder.editingElement,
        isInPreview: state => state.formBuilder.isInPreview,
        settingsDrawerOpened: state => state.plugin_publishing.settingsDrawerOpened,
        appData: state => state.plugin_publishing.appData,
        publisher: state => state.publisher.activePublisher,
      }),
      isMobile() { // detect the size of the screen, useful for showing navigation drawer bottom on Mobile
        return this.$vuetify.breakpoint.smAndDown;
      },
      widthDimensions() {
        if (this.$vuetify.breakpoint.smAndDown) {
          return "100%";
        } else if( this.$vuetify.breakpoint.md ) {
          return "40%";
        } else {
          return "30%";
        }
      },
    },
    
    async mounted() {
      if (this.app) {
        const installParams = safeJSONParse(this.appData.installParams);
        this.$store.dispatch("formBuilder/updateElements", installParams || []);
      }

      if (this.publisher && this.publisher.parse_server_url && this.publisher.parse_server_app_id) {
        const { parse_server_url, parse_server_app_id } = this.publisher;
        initParse(parse_server_url, parse_server_app_id);
        await this.getCategories();
      }
    },
    methods: {
      ...mapMutations("plugin_publishing", ["setSettingsDrawerOpened"]),
      onDrawerChange(val) {
        this.setSettingsDrawerOpened(val)
      },
      // - Get Categories on initialization
      async getCategories() {
        const res = await Parse.Cloud.run('getCategories', { parseServerSiteId: this.publisher?.parse_server_site_id });
        if (res.categories) {
          this.categories = res.categories.map(category => ({
            text: category.name,
            value: category.id
          }));
        }
      },
    },
    watch: {
      settingsDrawerOpened: function (newVal) {
        this.drawer = !!this.settingsDrawerOpened
      },
      publisher: {
        async handler(newVal) {
          if (this.publisher && this.publisher.parse_server_url && this.publisher.parse_server_app_id) {
            const { parse_server_url, parse_server_app_id } = this.publisher;
            initParse(parse_server_url, parse_server_app_id);
            await this.getCategories();
          }
        },
        deep: true
      },
      appData: function(newVal) {
        const installParams = safeJSONParse(this.appData.installParams);
        this.$store.dispatch("formBuilder/updateElements", installParams || []);
      }

    }
  };
</script>
<style scoped lang="scss">
  $style: PluginInstallParamsDrawer;
  .#{$style} {
    background: $N5;
  }
</style>

<style>
  .app-builder .panel-body {
    width: 100%;
    padding: 1rem;
  }
  .settingsSidebar footer {
    border-color: #eee !important;
  }
</style>