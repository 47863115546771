<template>
  <div class="narrow-container mx-2">
    <div class="d-flex flex-column flex-sm-row gap-3 align-sm-center justify-space-between pa-0 px-4 mt-4 w-full">
      <v-autocomplete
        v-model="selectedItem"
        :items="suggestionApps"
        :search-input.sync="search"
        :loading="autocompleteLoading"
        filled
        dark
        dense
        clearable
        hide-no-data
        hide-details
        prepend-inner-icon="search"
        placeholder="Search by plugin name"
        class="pa-0 flex-grow-0 search-input"
        return-object
        item-text="name"
        item-value="id"
        @change="onChange"
      ></v-autocomplete>

      <div class="d-flex align-center">
        <v-select
          dark
          dense
          filled
          clearable
          hide-details
          class="status-filter"
          v-model="filter.status"
          :items="statuses"
          placeholder="Status"
          @change="getReviewsList"
        >
        </v-select>
      </div>
    </div>
    <div class="mt-6 mx-4" v-if="!loading">
      <v-data-table 
        :headers="headers"
        :items="reviewList"
        class="data-table"
        sort-by="status"
        :sort-desc="false"
        :custom-sort="customSort"
        show-expand
      >
        <template v-slot:item.plugin="{item}">
          <router-link :to="{ name: 'plugin_detail', params: { pluginId: item.appObject.slug } }">
            {{ item.appObject.name }}
          </router-link>
        </template>
        <template v-slot:item.comment="{item}">
          <div v-html="item.comment" class="review-comment"></div>
        </template>
        <template v-slot:item.author="{item}">
          <div>{{ item.authorName }} <br/> {{ item.authorEmail }} </div>
        </template>
        <template v-slot:item.rating="{item}">
          <v-rating v-if="item.rating"
            v-model="item.rating"
            background-color="red lighten-3"
            color="red"
            small dense
          ></v-rating>
        </template>
        <template v-slot:item.status="{item}">
          <review-status-chip :status="item.status" :dataId="item.id" @updateStatus="onUpdateStatus"/>
        </template>
        <template v-slot:item.updatedAt="{item}">
          {{ formatDate(item.updatedAt) }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div v-html="item.comment"></div>
          </td>
        </template>
        
      </v-data-table>
    </div>
    <div v-else class="spinner">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="100"
        :color="'#496DDB'"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import dayjs from "dayjs";
import { MODAL_STATUS_CHANGE_CONFIRMATION } from "@/components/Modals";
import { IntersectingCirclesSpinner } from "epic-spinners";
import ReviewStatusChip from "./ReviewStatusChip";
const Parse = require('parse');

export default {
  name: "ReviewList",
  components: {
    ReviewStatusChip,
    IntersectingCirclesSpinner
  },
  data() {
    return {
      search: '',
      loading: false,
      columns: ['plugin', 'author', 'comment', 'rating', 'status', 'updatedAt'],
      columnOptions: [
        { text: 'Plugin', value: 'plugin', sortable: false },
        { text: 'Author', value: 'author', sortable: false },
        { text: 'Comment', value: 'comment', sortable: false },
        { text: 'Rating', value: 'rating' },
        { text: 'Status', value: 'status' },
        { text: 'Updated At', value: 'updatedAt' },
      ],
      filter: {
        appId: '',
        status: ''
      },
      statuses: [
        "Waiting Review",
        "Declined",
        "Approved"
      ],
      reviewList: [],
      wholeReviewList: [],
      selectedItem: null,
      suggestionApps: [], // your list of items
      autocompleteLoading: false
    }
  },
  computed: {
    ...mapState({
      currentPublisherInfo: ({ publisher_infos }) => publisher_infos.current
    }),
    // Which columns are to be displayed on the table based on selected columns filter
    // Header rowspan applied are handled by header details
    headers() { 
      const { columns, columnOptions } = this;
      const options = columnOptions.reduce((acc, cur) => cur.children ? [...acc, ...cur.children] : [...acc, cur], [])
      return options.filter(option => columns.indexOf(option.value) !== -1);
    },
  },
  async mounted() {
    Parse.initialize(this.currentPublisherInfo.parse_server_app_id);
    Parse.serverURL = this.currentPublisherInfo.parse_server_url;
    await this.getReviewsList();
  },
  methods: {
    async getReviewsList() {
      this.loading = true;
      try {
        const res = await Parse.Cloud.run('getReviewsList', { filter: this.filter, parseServerSiteId: this.currentPublisherInfo.parse_server_site_id });
        this.reviewList = res.reviews;
        this.wholeReviewList = res.reviews
      } catch(error) {
        console.error('Error in getReviewList', error);
      }
      this.loading = false;
    },

    onUpdateStatus(params) {
      this.$store.commit('application/openModal', {
        component: MODAL_STATUS_CHANGE_CONFIRMATION,
        props: {
          title: "Are you sure you want to change status?" ,
          showCancelBtn: true,
          actionBtnLabel: "Confirm",
          action: () => {
            this.updateReviewStatus(params);
          }
        }
      });
    },
    // Listener of Status Chip component update status
    async updateReviewStatus(params) {
      const { dataId, status } = params;
      this.loading = true;
      // Update Review status on Forge Ignite
      try {
        await Parse.Cloud.run('updateReview', { id: dataId, data: { Status: status }, parseServerSiteId: this.currentPublisherInfo.parse_server_site_id  });
      } catch(error) {
        console.error('Error while Updating Review', error);
        this.loading = false;
        return;
      }
      // Smart Update instead of fetching review list again
      const reviewIndex = this.reviewList.findIndex(review => review.id === dataId);
      if (reviewIndex !== -1) {
        let review = this.reviewList[reviewIndex];
        review = { ...review, status };
        Vue.set(this.reviewList, reviewIndex, review)
      }
      this.loading = false;
    },

    async updateStatusFilter(params) {
      const { status } = params;
      this.filter.status = status;
      await this.getReviewList();
    },
    // Autocomplete item select event handler
    async onChange() {
      this.filter.appId = this.selectedItem?.id || '';
      await this.getReviewsList();
    },
    customSort(items, sortFields, sortDesc) {
      return items.sort((a, b) => {
        for (let i = 0; i < sortFields.length; i++) {
          const result = this.customSortByOneField(sortFields[i], a, b)
          if (result !== 0) return sortDesc[i] ? result : -result;
        }
        return 0;
      });
    },
    customSortByOneField(sortField, a, b) {
      if (sortField === 'rating') return this.ratingSort(a, b);
      if (sortField === 'status') return this.statusSort(a, b);
      if (sortField === 'updatedAt') return this.updatedAtSort(a, b);
      const valueA = a[sortField].toLowerCase(), valueB = b[sortField].toLowerCase();
      if (valueA === valueB) {
        return 0;
      } else if (valueA > valueB) {
        return -1;
      } else {
        return 1;
      }
    },
    ratingSort(a, b) {
      const firstRating = a.rating || -1;
      const secondRating = b.rating || -1;
      if (firstRating === secondRating) return 0;
      return secondRating > firstRating ? 1: -1;
    },
    statusSort(a, b) {
      const firstStatusIndex = this.statuses.findIndex(status => status === a.status);
      const secondStatusIndex = this.statuses.findIndex(status => status === b.status);
      if (firstStatusIndex === secondStatusIndex) return 0;
      return secondStatusIndex > firstStatusIndex ? 1 : -1;    
    },
    updatedAtSort(a, b) {
      const firstUpdatedAt = dayjs(a.updatedAt);
      const secondUpdatedAt = dayjs(b.updatedAt);
      if (firstUpdatedAt === secondUpdatedAt) return 0;
      return secondUpdatedAt > firstUpdatedAt ? 1 : -1;
    },
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY hh:mm');
    }
  },
  watch: {
    async search () {
      if (this.autocompleteLoading || !this.search || this.search.length < 3) return

      this.autocompleteLoading = true
      const res = await Parse.Cloud.run('pluginAutocomplete', { search: this.search, parseServerSiteId: this.currentPublisherInfo.parse_server_site_id });
      this.suggestionApps = res?.apps || [];
      this.autocompleteLoading = false;
    },
  },
}
</script>

<style lang="scss">
.data-table {
  background: $N4 !important;
  border: 1px solid $N5;
  .review-comment {
    width: 150px;
    * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

</style>

<style lang="scss" scoped>
  .owner {
    background: orange;
    color: white;
    border-radius: 4px;
  }

  .remove {
    cursor: pointer;
    color: #E94A53;
    border: none;
  }
  .access-tab {
    background: #1c1b2e;
  }

  .avatar-placeholder-wrapper {
    height: 26px;
    width: 26px;
    border-radius: 100%;
    border: 1px solid #B3B3B3;
    overflow: hidden;

    .avatar-placeholder {
      height: 24px;
    }
  }

  $style: ReviewList;
  .#{$style} {
    &__filter-button {
      background: transparent !important;
      border: 1px solid $N3;
      span {
        margin-left: 10px;
        margin-right: 15px;
      }
    }
    &__icon-active {
      color: $N2;
      fill: $N2;
    }
    &__icon-inactive {
      color: $N3;
      fill: $N3;
      &.expanded {
        transform: rotate(-180deg);
      }
    }
    &__add-btn {
      background-color: $B1 !important;
    }
  }

  .dense-item {
    max-height: 40px;
  }
  .search-input {
    width: 344px;
  }
  .min-height-0 {
    min-height: 0px;
  }
  .parent-checkbox {
    max-height: 40px;
    margin-top: 6px;
  }
  th {
    padding: 10px;
    border-bottom: thin solid hsla(0,0%,100%,.12);
    &.parent {
      border-left: thin solid hsla(0,0%,100%,.12);
      border-right: thin solid hsla(0,0%,100%,.12);
    }
    &.child {
      border-left: thin solid hsla(0,0%,100%,.12);
      border-right: thin solid hsla(0,0%,100%,.12);
    }
  }  
  .approved {
    color: $G1;
    &:before {
      background: $G1;
    }
  }
  .waiting-review {
    color: $Y1;
    &:before {
      background: $Y1;
    }
  }
  .declined {
    color: $R2;
    &:before {
      background: $R2;
    }
  }
  .spinner {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
  }
  .developer-filter {
    border: 1px solid #5e636d;
    border-radius: 8px;
    width: 300px;
  }
  .status-filter {
    width: 200px;
  }
</style>