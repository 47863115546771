import posthog from "posthog-js";

export default {
  install(Vue) {
    posthog.init(process.env.VUE_APP_POSTHOG_KEY, {
      api_host: process.env.VUE_APP_POSTHOG_HOST || 'https://eu.i.posthog.com',
    });

    Vue.prototype.$posthog = posthog;
  },
};