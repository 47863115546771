import {Notification} from '@/models/Notification';
import {Notification as NotificationResource} from "@/resources/forge";

const store = {
  namespaced: true,
  state: {
    list: []
  },
  mutations: {
    /**
     * Sets the list of notifications in the store state.
     * @param {Array} list - The list of notifications.
     */
    setList (state, list) {
      state.list = list;
    },
    /**
     * Deletes a notification from the list based on its ID.
     * @param {string} id - The ID of the notification to delete.
     */
    delete(state, id) {
      state.list = state.list.filter(f => id != f.id);
    },
    /**
     * Adds a new notification to the list.
     * @param {Object} notification - The notification to add.
     */
    create(state, notification) {
      state.list = [...state.list, notification];
    },
    /**
     * Adds a new notification to the beginning of the list.
     * @param {Object} data - The data of the notification to add.
     */
    addNotification(state, data) {
      state.list = [new Notification(data), ...state.list];
    },
    /**
     * Updates an existing notification in the list.
     * @param {Object} params - The parameters to update the notification.
     */
    update(state, params) {
      const notification = state.list.find(n => n.id == params.id);
      if (!notification)
        return;

      Object.assign(notification, params);
    }
  },
  actions: {
    /**
     * Loads notifications from the API and sets them in the store state.
     * @param {boolean} load_all - Whether to load all notifications or not.
     */
    async load({commit}, load_all) {
      const res = await NotificationResource.get({all: load_all});
      const notificationsArray = res.body.notifications;
      commit('setList', notificationsArray.map(n => new Notification(n)));
      return res;
    },
    /**
     * Updates the view state of a notification.
     * @param {string} id - The ID of the notification to update.
     */
    updateViewState({commit, state}, id){
      let notification = _.cloneDeep(state.list.find(n => n.id == id));
      if (!notification)
        return;
      notification.viewed = !notification.viewed;
      commit('update', notification);
      NotificationResource.update({id: notification.id}, {notifications: {viewed: notification.viewed}})
    },
    /**
     * Deletes a notification from the API and the store state.
     * @param {string} id - The ID of the notification to delete.
     */
    delete({commit}, id) {
      NotificationResource.destroy({id: id})
        .then(() => {
          commit("delete", id);
        })
        .catch((err) => {
          console.log(err);
        }); 
    },
    /**
     * Sets all notifications to viewed in the API and updates the store state.
     */
    async setAllToView({commit}) {
      const res = await NotificationResource.setAllToView();
      const notificationsArray = res.body.notifications;
      commit('setList', notificationsArray.map(n => new Notification(n)));
      return res;
    }
  }
};

export default store;
