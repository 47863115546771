<template>
   <MemberAccess :loadingProp="loading" 
    :usersProp="users" 
    :invitedProp="invited"
    :memberAccessTypeProp="2"
    :changeOwnerEnabledProp="false"
  />
</template>

<script>
import MemberAccess from "@/components/_Common/MemberAccess";
export default {
  name: "ProjectAccess",
  components: { MemberAccess },
  data() {
    return {
      currentObjectId: this.$store.state.projects.active.id,
      loading: true,
      invited: [],
      users: [],
    }
  },
  mounted() {
    this.$store.dispatch('invitation/load', {type: 'project', type_id: this.currentObjectId });
    this.$store.dispatch('project_user/load', this.currentObjectId)
  },
  watch: {
    "$store.state.project_user.list": {
      handler (newVal, oldVal) {
        this.users = _.cloneDeep(newVal);
        this.loading = false;
      },
      deep: true
    },
    "$store.state.invitation.list": {
      handler (newVal, oldVal) {
        if (newVal[0] != undefined && newVal[0].type != 'Project')
          return;
        this.invited = _.cloneDeep(newVal);
        this.loading = false;
      },
      deep: true
    }
  },
}
</script>