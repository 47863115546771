import { Form } from '@/models/Form';
import { Form as FormResource } from "@/resources/forge";

const store = {
  namespaced: true,
  state: {
    list: [],
    currentForm: null,
    formData: []
  },
  mutations: {
    /**
     * Sets the list of forms in the store state.
     * @param {Array} list - The list of forms to set.
     */
    setList(state, list) {
      state.list = list;
    },
    /**
     * Deletes a form from the list in the store state.
     * @param {number} id - The ID of the form to delete.
     */
    delete(state, id) {
      state.list = state.list.filter(f => id != f.id);
    },
    /**
     * Creates a new form and adds it to the list in the store state.
     * @param {Object} form - The form object to create.
     */
    create(state, form) {
      state.list = [...state.list, form];
    },
    /**
     * Updates the properties of a form in the store state.
     * @param {Object} params - The parameters to update the form.
     */
    update(state, params) {
      const form = state.list.find(f => f.id == params.id);
      if (!form)
        return;

      Object.assign(form, params);
    },
    /**
     * Sets the current form in the store state.
     * @param {Object} form - The form object to set as current.
     */
    setCurrent(state, form) {
      state.currentForm = form;
    }
  },
  actions: {
    /**
     * Loads forms from the server and sets them in the store state.
     * @param {number} site_id - The ID of the site to load forms for.
     * @returns {Object} The response from the server.
     */
    async load({ commit }, site_id) {
      const res = await FormResource.get({ site_id: site_id });
      commit('setList',
        res.body.forms.map(f => new Form(f)));
      return res;
    },
    /**
     * Sets the current form in the store state.
     * @param {number} form_id - The ID of the form to set as current.
     * @returns {Promise} A promise that resolves with the response from the server.
     */
    setCurrent({ commit }, form_id) {
      return FormResource.show({ id: form_id })
        .then((res) => {
          commit('setCurrent', new Form(res.body.form));
        });
    },
    /**
     * Retrieves the policy for downloading a form.
     * @param {Object} params - The parameters for retrieving the policy.
     * @returns {Promise} A promise that resolves with the policy from the server.
     */
    getPolicy({}, params) {
      return FormResource.policy({ form_id: params.form_id, file_name: params.file_name });
    },
    /**
     * Updates a form on the server and sets the current form in the store state.
     * @param {Object} params - The parameters for updating the form.
     * @returns {Promise} A promise that resolves with the response from the server.
     */
    update({ commit }, params) {
      return FormResource.update({ id: params.form_id }, { form: params.form })
        .then((res) => {
          commit('setCurrent', new Form(res.body.form));
        });
    },
    /**
     * Downloads a CSV file for a form.
     * @param {number} form_id - The ID of the form to download CSV for.
     * @returns {Object} The response from the server.
     */
    async downloadCsv({}, form_id) {
      const res = await FormResource.downloadCsv({ id: form_id });
      return res;
    },
    /**
     * Deletes a form from the server and removes it from the store state.
     * @param {number} id - The ID of the form to delete.
     */
    delete({ commit }, id) {
      FormResource.destroy({ id: id })
        .then(() => {
          commit("delete", id);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  getters: {
    /**
     * Finds a form by its ID.
     * @param {String} id -  Target form id
     * @returns {Object|null} The found form or null if not found.
     */
    findById: state => id => {
      return state.list.find(f => f.id == id);
    }
  }
};

export default store;
