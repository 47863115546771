<template>
  <div class="SiteVersionComment__commentContainer" :id="'comment_container_' + comment.id" @mouseover="changeViewedState" @mouseleave="removeActiveClass">
    <div class="SiteVersionComment__commentHeader">
      <div class="SiteVersionComment__commentProfile">
        <avatar
          v-if='avatarEmpty()'
          :rounded="true"
          class="SiteVersionComment__avatar"
          :initials="initials()"
          username="userName"
          :size="32"
        />
        <img class="SiteVersionComment__avatar" :src='comment.userAvatar' v-else />
    
        <div class="SiteVersionComment__profile_name" style="margin-left: 8px">{{ comment.userName }}</div>
        <small class="comments_timestamps"> {{comment.createdAt}} </small>
        <div class="comment_new" v-if="!comment.viewed"> </div>
        <v-icon class="comments_remove" v-if="siteOrCommentOwner" @click="deleteComment"> mdi-delete-outline </v-icon>
        <div class="version_scoped"> Version #{{comment.versionScopedId}} </div>
      </div>
    </div>
    <div class="SiteVersionComment__commentContents">
      <div>
        <div :ref="'commentRef' + comment.id" :class="{'show_all_text': showMore}" class="SiteVersionComment__commentMessage" :id="'comment_message_' + comment.id" v-html="comment.message"></div>
        <v-btn class="more_btn" plain color="y1" @click="showMore = !showMore" v-if="showMoreEnabled"> {{showMoreText}} </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import Avatar from 'vue-avatar';
  import {ref} from 'vue';
  export default {
    name: "SiteVersionComment",
    components: { Avatar },
    props: {
      comment: Object,
      version: Object,
    },
    data() {
      return {
        isMounted: false,
        showMore: false,
        showMoreEnabled: false
      }
    },
    computed: {
      setShowMoreEnabled() {
        if(!this.isMounted || this.$refs['commentRef' + this.comment.id] == undefined)
          return;
        const el = this.$refs['commentRef' + this.comment.id];
        this.showMoreEnabled = el.scrollHeight > 70;
      },
      showMoreText() {
        if (this.showMore)
          return "Show Less"
        else
          return 'Show All'
      },
      siteOrCommentOwner() {
        const currentUserId = this.$store.state.user.current.id;
        const objectOwner = this.comment.siteOwnerId;
        const commentOwner = this.comment.userId;
        return [objectOwner, commentOwner].includes(currentUserId);
      }
    },
    methods: {
      avatarEmpty() {
        return this.comment.userAvatar == null || this.comment.userAvatar.length == 0;
      },
      changeViewedState() {
        if (this.comment.viewed) 
          return;
        this.$store.dispatch('comments/updateViewState', this.comment.id)
      },
      initials() {
        this.comment.userName[0].toUpperCase();
      },
      deleteComment() {
        this.$store.dispatch('comments/delete', this.comment.id)
      },
      removeActiveClass() {
        const element = document.getElementById('comment_container_' + this.comment.id);
        if (!element)
          return;
        element.classList.remove('_active');
      }
    },
    mounted() {
      this.isMounted = true;
      this.setShowMoreEnabled;
    },
    updated() {
      this.isMounted = true;
      this.setShowMoreEnabled;
    }
  }
</script>

<style lang="scss" scoped>
  $style: SiteVersionComment;
  ::v-deep .mention {
    color: $Y1
  }

  ::v-deep .#{$style}__commentMessage p {
    margin-bottom: 0px;
  }

  .#{$style} {

    &__commentContainer {
      padding-top: 15px;
      padding-bottom: 16px;
      padding-left: 12px;
      padding-right: 12px;
      border-bottom: 1px solid $N7;
      width: 100%;
      align-self: flex-end;

      &:hover, &._active {
        border-radius: 8px;
        background: $N7;
      }

      .show_all_text {
        overflow: visible !important;
        max-height: unset !important;
      }

      .more_btn {
        margin-left: 24px;
        margin-top: 5px;
      }
      .comment_new {
        float: right;
        width: 10px;
        height: 10px;
        border-radius: 8px;
        background: red;
      }

      &:hover {
        .comments_remove {
          display: block;
          cursor: pointer;
          color: $N2;
        }
      }
    }

    &__avatar {
      display: inline-block !important;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      display: inline-block;
      vertical-align: middle;
    }

    &__commentMessage {
      margin-left: 41px;
      margin-top: 8px;
      color: $N2;
      max-height: 70px;
      overflow: hidden;
    }

    &__commentProfile {
      .comments_timestamps {
        color: $N2;
        margin-left: 10px;
        font-size: 12px;
      }
      .version_scoped {
        margin-left: 41px;
        color: $N8;
        font-size: 12px;
      }
      .comments_remove {
        display: none;
        float: right;
      }
    }

    &__profile_name {
      color: $N2;
      font-weight: 500;
      font-size: 14px;
      display: inline-block;
    }
  }

</style>