<template>
  <v-card dark class="ModalStatusChangeConfirmation">
    <div class="d-flex justify-end align-center">
      <v-btn icon text color="n3" @click="close">
        <v-icon>close</v-icon>
      </v-btn>
    </div>
    <div class="ModalStatusChangeConfirmation__body">
      <div class="text-center mb-4">
        <the-icon icon="warn" />
      </div>
      <h2 class="ModalStatusChangeConfirmation__title">{{ title }}</h2>
      <div v-html="text" class="ModalStatusChangeConfirmation__label"></div>
      <div class="ModalStatusChangeConfirmation__footer">
        <v-btn color="n5" v-if="showCancelBtn" @click="close">
          Cancel
        </v-btn>
        <v-btn
          @click="doAction"
          color="blue"
        >
          {{ actionBtnLabel }}
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ModalStatusChangeConfirmation",
  props: {
    title: String,
    text: String,
    actionBtnLabel: {
      type: String,
      default: "OK",
    },
    showCancelBtn: Boolean,
    action: Function,
    status: {
      type: String,
      default: "success"
    }
  },
  methods: {
    async doAction() {
      if (this.action){
        this.action();
        this.$emit('close');
      }
      else 
        this.$emit('close');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
$style: ModalStatusChangeConfirmation;
.#{$style} {
  width: 250px;
  text-align: center;
  &.theme--dark.v-card {
    @extend %modal-body-styles;
    min-width: 400px;
    padding: 24px 24px 0;
  }
  &__body {
    width: 250px;
    margin: 0 auto;
  }
  &__title {
    font-weight: 500;
    font-size: $H16;
    color: $white;
    margin-bottom: 16px;
  }
  &__label {
    @extend %label-styles;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    gap: 16px;
    & > .theme--dark.v-btn {
      color: $white;
    }
  }
}
</style>
