<template>
  <v-card class="ModalSiteHook" max-width="600px" dark>
    <v-card-title class="headline justify-center">
      Create Outgoing Hook
    </v-card-title>

    <v-card-text class="mt-6 text">
      <div>Method: </div>
      <v-select
          v-model="method"
          class="pa-0"
          :items="methods"
          item-text="label"
          item-value="value"
          return-object
          dense
          filled
          single-line
      ></v-select>

      <div>Event: </div>
      <v-select
          v-model="selectedEvent"
          class="pa-0"
          :items="events"
          item-text="label"
          item-value="value"
          return-object
          dense
          filled
          single-line
      ></v-select>

      <div>URL</div>
      <v-text-field
        v-model="url"
        class="pa-0 mt-1"
        placeholder="Enter Webhook Url"
        hide-details
        filled
        dense
      />

      <div class="mt-5"> Parameters </div>
      <v-textarea
          filled
          v-model="parameters"
          placeholder="
          key = value format e.g.
          text = site deployed successfully"
        ></v-textarea>

    </v-card-text>

    <v-card-actions class="mx-auto pa-6 justify-center">
      <v-btn @click="onCreate" color="y1">{{actionType}}</v-btn>
      <v-btn @click="close" color="n5" class="ml-7">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: "ModalSiteHook",
    props: {
      webhook: Object
    },
    data() {
      let webhookValue = this.webhook == undefined ? 'POST' : this.webhook.http_method;
      let eventLabel = this.webhook == undefined ? 'Deployment Success' : this.webhook.event;
      let eventValue = this.webhook == undefined ? 'deploy_success' : this.webhook.event.replaceAll(" ", '_').toLowerCase();
      if (eventValue.startsWith("deployment"))
        eventValue = eventValue.replace("deployment", "deploy")
      return {
        events: [
          {
            label: 'Deployment Success',
            value: 'deploy_success'
          },
          {
            label: 'Deployment Failure',
            value: 'deploy_failure',
          },
          {
            label: 'Form submission',
            value: 'form_submission'
          },
          {
            label: 'Plugin analysis complete',
            value: 'plugin_analysis_complete'
          }],
          methods: [
          {
            label: "POST",
            value: "POST"
          }, 
          {
            label: "GET",
            value: "GET"
          }],
          method: {label: webhookValue, value: webhookValue},
          selectedEvent: {label: eventLabel, value: eventValue},
          url: this.webhook == undefined ? "" : this.webhook.url,
          parameters: this.webhook == undefined ? "" : this.webhook.parameters,
          actionType: this.webhook == undefined ? 'Add New Hook' : 'Update Hook'
      }
    },
    created() {
    },
    methods: {
      onCreate() {
        let params = {
          event: this.selectedEvent.value, 
          http_method: this.method.value, 
          parameters: this.parameters, 
          url: this.url,
          site_id: this.activeSite.id
        }
        if (this.webhook == undefined)
          this.$store.dispatch('sites/addHook', params);
        else {
          params = {id: this.webhook.id, webhook_trigger: params};
          this.$store.dispatch('sites/updateHook', params, this.webhook.id);
        }
        this.$emit('close');
      },
      close() {
        this.$emit('close');
      }
    }
  }
</script>

<style lang="scss" scoped>
$style: ModalSiteHook;
.#{$style} {
  &.theme--dark.v-card {
    @extend %modal-body-styles;
    min-width: 600px;
    padding: 24px 24px 0;
  }
}
  .text {
    font-size: 13px;
  }
  .error-main {
    background: #F9D1D1;
    border-radius: 2px;
  }
</style>