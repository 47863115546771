<template>
  <v-card dark class="ModalAlert">
    <h2 class="ModalAlert__title">{{ title }}</h2>

    <div v-if="showSpiner" class="spinner">
      <IntersectingCirclesSpinner :animation-duration="1200" :size="100" :color="'#496DDB'" />
    </div>
    <div v-else>
      <div v-html="text" class="ModalAlert__label"></div>
      <v-form v-if="confirmString" @submit.prevent="doAction">
        <v-text-field
          v-model="confirmLocal"
          class="ModalAlert__input"
          autofocus
          hide-details
          filled
          dense
        />
      </v-form>
      <div class="ModalAlert__footer">
        <v-btn color="n5" v-if="showCancelBtn" @click="close">
          Cancel
        </v-btn>
        <v-btn
          @click="doAction"
          :disabled="disableAction"
          :color="confirmString && alertMessage ? 'r1' : 'y1'"
        >
          {{ actionBtnLabel }}
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import {IntersectingCirclesSpinner} from "epic-spinners";
export default {
  name: "ModalAlert",
  components: {IntersectingCirclesSpinner},
  props: {
    title: String,
    text: String,
    actionBtnLabel: {
      type: String,
      default: "OK",
    },
    showCancelBtn: Boolean,
    withErrCatch: {
      type: Boolean,
      default: false
    },
    action: Function,
    confirmString: String,
    alertMessage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      confirmLocal: "",
      showSpiner: false
    };
  },
  computed: {
    disableAction() {
      return this.confirmString && this.confirmLocal != this.confirmString;
    },
  },
  methods: {
    async doAction() {
      if (this.disableAction) return;

      if (this.action){
        this.showSpiner = true;
        let res = await this.action();
        setTimeout(() => this.$emit('close'), 2000);
      }
      else 
        this.$emit('close');
    },
    close() {
      this.showSpiner = false;
      if (this.withErrCatch)
        throw 'closed'
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
$style: ModalAlert;
.#{$style} {
  &.theme--dark.v-card {
    @extend %modal-body-styles;
    min-width: 400px;
    padding: 24px 24px 0;
    
    @media screen and (max-width: 600px) {
      min-width: 300px;
    }
  }
  &__title {
    font-weight: 500;
    font-size: $H16;
    color: $white;
    margin-bottom: 16px;
  }
  &__label {
    @extend %label-styles;
  }
  &__input {
    &.v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot {
      @extend %input-styles;
    }
    &.v-input input {
      color: $white;
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 0;
    gap: 16px;
    & > .theme--dark.v-btn {
      color: $white;
    }
  }
  .error-main {
    background: #f9d1d1;
    border-radius: 2px;
  }
  .spinner {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    margin-bottom: 15px;
    justify-content: center;
  }
}
</style>
