<template>
  <v-card dark class="ModalInformation">
    <div class="d-flex justify-space-between align-center mb-4">
      <v-btn icon color="white darken-3" class="btn-status">
        <v-icon v-if="status == 'success'">check_circle</v-icon>
        <v-icon v-else>warning</v-icon>
      </v-btn>
      <v-btn icon text color="n3" @click="close">
        <v-icon>close</v-icon>
      </v-btn>
    </div>
    <h2 class="ModalInformation__title">{{ title }}</h2>
    <div v-html="text" class="ModalInformation__label"></div>
    <div class="ModalInformation__footer">
      <v-btn color="n5" v-if="showCancelBtn" @click="close">
        Cancel
      </v-btn>
      <v-btn
        @click="doAction"
        color="y1"
      >
        {{ actionBtnLabel }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ModalInformation",
  props: {
    title: String,
    text: String,
    actionBtnLabel: {
      type: String,
      default: "OK",
    },
    showCancelBtn: Boolean,
    action: Function,
    status: {
      type: String,
      default: "success"
    }
  },
  methods: {
    async doAction() {
      if (this.action){
        this.action();
        this.$emit('close');
      }
      else 
        this.$emit('close');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
$style: ModalInformation;
.#{$style} {
  &.theme--dark.v-card {
    @extend %modal-body-styles;
    min-width: 400px;
    padding: 24px 24px 0;
  }
  &__title {
    font-weight: 500;
    font-size: $H16;
    color: $white;
    margin-bottom: 16px;
  }
  &__label {
    @extend %label-styles;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 0;
    gap: 16px;
    & > .theme--dark.v-btn {
      color: $white;
    }
  }
}
</style>
