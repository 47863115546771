export class OrganisationSubscription
{
  id = 0;
  project_count = null;
  name = null;
  organisation_id = null;
  bandwidth_limit_gb = null;
  sites_count_limit = null;
  free_ssl = false;
  monthly_price = null;
  annually_monthly_price = null;
  max_owners = null;
  max_admins = null;
  monthly_price_per_user = false;

  constructor(origin) {
  	if (!origin)
	  return;
	this.id = origin.id;
	this.name = origin.name;
	this.project_count = origin.project_count;
	this.organisation_id = origin.organisation_id;
	this.bandwidth_limit_gb = origin.bandwidth_limit_gb;
	this.sites_count_limit = origin.sites_count_limit;
	this.free_ssl = origin.free_ssl;
	this.annually_monthly_price = origin.annually_monthly_price;
	this.monthly_price = origin.monthly_price;
	this.max_owners = origin.max_owners;
	this.max_admins = origin.max_admins;
	this.monthly_price_per_user = origin.monthly_price_per_user;
  }

  getServerObject() {
  	return {
	  id: this.id
  	}
  }

}