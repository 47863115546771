export class SiteUser {
  id = null;
  user_id = null;
  site_id = null;
  email = "";
  avatar = null;
  name = null;
  role = null;
  invited = false;
  value = "";

  constructor(origin) {
    if (!origin)
      return;


    this.id  = origin.id;
    this.email = origin.email;
    this.value = origin.name;
    this.avatar = origin.avatar_url;
    this.user_id = origin.user_id;
    this.site_id = origin.site_id;
    this.role = origin.role;
    this.name = origin.name;
  }

  getServerObject() {
    return {
      email: this.email,
      site_id: this.site_id,
      user_id: this.user_id
    };
  }
}
