<template>
  <v-card class="ModalCreateProject modal_window">
    <h4 class="ModalCreateProject__title">
      Create new project
    </h4>
    <v-card-subtitle>
      A Project is a great way to organise your sites and plugins
    </v-card-subtitle>
    <v-card-text class="mt-2">
      <span class="ModalCreateProject__label">Enter project name</span>
      <v-text-field
        v-model="name"
        class="pa-0 ModalCreateProject__input"
        autofocus
        hide-details
        filled
        dense
      />
      <div v-if="errorMain" class="error-main mt-4 pa-4">
        {{ errorMain }}
      </div>
    </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions class="pa-6 ModalCreateProject__footer">
      <v-btn @click="close" color="n5">Cancel</v-btn>
      <v-btn @click="onCreate" color="y1">Create project</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {guid} from '@/utils/common';

export default {
  name: "ModalCreateProject",
  data() {
    return {
      name: '',
      errorMain: ""
    }
  },
  methods: {
    onCreate() {
      if (!this.name)
        return;

      let projectData = {
        name: this.name
      };
      if (this.$store.state.organisations.current.id == 0)
        projectData['user_id'] = this.$store.state.user.current.id
      else
        projectData['organisation_id'] = this.$store.state.organisations.current.id;
      this.$store.dispatch('projects/create', projectData)
      .then(() => {
        this.$emit('close');
      })
      .catch( e => {
        console.log(e);

        // TODO: Remove unique validation for Project name
        this.errorMain = "Project name should be unique";
      })
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped lang="scss">
  .error-main {
    background: #d95050;
    border-radius: 2px;
  }

  $style: ModalCreateProject;
  .#{$style} {
    &.theme--dark.v-card {
      @extend %modal-body-styles;
      min-width: 600px;
      padding: 24px 24px 0;
    }
    &__title {
      font-weight: 500;
      font-size: $H16;
      color: $white;
      margin-bottom: 32px;
    }
    &__label {
      @extend %label-styles;
    }
    &__input {
      &.v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot {
        @extend %input-styles;
      }
      &.v-input input {
        color: $white;
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 16px 0;
      gap: 16px;
      & > .theme--dark.v-btn {
        color: $white;
      }
    }
  }
</style>