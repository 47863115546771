<template>
  <div class="PluginPublishFlowAppDataStep">
    <h6 class="mb-4">Additional Information</h6>
    <vue-form-generator :schema="schema" :model="model" :options="formOptions"></vue-form-generator>
  </div>
</template>
<script>
  import {mapState, mapMutations} from 'vuex';
  export default {
    name: "PluginPublishFlowAppDataStep",
    props: {
      capabilities: Array,
    },
    data() {
      return {
        model: {},
        schema: {
          fields: [
            {
              type: "select-reset",
              label: "Capabilities",
              model: "Capabilities",
              options: this.capabilities,
              placeholder: "Select capabilities"
            },
            {
              type: "select-reset",
              label: "Fee Type",
              model: "Fee_Type",
              options: [
                {text: "One-time", value: "One-time"}, 
                {text: "Monthly", value: "Monthly"}
              ],
              placeholder: "Select fee type"
            },
            {
              type: "boolean-switch",
              label: "Is paid?",
              model: "Is_Paid_"
            },
            {
              "type":"input",
              "inputType":"number",
              "name":"Fee_Amount",
              "label":"Fee Amount",
              "model":"Fee_Amount",
            },
            {
              type: "select-reset",
              label: "Permissions",
              model: "Permissions",
              multiple: true,
              options: [
                {text: "Full Screen", value: "fullscreen"}, 
                {text: "Camera", value: "camera"},
                {text: "Microphone", value: "microphone"}, 
                {text: "Geolocation", value: "geolocation"},
                {text: "Display Capture", value: "display-capture"},
                {text: "XR Spatial Tracking", value: "xr-spatial-tracking"}, 
                {text: "Speaker Selection", value: "speaker-selection"},
                {text: "Clipboard Read", value: "clipboard-read"}, 
                {text: "Clipboard Write", value: "clipboard-write"}
              ],
              placeholder: "Select permissions"
            },
            {
              type: "select-reset",
              label: "Sandbox Permissions",
              model: "Sandbox_Permissions",
              multiple: true,
              options: [
                {text: "Allow Scripts", value: "allow-scripts"}, 
                {text: "Allow Same Origin", value: "allow-same-origin"},
                {text: "Allow Presentation", value: "allow-presentation"}, 
                {text: "Allow Modals", value: "allow-modals"},
                {text: "Allow Top Navigation", value: "allow-top-navigation"}, 
                {text: "Allow Popups", value: "allow-popups"}
              ],
              placeholder: "Select sandbox permissions"
            },
          ],
          app: {},
          styleClasses: "rows",
          labelClasses: "label",
          fieldClasses: "boxed elem mb-15"
        },
        formOptions: {
          validateAfterLoad: true,
          validateAfterChanged: true
        },
      };
    },
    computed: {
      ...mapState('plugin_publishing', {
        appData: state => state.appData
      })
    },
    methods: {
      ...mapMutations("plugin_publishing", ["setAppData"]),
    },
    mounted() {
      this.model = this.appData;
    },
    watch: {
      appData: {
        handler() {
          if (JSON.stringify(this.appData) != JSON.stringify(this.model)) {
            this.model = this.appData;
          }
        },
        deep: true
      },
      model: {
        handler(newValue) {
          this.setAppData({...newValue});
        },
        deep: true
      }
    }

  };
</script>
<style scoped lang="scss">
  $style: PluginPublishFlowAppDataStep;
  .#{$style} {
    h6 {
      font-size: 14px;
      font-weight: normal;
    }
  }
</style>