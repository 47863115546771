export class Version {
  id = null;
  site_id = null;
  createdAt = null;
  description = "";
  percentDeployed = 1;
  scopedId = 0;
  uploadFileSize = 0;
  parse_custom_code_id = null;
  deployStatus = 'deploying';
  unreadComments = 0;
  added = [];
  removed = [];
  modified = [];

  diff = {
    added: [],
    deleted: [],
    modified: []
  };

  messages = [];


  constructor(origin) {
    if (!origin)
      return;

    this.id = origin.id;
    this.site_id = origin.site_id;
    this.createdAt = new Date(origin.created_at);
    this.description = origin.description == null ? "" : origin.description;
    this.percentDeployed = origin.percent_deployed;
    this.scopedId = origin.scoped_id;
    this.deployStatus = origin.deploy_status;
    this.uploadFileSize = origin.upload_file_size;
    this.parse_custom_code_id = origin.parse_custom_code_id;
    this.unreadComments = origin.unread_comments;
    this.added = origin.added_files;
    this.removed = origin.removed_files;
    this.modified = origin.modified;
    if (this.messages == null)
      this.messages = [];
    if (origin.diff) {
      this.diff.added = origin.diff.added ? origin.diff.added : [];
      this.diff.deleted = origin.diff.deleted ? origin.diff.deleted : [];
      this.diff.modified = origin.diff.modified ? origin.diff.modified : [];
    }
  }

  getServerObject() {
    return {
      site_id: this.site_id,
      created_at: this.createdAt ? this.createdAt.toISOString() : null,
      description: this.description,
      percent_deployed: this.percentDeployed,
      scoped_id: this.scopedId,
      upload_file_size: this.uploadFileSize,
      diff: this.diff,
      parse_custom_code_id: this.parse_custom_code_id
    };
  }
}
