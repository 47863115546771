<template>
  <div class="PluginPublishFlowAppStep">
    <h6 class="mb-4">About</h6>
    <vue-form-generator :schema="schema" :model="model" :options="formOptions"></vue-form-generator>
  </div>
</template>

<script>
  import {mapState, mapMutations} from 'vuex';
  import VueFormGenerator from 'vue-form-generator';

  export default {
    name: "PluginPublishFlowAppStep",
    components: {
      "vue-form-generator": VueFormGenerator.component    
    },
    data() {
      return {
        model: {
          URL: this.activeSite?.url
        },
        schema: {
          fields: [
            {
              "type":"input",
              "inputType":"text",
              "name":"Name",
              "icon":"mdi-text-short",
              "label":"Name",
              "model":"Name",
              required: true,
              validator: ["string"],
            },
            {
              "type":"input",
              "inputType":"text",
              "name":"Slug",
              "icon":"mdi-text-short",
              "label":"Slug",
              "model":"Slug",
              "disabled": !!this.app?.id
            },
            {
              "type":"input",
              "inputType":"text",
              "name":"URL",
              "icon":"mdi-text-short",
              "label":"URL",
              "model":"URL",
              disabled: true
            },
            {
              "type":"input",
              "inputType":"text",
              "name":"Dev_URL",
              "icon":"mdi-text-short",
              "label":"Dev URL",
              "model":"Dev_URL",
              visible: this.activeSite?.mode === 'development'
            },
            {
              "type":"switch",
              "name":"Dev_Mode",
              "label":"Dev Mode",
              "model":"Dev_Mode",
              visible: this.activeSite?.mode === 'development'
            }
          ],
          styleClasses: "rows",
          labelClasses: "label",
          fieldClasses: "boxed elem mb-15"
        },
        formOptions: {
          validateAfterLoad: true,
          validateAfterChanged: true
        },
      };
    },
    mounted() {
      this.model = {...(this.app || {}), URL: this.app.URL || this.activeSiteURL };
      this.schema.fields = this.schema.fields.map(field => {
        if (field.name === 'Dev_Mode' || field.name === 'Dev_URL') {
          return { ...field, visible: this.activeSite?.mode === 'development' };
        }
        return field;
      });
    },
    computed: {
      ...mapState({
        app: state => state.plugin_publishing.app
      }),
      activeSiteURL() {
        let site_url = "";
        if (this.activeSite.use_ssl) site_url = "https://" + this.activeSite.url;
        else site_url = "http://" + this.activeSite.url;
        return site_url;
      },
      slug() {
        if (this.app && this.app.Name) {
          return this.app.Name.toLowerCase().replace(/[^a-z0-9]+/g, "-");
        }
        return "";
      }
    },
    methods: {
      ...mapMutations("plugin_publishing", ["setApp", "setAppData"]),
    },
    watch: {
      "model.Name": function(val) {
        if (val && !this.app.id) {
          this.model.Slug = val.toLowerCase().replace(/[^a-z0-9]+/g, "-");
        }
      },
      "app.id": function(newAppId) {
        this.schema.fields[1].disabled = !!newAppId;
      },
      app: {
        handler() {
          if (JSON.stringify(this.app) != JSON.stringify(this.model)) {
            this.model = this.app;
          }
        },
        deep: true
      },
      model: {
        handler(newValue) {
          if (JSON.stringify(this.app) != JSON.stringify(this.model)) {
            this.setApp({ ...newValue });
          }
        },
        deep: true,
      }
    }
  };
</script>

<style scoped lang="scss">
  $style: PluginPublishFlowAppStep;
  .#{$style} {
    h6 {
      font-size: 14px;
      font-weight: normal;
    }
  }
</style>
