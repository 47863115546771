<template>
  <div class="PluginInstallParamsMainView" :class="settingsDrawerOpened ? 'settings-drawer' : ''">
    <div class="PluginInstallParamsMainView__header">
      <div class="d-flex">
        <h6>Plugin Setting</h6>
      </div>
      <v-btn icon text color="n2" @click="onClose" v-if="settingsDrawerOpened">
        <v-icon>close</v-icon>
      </v-btn>
    </div>
    <div class="PluginInstallParamsMainView__body">
      <div class="PluginInstallParamsMainView__elements-count">Elements Added {{elements.length}} </div>
      <div class="PluginInstallParamsMainView__elements">
        <form-builder-elements v-model="elements" :level="0" />
      </div>
      <div class="PluginInstallParamsMainView__toolbar" :class="settingsDrawerOpened ? 'justify-center' : 'justify-space-between'" >
        <v-btn color="y1" text @click="onPreview" v-if="!settingsDrawerOpened">
          <the-icon class="mr-2" icon="eye-on" />
          <span>Preview</span>
        </v-btn>
        <form-builder-toolbar />
      </div>
    </div>
    <div class="PluginInstallParamsMainView__footer" v-if="settingsDrawerOpened">
      <div>
        <v-btn  color="y1" text @click="onPreview" >
          <the-icon class="mr-2" icon="eye-on" />
          <span>Preview</span>
        </v-btn>
        <div class="d-flex flex-row-reverse">
          <v-btn @click="onSubmit" color="y1">Save Changes</v-btn>
          <v-btn @click="onClose" text color="white" class="mr-2" >Cancel</v-btn>
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="30"
        :color="'#496DDB'"
      />
    </div>
  </div>
</template>
<script>
  
  import {mapState, mapMutations} from 'vuex';
  import { IntersectingCirclesSpinner } from "epic-spinners";
  import { updateDeveloperAppData } from '@/utils/parse';
  import { MODAL_INFORMATION } from "@/components/Modals";
  import FormBuilderToolbar from "../FormBuilder/FormBuilderToolbar";
  import FormBuilderElements from "../FormBuilder/FormBuilderElements";

  export default {
    name: "PluginInstallParamsMainView",
    components: {
      IntersectingCirclesSpinner,
      FormBuilderElements,
      FormBuilderToolbar
    },
    data() {      
      return {
        loading: false
      };
    },

    computed: {
      ...mapState('plugin_publishing', {
        settingsDrawerOpened: state => state.settingsDrawerOpened,
        app: state => state.app,
        appData: state => state.appData,
      }),
      ...mapState({
        publisher: state => state.publisher.activePublisher,
      }),

      elements: {
        get() {
          return this.$store.state.formBuilder.elements;
        },
        set(value) {
          this.$store.dispatch("formBuilder/updateElements", value);
        }
      },
    },

    methods: {
      ...mapMutations("plugin_publishing", ["setSettingsDrawerOpened"]),
      ...mapMutations("formBuilder", ["setEditingElement", "updateElementOnly", "setIsInPreview"]),
      
      // Submit
      async onSubmit() {
        this.loading = true;
        if (this.app && this.app.id) {
          const res = await updateDeveloperAppData({
            appDataId: this.appData.id, 
            installParams: JSON.stringify(this.elements),
            status: 'Waiting for Review',
            parseServerSiteId: this.publisher?.parse_server_site_id
          });
          if (res && res.id) {
            this.$store.commit('application/openModal', {
              component: MODAL_INFORMATION,
              props: {
                title: "Plugin Setting" ,
                text: "Plugin Setting has been updated successfully.",
                showCancelBtn: false,
                actionBtnLabel: "OK",
              }
            });
          }
        }
        this.loading = false;
      },
      // Save as draft and show the confirmation dialog to the user.
      onPreview() {
        this.setIsInPreview(true);
      },
      onClose() {
        this.setEditingElement(null);
        this.setSettingsDrawerOpened(false);
        this.setIsInPreview(false);

      }
    }
  };
</script>
<style scoped lang="scss">
  $style: PluginInstallParamsMainView;
  .#{$style} {
    height: 100%;
    background: $N5;
    position: relative;
    display: flex;
    flex-direction: column;
    &.settings-drawer {
      .#{$style}__header {
        padding: 20px;
      }
      .#{$style}__body {
        padding: 16px 28px;
      }
    }
    &__header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $N6;
      padding: 0px;
      padding-bottom: 10px;
      h6 {
        font-size: 16px;
        font-weight: normal;
      }
    }
    &__status {
      background: $B4;
      color: $B1;
      padding: 3px 8px;
      border-radius: 6px;
      margin-left: 4px;
      font-size: 12px;
    }

    &__body {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      color: $N1;
      height: 100%;
      padding: 0px;
    }
    &__elements-count {
      font-size: 14px;
      margin-bottom: 16px;
      margin-top: 16px;
    }
    &__elements {
      // height: calc(100vh - 250px);
      overflow-y: auto;
      flex-grow: 1;
    }
    &__toolbar {
      display: flex;
      align-items: center;
    }
    &__footer {
      padding: 0px;
      > div {
        border-top: 1px solid $N6;
        background: $N5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        width: 100%;
      }
    }
  }
  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.3s ease-out;
  }
  .slide-enter,
  .slide-leave-to {
    transform: translateX(-100%);
    opacity: 0;
  }
  .slide-leave,
  .slide-enter-to {
    transform: translateX(100%);
    opacity: 0;
  }

  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(200, 200, 200, 0.3);
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 80;
  }

</style>

<style lang="scss">
  .PluginInstallParamsMainView .form-group label {
    display: block;
    margin-bottom: 6px;
    color: $N2;
  }

  .app .PluginInstallParamsMainView .vue-form-generator .form-control {
    border: 1px solid $N3;
    background: transparent;
    border-radius: 8px;
  }
</style>