<template>
  <div class="VulcanProjectList">
    <div class="mb-6" v-for="project in projects" :key="project.id">
      <h3 class="mb-2">{{ project.name }}</h3>
      <v-container grid-list-md class="pa-0">
        <v-layout wrap>
          <v-flex
            xs12
            md3
            v-for="chart in project.charts"
            :key="chart.id"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                light
                @click="openChart({chartId: chart.id, projectId: project.id})"
                class="projects-list-item__chart"
              >
                <v-img :src="chartPlaceholderImage(project)" aspect-ratio="2.75" />
                <v-layout>
                  <v-flex>
                    <div class="title">{{chart.name}}</div>
                    <v-divider />
                    <div class="last-update">
                      <v-icon small>update</v-icon> Last updated {{friendlyTime(chart.last_update)}}
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-hover>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: 'VulcanProjectList',
  props: {
    projects: Array,
    openChart: Function
  },
  methods: {
    chartPlaceholderImage(project) {
      var imageUrl = ""
      if (project.type == 'AnalysisTools') {
        imageUrl = "https://kaupang-production.s3.us-east-2.amazonaws.com/Vulcan+Client/knowledge-project-placeholder.jpg"
      } else if (project.type == 'Chatbot') {
        imageUrl = "https://kaupang-production.s3.us-east-2.amazonaws.com/Vulcan+Client/chatbot-project-placeholder.jpg"
      } else {
        imageUrl = "https://kaupang-production.s3.us-east-2.amazonaws.com/Vulcan+Client/knowledge-project-placeholder.jpg"
      }
      return imageUrl
    },
    friendlyTime(date) {
      return moment(date).fromNow()
    },
  }
};
</script>

<style scoped lang="scss">
  $style: VulcanProjectList;
  .#{$style} {
    margin-top: 20px;
    .title {
      padding: 0 10px;
    }
    .last-update {
      font-size: 10px;
      padding: 5px 10px;
    }
    .disabled {
      opacity: 0.3;
    }
  }
</style>
