<template>
  <v-card min-height="90vh" class="ModalComments" max-width="600px">
    <div class="ModalComments__title">
      <div class="ModalComments__title-wrapper">
        Comments
      </div>
      <v-btn x-small icon dark @click="close"
        ><the-icon class="ModalComments__close-icon" icon="close"
      /></v-btn>
    </div>

    <div class="ModalComments__select_version_block">
      <div class="ModalComments__version_select">
        <v-btn :ripple="false" class="ModalComments__version_select_btn" @click="selectVersionComment" :class="{selected: !allComments}" plain> 
          Version #{{version.scopedId}}
        </v-btn>
        <v-btn :ripple="false" class='ModalComments__version_select_btn' @click="selectAllComments" :class="{selected: allComments}" plain> 
          All versions
        </v-btn>
      </div>
    </div>

    <SiteVersionComments :cloudCode="cloudCode" :loading="loading" :comments="comments" :placeholder="inputPlaceholder" :version="version"/>
  </v-card>
</template>

<script>
import SiteVersionComments from "@/components/TheSite/SiteVersion/SiteVersionComments/SiteVersionComments";
export default {
  name: "ModalComments",
  props: {
    version: Object,
    cloudCode: Boolean
  },
  components: { SiteVersionComments },
  data() {
    return {
      allComments: false,
      comments: [],
      usersList: [],
      loading: true
    };
  },
  computed: {
    inputPlaceholder() {
      return `Add comment for version #${this.version.scopedId} ...`
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async loadVersionComments() {
      this.$store.dispatch('comments/load', {version_id: this.version.id })
      this.$store.commit('comments/setActiveVersion', this.version.id)
    },
    async loadAllSiteComments() {
      if (this.cloudCode)
        this.$store.dispatch('comments/load', {custom_code_id: this.version.parse_custom_code_id})
      else  
        this.$store.dispatch('comments/load', {site_id: this.activeSite.id})
      this.$store.commit('comments/setActiveVersion', 'all')
    },
    selectVersionComment() {
      if (!this.allComments) {
        return
      }
      this.loading = true;
      this.comments = [];
      this.allComments = false;
      this.loadVersionComments();
    },
    selectAllComments() {
      if (this.allComments) {
        return;
      }
      this.loading = true;
      this.comments = []
      this.allComments = true;
      this.loadAllSiteComments();
    }
  },
  updated() {
    if (this.$route.query.versionId && this.$route.query.commentId)
    {
      const element = document.getElementById("comment_" + this.$route.query.commentId);
      if (!element)
        return;
      this.$router.push({query: {}})
      element.scrollIntoView();
      element.focus();
      element.getElementsByClassName('SiteVersionComment__commentContainer')[0].classList.add('_active');
    }
  },
  async mounted() {
    await this.$store.dispatch('comments/load', { version_id: this.version.id })
    this.$store.commit('comments/setActiveVersion', this.version.id)
    // this.loading = false;
  },
  watch: {
    "$store.state.comments.list": {
      handler (newVal, oldVal) {
        this.comments = _.cloneDeep(newVal);
        this.loading = false;
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
$style: ModalComments;
.#{$style} {
  min-height: 100vh !important;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 12px 0px 0px 12px;

  &.theme--dark.v-card {
    background: $N5;
    border: 1px solid $N7;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    border-radius: 12px 0px 0px 12px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: $H16;
    color: $white;
    padding: 30px 34px !important;
    border-bottom: 1px solid $N5;
    position: sticky;
  }
  &__title-wrapper {
    display: flex;
    align-items: center;
  }
 
  &__close-icon {
    fill: $N3;
    width: 18px;
    height: 18px;
  }

  &__select_version_block {
    border-bottom: 1px solid $N7;
    border-radius: 0px !important;

    .#{$style}__version_select {
      padding-left: 36px;
      .#{$style}__version_select_btn {
        span {
          color: $N8;
        }
        &.selected {
          border-radius: 0;
          border-bottom: 1px solid $B1;
          span {
            color: #FFF;
            opacity: 1 !important; 
          }
        }
      }
    }
  }
}
</style>
