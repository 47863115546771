<template>
  <div class="my-4 mx-6">
    <div class="mb-6">
      <router-link class="breadcrumb-link" to="/publisher/reports">
        Reports
      </router-link>
      <span class="breadcrumb-separator">/</span>
      <span class="breadcrumb-link">Plugin Installs</span>
    </div>
    <div class="PluginInstallsReport__header d-flex flex-column flex-sm-row justify-space-between align-sm-center gap-3 pa-0 mt-4">
      <div class="d-flex flex-column flex-sm-row align-sm-center gap-3">
        <v-select
          v-model="filter.period"
          :items="periods"
          item-text="label"
          item-value="value"
          class="mr-4 PluginInstallsReport__select-filter flex-grow-0"
          height="40"
          dense 
          dark
          hide-details
          :readonly="reportReady"
          @click="displayReadonlyAlert"
        />
        <RangePicker 
          v-if="filter.period == 'custom'"
          :start-date.sync="filter.startDate"
          :end-date.sync="filter.endDate"
          :read-only="reportReady"
          :click="displayReadonlyAlert"
        />
        <v-checkbox
          dark
          v-if="filter.period=='all'"
          v-model="filter.showOnlyReadyForSale"
          @click="displayReadonlyAlert"
          label="Only 'Ready for Sale' Plugins"
          class="mr-4"
          :readonly="reportReady"
          dense
          hide-details
        ></v-checkbox>
        <v-checkbox
          dark
          v-if="filter.period=='all'"
          v-model="filter.hideZeroInstalls"
          @click="displayReadonlyAlert"
          label="Hide Zero Installs"
          :readonly="reportReady"
          dense
          hide-details
        ></v-checkbox>
      </div>
      
      <div class="d-flex" v-if="reportReady">
        <v-btn 
          class="v-btn primary-btn mr-4"
          @click="onDowloadReport"
          elevation="2"
          large
        >
          <v-icon dark left> mdi-download </v-icon>
          Download
        </v-btn>
        <v-btn 
          class="v-btn reset-btn"
          @click="onResetFilter"
          elevation="2"
          large
        >
          <v-icon dark left> mdi-refresh </v-icon>
          Reset
        </v-btn>
      </div>
      <v-btn 
        class="v-btn primary-btn"
        @click="onRunReport"
        elevation="2"
        large
        v-else
      >
        <v-icon dark left> mdi-play </v-icon>
        Run
      </v-btn>
    </div>
    <div class="PluginInstallsReport__body">
      <v-row v-if="reportReady" class="mt-4">
        <v-col>
          <v-data-table 
            :headers="headers"
            :items="tableData"
            ref="dataTable"
            class="data-table"
            @click:row="handleClickPluginRow"
          /> 
        </v-col>
      </v-row>
      <div v-if="loading" class="spinner">
        <IntersectingCirclesSpinner
          :animation-duration="1200"
          :size="100"
          :color="'#496DDB'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { IntersectingCirclesSpinner } from "epic-spinners";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getFirstMondayAndLastSunday } from '@/utils/common';
import RangePicker from "./RangePicker.vue";
const Parse = require('parse');

export default {
  name: "PluginInstallsReport",
  components: {
    RangePicker,
    IntersectingCirclesSpinner
  },
  data() {
    const periods = [{ value: 'all', label: 'All Time' }, { value: 'custom', label: 'Custom Range' }];
    const [firstMonday, lastSunday] = getFirstMondayAndLastSunday();
    return {
      periods,
      filter: {
        period: 'all',
        showOnlyReadyForSale: true,
        hideZeroInstalls: true,
        startDate: firstMonday,
        endDate: lastSunday
      },
      data: [],
      loading: false,
      reportReady: false,
      tableData: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'URL', value: 'url' },
        { text: 'Status', value: 'status' },
        { text: 'Installs Count', value: 'installsCount' }
      ]
    };
  },
  computed: {
    ...mapState({
      currentPublisherInfo: ({ publisher_infos }) => publisher_infos.current
    })
  },
  mounted() {
    Parse.initialize(this.currentPublisherInfo.parse_server_app_id);
    Parse.serverURL = this.currentPublisherInfo.parse_server_url;
  },
  methods: {
    async onRunReport() {
      
      const params = {
        limit: 100,
        ...this.filter
      }
      let result;
      this.loading = true;
      if (this.filter.period === 'custom') {
        if (!this.filter.startDate || !this.filter.endDate) {
          alert('Please select both start end end date.');
          this.loading = false;
          return;
        }
        result = await Parse.Cloud.run('getPluginInstallsWithinPeriod', 
          { parseServerSiteId: this.currentPublisherInfo?.parse_server_site_id, filter: params }
        );
      } else {
        result = await Parse.Cloud.run('getPluginInstalls', 
          { parseServerSiteId: this.currentPublisherInfo?.parse_server_site_id, filter: params }
        );
      }
      


      if (result.status === 'success') {
        this.data = result.pluginInstalls;
        this.tableData = result.pluginInstalls
          .map((item) => ({
              name: item.name,
              id: item.id,
              slug: item.slug,
              url: item.url,
              installsCount: item.installsCount,
              status: item.developerData?.status
            }))
          .sort((a, b) => a.installsCount - b.installsCount > 0 ? -1 : 1);
        this.reportReady = true;
      }
      this.loading = false;
    },

    async onDowloadReport() {
      var source =  this.$refs["dataTable"];

      let columnHeader = ['Name', 'URL', 'Status', 'InstallsCount'];
      let pdfName = 'Plugin Installs';
      const rows = source.items.map(element => ([
        element.name,
        element.url,
        element.status,
        element.installsCount
      ]));

      var doc = new jsPDF();
      doc.autoTable(columnHeader, rows, { startY: 10 });
      doc.save(pdfName + '.pdf');
    },

    onResetFilter () {
      this.reportReady = false;
      this.loading = false;
    },
    handleClickPluginRow(plugin) {
      this.$router.push({ name: "plugin_detail", params: { pluginId: plugin.slug } });
    },
    displayReadonlyAlert() {
      if (this.reportReady) alert('Filters are readonly and please click on Reset button to restart filter.');
    },
  },
};
</script>
<style lang="scss">
$style: PluginInstallsReport;
.#{$style} {
  padding: 42px 24px 24px;
  &__row {
    margin-bottom: 58px;
  }

  &__header {
    .v-btn.primary-btn {
      background-color: $B1;
      color: white;
    }
    .v-btn.reset-btn {
      background-color: $R1;
      color: white;
    }
  }

  &__select-filter {
    border: 1px solid #5e636d;
    border-radius: 8px;
    padding: 0 10px;
    background-color: hsla(0,0%,100%,.08);
    width: 200px;
  }

  &__body {
    min-height: 500px;
    position: relative;
    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -50px;
      margin-left: -50px;
      width: 100px;
      height: 100px;
    }
  }
}
</style>
