<template>
  <div class="ParseCloud">
    <div class="mb-2">
      <v-btn @click="activatedTab = 0" dark color="orange" class="ml-2" small> Cloud Code </v-btn>
  	  <v-btn @click="activatedTab = 1" class="ml-2" dark color="grey" small> Templates </v-btn>
    </div>
  	
  	<codemirror v-model="customCode" :options="codeOptions" v-if="activatedTab == 0" />
  	<codemirror v-model="templatesJson" :options="templatesOptions" v-else-if="activatedTab == 1" />

  	<div class="mt-4" align="right" v-if="changed && !saving">
  	  <v-btn @click="resetChanges" class="mr-2" dark color="grey" medium> Reset </v-btn>
  	  <v-btn @click="save"  dark color="orange" medium> Save </v-btn>
  	</div>

  	<div class="mt-8" v-if="versions.length == 0" align="center">
  	  <h3>Create your first version to see the version history </h3>
  	</div>
  	<div class="mt-8" v-else>
        <SiteVersionElement
          v-for="version in versions"
          :arrayType="1"
          :key="version.id"
          :version="version"
        />
  	</div>
  </div>

</template>

<script>
  import _ from 'lodash';
  import SiteVersionElement from "@/components/TheSite/SiteVersion/SiteVersionElement";
  import { codemirror } from 'vue-codemirror';
  import 'codemirror/lib/codemirror.css';
  import 'codemirror/mode/javascript/javascript.js'
  import 'codemirror/theme/base16-dark.css';

  export default {
    name: "SiteAppParseCloud",
    components: {codemirror, SiteVersionElement},
    data() {
      return {
        defaultTemplates: null,
        defaultCode: null,
        id: null,
        parse_setting_id: null,
        current_version_id: null,
        customCode: "",
        saving: false,
        templatesJson: "",
        activatedTab: 0,
        codeOptions: {
          tabSize: 2,
          mode: 'text/javascript',
          lint: true,
          theme: 'base16-dark',
          lineNumbers: true,
          line: true
        },
        templatesOptions: {
          tabSize: 2,
          mode: {
          	name: 'javascript',
          	json: true
      	  },
      	  lint: true,
          theme: 'base16-dark',
          lineNumbers: true,
          line: true
        }
      }
    },
    mounted() {
	  this.$store.dispatch('parse_cloud/load',(this.$route.params.siteAppId));
    },
    computed: {
      changed() {	
      	return this.customCode != this.defaultCode || this.templatesJson != this.defaultTemplates;
      },
      versions() {
      	if (this.id == null)
      	  return [];
        let versions = this.$store.state.versions.list
     		.filter(v => v.parse_custom_code_id == this.id)
     		.sort((a, b) => b.scopedId - a.scopedId);
 	    return versions;	
        	
      },
    },
    methods: {
      save() {
      	this.saving = true;
      	const params = {
      	  id: this.id,
      	  parse_custom_code: {
      	  	id: this.id,
      	  	parse_setting_id: this.parse_setting_id,
      	  	templates_json: this.templatesJson,
      	  	custom_code: this.customCode
      	  } 
      	};
      	this.$store.dispatch('parse_cloud/update', params)
      	.then(() => {
      	  this.saving = false;
      	})
      	.catch(e => {
      	  this.saving = false;
      	  console.log(e)
      	})
      },
      resetChanges() {
      	this.customCode = this.defaultCode;
      	this.templatesJson = this.defaultTemplates;
      }
    },
    watch: {
      "$store.state.parse_cloud.current": {
        handler(newVal, oldVal) {
            this.defaultTemplates = newVal.templates_json;
            this.defaultCode = newVal.custom_code;
            this.id = newVal.id;
            this.parse_setting_id = newVal.parse_setting_id;
            this.current_version_id = newVal.current_version_id;
            if (this.customCode.length == 0)
              this.customCode = newVal.custom_code;
            if (this.templatesJson.length == 0)
              this.templatesJson = newVal.templates_json;
        }
      }
    }
  }
</script>

<style lang="scss">
  .ParseCloud {
    padding: 42px 0px 24px;
    margin: auto;
  }
  .CodeMirror {
    height: 900px;
    max-height: 80%;
  }
	
</style>
