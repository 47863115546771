import {OrganisationUser} from '@/models/OrganisationUser';
import {OrganisationUser as OrganisationUserResource} from "@/resources/forge";
import router from "@/router";

const store = {
  namespaced: true,
  state: {
    list: []
  },
  mutations: {
    /**
     * Sets the list of organisation users in the store state.
     * @param {Array} list - The list of organisation users.
     */
    setList (state, list) {
      state.list = list;
    },
    /**
     * Deletes an organisation user from the list based on its ID.
     * @param {string} id - The ID of the organisation user to delete.
     */
    delete(state, id) {
      state.list = state.list.filter(ou => id != ou.id);
    },
    /**
     * Adds a new organisation user to the list.
     * @param {Object} organisation_user - The organisation user to add.
     */
    create(state, organisation_user) {
      state.list = [...state.list, organisation_user];
    },
    /**
     * Updates an existing organisation user in the list.
     * @param {Object} params - The parameters to update the organisation user.
     */
    update(state, params) {
      const organisation_user = state.list.find(ou => ou.id == params.id);
      if (!organisation_user)
        return;

      Object.assign(organisation_user, params);
    }
  },
  actions: {
    /**
     * Loads organisation users from the API and sets them in the store state.
     * @returns {Promise} A promise resolving to the API response.
     */
    async load({commit}) {
      const res = await OrganisationUserResource.get();
      commit('setList',
        res.body.organisation_users.map(ou => new OrganisationUser(ou)));
      return res;
    },
    /**
     * Deletes an organisation user from the API and the store state.
     * @param {string} id - The ID of the organisation user to delete.
     */
    delete({commit, rootState, getters}, id) {
      const organisation_user = getters['findById'](id);
      const user_id = organisation_user.user_id;
      const organisation_id = organisation_user.organisation_id;
      OrganisationUserResource.destroy({id: id})
        .then(() => {
          commit("delete", id);
          if (rootState.user.current.id == user_id){
            location="/";
          }
        })
        .catch((err) => {
          console.log(err);
        }); 
    },
    /**
     * Changes the role of an organisation user.
     * @param {Object} params - The parameters for changing the role.
     * @returns {Promise} A promise resolving to the API response.
     */
    changeRole({commit}, params) {
      return OrganisationUserResource.update({id: params.id}, {organisation_user: params.organisation_user}) 
        .then(res => {
          commit("update", new OrganisationUser(res.body.organisation_user));
          return res;
        })
    },
    /**
     * Adds a new user to an organisation.
     * @param {Object} params - The parameters for adding the user.
     */
    addUser({commit}, params) {
      OrganisationUserResource.create({organisation_user: params})
        .then(res => {
          if (res.body.organisation_user != undefined)
            commit("create", new OrganisationUser(res.body.organisation_user));
          else
            commit("invitation/create", res.body.invitation, {root: true});
        })
        .catch(e => {
          console.log(e)
        })
    },
    /**
     * Adds multiple users to an organisation.
     * @param {Object} params - The parameters for adding the users.
     */
    async addUsers({commit, dispatch}, params) {
      await OrganisationUserResource.addUsers({organisation_user: params})
        .then(async () => {
          await dispatch("load");
          await dispatch('invitation/load', {type: 'organisation', type_id: params.organisation_id }, { root: true });
        })
    }
  },
  getters: {
    /**
     * Finds an organisation user by ID.
     * @param {string} id - The ID of the organisation user to find.
     * @returns {Object} The organisation user.
     */
    findById: state => id => {
      return state.list.find(ou => ou.id == id);
    }
  }
};

export default store;
