<template>
  <div>
    <div v-if="showBrowser">
      <div class='header-browser' align="left">
        <v-icon dark left>mdi-github</v-icon>
        <span> CHOOSE A REPO </span>
        <v-btn color="error" class="one_line" text @click="showBrowser = false"> CANCEL </v-btn>
        <br/>
        <div class="mt-3 mb-3">
          <span @click="clearRepoPath"> <v-icon dark left>mdi-folder</v-icon> Repositories </span>
            <span v-if="reposSelected">  > {{currentGitPath}}
              <span align='right'>
                <v-btn @click="selectFolder(reposCursor)" class="white--text choose-btn" color="black">Choose This Folder</v-btn>
              </span>
          </span>
        </div>
      </div>

      <div class="mt-4 repos-block" align="left">
        <div v-if="reposSelected" class="mt-1 git-row" @click="back()">
          <v-icon dark left>mdi-folder </v-icon>
          <span> . . . </span>
        </div>
        <div class="mt-1 git-row" v-for="repo in reposInList" @click="selectRepo(repo.path_lower)">
          <v-icon dark left>mdi-folder</v-icon>
          <span> {{repo.name}} </span>
        </div>
      </div>
    </div>
    <div class="syncing-text" v-else>
      <p>
        <b>{{activeSite.url}} </b> is syncing with <b>{{savedData.path}}</b>
      </p>
      <v-btn @click="showBrowser = true" class="white--text mt-3" color="orange darken-4">
        <span v-if="selectedRepo ==null" class="pr-1">Select </span> <span v-else class="pr-1"> Change  </span> Dropbox Folder
      </v-btn>
      <div v-if="selectedRepo != null" align="center">
        <v-checkbox
          dark
          class="align-center justify-center d-block checkbox-auto"
          @change="changeAutoDeploy"
          v-model="deployHandler"
          :label="`${deployHandler.toString() == 'true' ? 'Disable' : 'Enable'} Auto-Deployment`"
        ></v-checkbox>
        Push to your Dropbox repository and automatically trigger a re-deployment
      </div>
    </div>
  </div>
</template>

<script >
  export default {
    name: "DropboxBrowser",
    props: {
        savedData: Object,
        url: String
    },

    data() {
      return {
        repos: [],
        selectedRepo: this.savedData.path,
        reposCursor: this.savedData.path == null ? "" : this.savedData.path,
        showBrowser: this.savedData.path == null,
        deployHandler: this.savedData.autoDeploy == null ? false : this.savedData.autoDeploy,
        filterText: "",
      }
    },
    methods: {
      async getDataFromApi(path, type) {
        let headers = {
            'Authorization': 'Bearer ' + this.savedData.token, 
            'Content-Type': 'application/json'
          }
        let url = this.url;
        let res = await this.$http.post(url, {path: this.reposCursor, recursive: false}, {headers}) ;
        this[type] = res.body.entries;

      },
      back() 
      {
        this.reposCursor = this.reposCursor.split('/').slice(0, -1).join('/');
        this.fetchRepos();
      },
      fetchRepos() {
        this.repos = [];
        this.getDataFromApi('', 'repos')
      },
      selectRepo(path) {
        this.reposCursor = path;
        this.fetchRepos();
        
      },
      clearRepoPath() {
        this.reposCursor = '';
        this.selectedRepo = null;
        this.fetchRepos();
      },
      selectFolder(folderPath) {
        let params = {
          github_path: null,
          github_branch: null,
          bitbucket_path: null,
          bitbucket_branch: null,
          dropbox_path: folderPath,
          oauth_user_id: this.$store.state.user.current.id
        }
        this.$store.dispatch('sites/update', {site: params})
        this.selectedRepo = folderPath;
        this.showBrowser = false;
      },
      changeAutoDeploy() {
        this.$store.dispatch('sites/update', {site: {dropbox_autodeploy: this.deployHandler}});
      }
    },
    mounted() {
      this.fetchRepos();
    },
    computed: {
      filesInList() {
        let sorted = this.repos.filter(f => f['.tag'] == 'file').sort((a,b) => a.name.localeCompare(b.name));
        return sorted;
      },
      reposInList() {
        let sorted = this.repos.filter(f => f['.tag'] == 'folder').sort((a,b) => a.name.localeCompare(b.name));
        return sorted;
      },
      currentRepoPath() {
        return (this.reposCursor != null && this.reposCursor.length > 0) || this.selectedRepo != null;
      },
      currentGitPath() {
        let path = this.reposCursor == null ? this.selectedRepo : this.reposCursor;
        return path.substring(1);
      },
      reposSelected() {
        return this.reposCursor != null && this.reposCursor.length > 0;
      }
    }
  }
</script>

<style>
  .one_line {
    margin-top: -5px;
  }
  .header-browser {
    border-bottom: 1px solid lightgray;
    border-bottom-color: rgba(255,255,255,0.1);
    padding-bottom: 4px;
  }
  .syncing-text {
    align-items: center;
    text-align: center;
  }
  .git-row {
    height: 36px;
    border-bottom: 1px solid lightgray;
    border-bottom-color: rgba(255,255,255,0.1);
    padding-top: 6px;
  }
  .repos-block {
    max-height: 700px;
    overflow-y: auto;
  }
  .choose-btn {
    float: right;
    bottom: 10px;
  }
  .checkbox-auto .v-input--selection-controls__input {
    vertical-align: top;
  }
  .checkbox-auto .v-input__slot {
    display: block;
    text-align: center;
  }
</style>
