import { getRelativeTime } from "@/utils/common";

export class Form {
  id = null;
  user_id = null;
  site_id = null;
  human_name = "";
  form_datum_ids = null;
  form_data = [];
  fields = [];
  name = null;
  notifications = false;
  auto_response = false;
  email_address = null;
  email_subject = null;
  email_body = null;
  data = [];
  ajax_form = false;
  ajax_without_message = false;
  ajax_message = null;
  redirect_to_url = false;
  redirect_url = null;
  active = false;
  custom_email = null;
  custom_email_enabled = false;
  collaborator_enabled = false;
  collaborator_emails = null;
  collaborators = null;
  message_body = null;
  custom_template_enabled = false;
  header_image_url = null;
  custom_from_text = null;
  custom_subject_text = null;

  constructor(origin) {
    if (!origin)
      return;
    this.id  = origin.id;
    this.user_id = origin.user_id;
    this.form_datum_ids = origin.form_datum_ids;
    this.site_id = origin.site_id;
    this.human_name = origin.human_name;
    this.name = origin.name;
    this.notifications = origin.notifications;
    this.auto_response = origin.auto_response;
    this.email_address = origin.email_address;
    this.email_subject = origin.email_subject;
    this.email_body = origin.email_body;
    this.ajax_form = origin.ajax_form;
    this.ajax_without_message = origin.ajax_without_message;
    this.ajax_message = origin.ajax_message;
    this.redirect_to_url = origin.redirect_to_url;
    this.redirect_url = origin.redirect_url;
    this.active = origin.active;
    this.custom_email = origin.custom_email;
    this.custom_email_enabled = origin.custom_email_enabled;
    this.collaborator_emails = origin.collaborator_emails;
    this.collaborator_enabled = origin.collaborator_enabled;
    this.collaborators = origin.collaborators;
    this.message_body = origin.message_body;
    this.custom_template_enabled = origin.custom_template_enabled;
    this.header_image_url = origin.header_image_url;
    this.custom_from_text = origin.custom_from_text;
    this.custom_subject_text = origin.custom_subject_text;
    this.fields = ['Timestamp'].concat(origin.fields);
    if (origin.form_data) {
      origin.form_data.forEach(form_datum => {
        let form_element = new FormDatum(form_datum);
        // this.form_data.push(form_element);
        this.data.push(form_element.data);
      })
    }
  }

  getServerObject() {
    return {
      notifications: this.notifications,
      auto_response: this.auto_response,
      email_address: this.email_address,
      email_subject: this.email_subject,
      email_body: this.email_body,
      ajax_form: this.ajax_form,
      ajax_message: this.ajax_message,
      redirect_to_url: this.redirect_to_url,
      ajax_without_message: this.ajax_without_message,
      redirect_url: this.redirect_url,
      active: this.active,
      custom_email: this.custom_email,
      custom_email_enabled: this.custom_email_enabled,
      collaborator_enabled: this.collaborator_enabled,
      collaborator_emails: this.collaborator_emails
    };
  }

  getCustomEmailObject() {
    return {
      custom_from_text: this.custom_from_text,
      custom_subject_text: this.custom_subject_text,
      custom_template_enabled: this.custom_template_enabled,
      header_image_url: this.header_image_url,
      message_body: this.message_body
    }
  }
}

export class FormDatum {
  id = null;
  form_id = null;
  timestamp = null;
  data = null;

  constructor(origin) {
    if (!origin)
      return;
    this.id = origin.id;
    this.form_id = origin.form_id;
    this.timestamp = getRelativeTime(new Date(origin.created_at)).toLowerCase();
    this.data = origin.data;
    this.data["timestamp"] = this.timestamp;
  }
}