import { getRelativeTime } from "@/utils/common";

export class Notification {
  id = null;
  header = "";
  htmlText = null;
  notificationType = null;
  viewed = false;
  createdAt = null;
  createdAvatarUrl = null;
  linkInfo = {};
  createdByName = null;

  constructor(origin) {
  	if (!origin)
  	  return;
    this.id = origin.id;
    this.header = origin.header;
    this.htmlText = origin.html_text;
    this.notificationType = origin.notification_type;
    this.viewed = origin.viewed;
    this.createdAvatarUrl = origin.created_avatar_url;
    this.linkInfo = origin.link_info;
    this.createdAt = getRelativeTime(new Date(origin.created_at)).toLowerCase();
    this.createdByName = origin.created_by_name;
  }
}