<template>
  <div class="form-builder-toolbar">
    <draggable v-bind="dragOptions" class="d-inline-block">
      <v-tooltip bottom v-for="field in toolbarFields" :key="field.name">
        <template v-slot:activator="{ on : openTooltip }">
          <v-btn icon v-on="openTooltip" color="n2" @click="addFieldInstance(field)">
            <v-icon>{{ field.icon }}</v-icon>
          </v-btn>
        </template>
        <span> {{ field.name }} </span>
      </v-tooltip>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions } from "vuex";
import fields from "./data/fields"


export default {
  name: "FormBuilderToolbar",
  props: ["model"],
  components: {draggable},
  computed: {
    // data in toolbar, just display container types and chosen elements, NOT misc 
    toolbarFields() {
      return fields;
    },

    // to add transition while dragging
    dragOptions() {
      return {
        animation: 150,
        group: {
          name: "nested",
          pull: "clone",
          put: false
        },
        disabled: true, // disabled drag & drop toolbar for now.
        ghostClass: "ghost"
      };
    },
  },
  methods: {
    ...mapActions({
      addElement: 'formBuilder/addElement',
    }),

    // toolbar icon clicking event handler: append new instance of that field
    // expected scenario: 
    //   - containers will be appended to root without problem: formBuilder/addElement
    //   - leafs should be appended to last valid container: formBuilder/addElementToLastContainer
    addFieldInstance(field) {
      let date = new Date();
      let instance = {
        ...field,
        domID: field.type + "_" + date.getTime(),
        id: field.type + "_" + date.getTime()
      };
      this.addElement(instance);
    },

  }
}
</script>

<style lang="scss">
  .form-builder-toolbar {
    box-shadow: 0px 4px 20px 0px #25233899;
    background: $N6;
    padding: 10px;
    border-radius: 8px;
  }
</style>